
import { Component, Prop, Vue } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

@Component({ name: 'base-modal' })
export default class BaseModal extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly value: boolean

  @Prop({ type: String, default: null })
  readonly maxWidth: string | null

  @Prop({ type: String, default: '800' })
  readonly width: string

  @Prop({ type: String, default: 'auto' })
  readonly height: string

  @Prop({ type: String, default: '' })
  readonly error: string

  @Prop({ type: Boolean, default: false })
  readonly persistent: boolean

  @Prop({ type: Boolean, default: false })
  readonly disableActionsPadding: boolean

  readonly colors = colors

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  close(): void {
    if (!this.persistent) {
      this.$emit('close')
      this.show = false
    }
  }
}
