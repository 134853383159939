
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  getDocumentModule,
  getDocumentVersionModule,
  getFolderModule,
  getPhaseModule,
  getProjectModule,
  getTeamModule,
  getVisaModule,
  getVisaObservationModule,
} from '@/store/utils'
import { colors, defaultAvatarUrl, getFormattedDate } from '@/utils/constants'
import { getImageUrl } from '@/utils/imageUrlGetter'

import {
  FolderType,
  IBasicPhase,
  IDocument,
  IDocumentVersion,
  IFolder,
  IVisa,
  IVisaObservation,
  Project,
  ProjectGuest,
  PublicUser,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'

import VisaStatusChip from '@/components/project/documents/documents/visa/visa.status.chip.vue'

const Store = {
  ...getProjectModule(),
  ...getFolderModule(),
  ...getDocumentModule(),
  ...getDocumentVersionModule(),
  ...getPhaseModule(),
  ...getTeamModule(),
  ...getVisaObservationModule(),
  ...getVisaModule(),
}

interface IDocumentRowMenuItem {
  name: string
  action: () => void
}

@Component({ name: 'document-row', components: { VisaStatusChip } })
export default class DocumentRow extends Vue {
  @Prop({ required: true, type: String })
  readonly documentId: string

  @Prop({ type: Boolean, default: false })
  readonly isTrdView: boolean

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl
  readonly getFormattedDate = getFormattedDate

  @Store.ProjectModule.State
  readonly project: Project

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder

  @Store.DocumentModule.Getter
  readonly getDocumentById: (id: string) => IDocument

  @Store.DocumentVersionModule.Getter
  readonly getVersionsByDocumentId: (id: string) => IDocumentVersion[]

  @Store.VisaModule.Getter
  readonly getVisaById: (id: string) => IVisa

  @Store.VisaObservationModule.Getter
  readonly getObservationById: (id: string) => IVisaObservation | null

  get document(): IDocument {
    return this.getDocumentById(this.documentId)
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown || this.isTrdView
  }

  get documentVersions(): IDocumentVersion[] {
    return this.getVersionsByDocumentId(this.documentId)
  }

  get currentVersion(): IDocumentVersion {
    return this.documentVersions[0]
  }

  get visa(): IVisa | null {
    if (this.currentVersion.visaId === null) {
      return null
    }

    return this.getVisaById(this.currentVersion.visaId)
  }

  get visaObservations(): IVisaObservation[] {
    if (this.visa === null) {
      return []
    }

    return this.visa.observationsIds
      .map((id) => this.getObservationById(id))
      .filter((o) => o !== null) as IVisaObservation[]
  }

  hasRequiredRights(right: TeamRight): boolean {
    if (this.folder.type === FolderType.Trd) {
      return (
        this.hasRight(TeamRightGroup.TRD, TeamRight.ACCESS) &&
        this.hasRight(TeamRightGroup.TRD, right)
      )
    }

    if (this.folder.type === FolderType.Confidential) {
      return (
        this.hasRight(TeamRightGroup.CONFIDENTIAL, TeamRight.ACCESS) &&
        this.hasRight(TeamRightGroup.TRD, right)
      )
    }

    return true
  }

  get folder(): IFolder {
    return this.getParentByChildId(this.documentId)
  }

  get fileImage(): string {
    switch (this.folder.type) {
      case FolderType.Trd:
        return '/documents/3d_file.png'
      default:
        return `/documents/${getImageUrl(this.currentVersion.fileUrl)}`
    }
  }

  get fileCreator(): PublicUser {
    if (this.document.creator === this.project.creator.id) {
      return this.project.creator
    }

    const ret = this.project.guests.find(
      ({ user }) => user.id === this.document.creator
    ) as ProjectGuest
    return ret.user
  }

  get menuItems(): IDocumentRowMenuItem[] {
    const items = [
      {
        right:
          this.currentPhase.locked === false &&
          this.hasRight(TeamRightGroup.DOCUMENT, TeamRight.UPDATE) &&
          this.hasRequiredRights(TeamRight.UPDATE),
        name: 'document_update',
        action: () => this.$emit('showUpdateDocumentModal', this.documentId),
      },
      {
        right:
          this.currentPhase.locked === false &&
          this.hasRight(TeamRightGroup.DOCUMENT, TeamRight.DELETE) &&
          this.hasRequiredRights(TeamRight.CREATE),
        name: 'document_delete',
        action: () => this.$emit('showDeleteDocumentModal', this.documentId),
      },
    ]

    return items.filter(({ right }) => right === true)
  }

  isChoiceAvailable(nameToFind: string): boolean {
    return this.menuItems
      .map(({ name }) => {
        const splitedName = name.split('.')
        return splitedName[splitedName.length - 1]
      })
      .includes(nameToFind)
  }

  strToInitials(str: string): string {
    return str
      .split(' ')
      .map((s) => s[0])
      .join('')
      .toUpperCase()
  }
}
