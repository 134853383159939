
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IFolder } from '@/services/bimoboxApiService/types'
import { AlertWithData, I18NText } from '@/types/global'

import { getAlertModule, getFolderModule } from '@/store/utils'

import ConfirmationModal from '@/components/common/modals/confirmation.modal.vue'

const components = {
  ConfirmationModal,
}

const Store = {
  ...getAlertModule(),
  ...getFolderModule(),
}

@Component({ name: 'delete-folder-modal', components })
export default class DeleteFolderModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<IFolder>, required: true })
  readonly folder: IFolder

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.FolderModule.Action
  readonly deleteFolder: (id: string) => Promise<void>

  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.loading = false
    this.$emit('input', value)
  }

  get confirmationModalText(): I18NText {
    if (!this.show) {
      return { key: '' }
    }

    return {
      key: 'folder_delete_confirmation',
      data: { name: this.folder.name },
    }
  }

  async action(): Promise<void> {
    this.loading = true

    try {
      await this.deleteFolder(this.folder.id)
      this.ALERT_SUCCESS_WITH_DATA({
        message: `folder_delete_success`,
        data: { name: this.folder.name },
      })
    } catch (error) {
      this.ALERT_ERROR(error as string)
    }

    this.show = false
  }
}
