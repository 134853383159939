
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  IBasicPhase,
  IUpdatePhasePayload,
  Team,
} from '@/services/bimoboxApiService/types'
import { AlertWithData, VuetifyDataTableHeader } from '@/types/global'

import { getAlertModule, getPhaseModule, getTeamModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import NumberInput from '@/components/common/NumberInput.vue'

const components = {
  BaseModal,
  FormActions,
  NumberInput,
}

const Store = {
  ...getTeamModule(),
  ...getPhaseModule(),
  ...getAlertModule(),
}

@Component({ name: 'update-phase-modal', components })
export default class UpdatePhaseModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ required: true, type: Object as PropType<IBasicPhase> })
  readonly phase: IBasicPhase

  @Store.TeamModule.State
  readonly teams: Team[]

  get projectTeams(): Team[] {
    return this.teams.filter(({ id }) => !this.phase.guestsIds.includes(id))
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  @Store.PhaseModule.Action
  readonly updatePhase: (data: {
    id: string
    payload: IUpdatePhasePayload
  }) => Promise<void>

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Watch('phase')
  onPhaseChange(): void {
    this.resetData()
  }

  created(): void {
    this.resetData()
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  readonly colors = colors

  loading = false
  phaseName = ''
  error = ''
  phaseTeams: Team[] = []

  visaDelay = 14
  visaEmailDelay = 3
  documentUpdateDelay = 14

  selectedTeams: string[] = []
  teamsToDelete: string[] = []

  resetData(): void {
    this.loading = false
    this.selectedTeams = this.phase.guestsIds
    this.teamsToDelete = []
    this.phaseName = this.phase.name
    this.visaDelay = this.phase.visaDelay
    this.visaEmailDelay = this.phase.visaEmailDelay
    this.documentUpdateDelay = this.phase.documentUpdateDelay
    this.phaseTeams = this.teams.filter(({ id }) =>
      this.phase.guestsIds.includes(id)
    )
  }

  removeAddedTeam(id: string): void {
    this.selectedTeams = this.selectedTeams.filter((t) => t !== id)
  }

  get isFormValid(): boolean {
    const isSamePhase: boolean =
      this.selectedTeams.length === this.phase.guestsIds.length &&
      this.selectedTeams.every((id) => this.phase.guestsIds.includes(id)) &&
      this.phaseName === this.phase.name &&
      this.teamsToDelete.length === 0 &&
      this.visaDelay === this.phase.visaDelay &&
      this.visaEmailDelay === this.phase.visaEmailDelay &&
      this.documentUpdateDelay === this.phase.documentUpdateDelay

    return this.phaseName.length > 0 && !isSamePhase
  }

  get headerInfo(): VuetifyDataTableHeader[] {
    return [
      {
        value: 'color',
        align: 'center',
        filterable: false,
        sortable: false,
        width: '40',
      },
      {
        text: (this.$t('team') as string).toUpperCase(),
        value: 'name',
        align: 'start',
      },
      {
        text: (this.$t('team_members') as string).toUpperCase(),
        value: 'members',
        align: 'center',
        filterable: false,
      },
      {
        text: (this.$t('actions') as string).toUpperCase(),
        value: 'actions',
        filterable: false,
        sortable: false,
        align: 'end',
      },
    ]
  }
  updateTeamsToDelete(id: string): void {
    if (this.teamsToDelete.includes(id)) {
      this.teamsToDelete = this.teamsToDelete.filter(
        (memberId) => memberId !== id
      )
    } else {
      this.teamsToDelete.push(id)
    }
  }

  async primaryAction(): Promise<void> {
    this.loading = true
    try {
      const payload = {
        name: this.phaseName,
        guestsIds: this.selectedTeams.filter(
          (id) => !this.teamsToDelete.includes(id)
        ),
        visaDelay: this.visaDelay,
        visaEmailDelay: this.visaEmailDelay,
        documentUpdateDelay: this.documentUpdateDelay,
      }

      await this.updatePhase({
        id: this.phase.id,
        payload,
      })
      this.ALERT_SUCCESS_WITH_DATA({
        message: 'phase_update_success',
        data: { name: this.phaseName },
      })

      this.show = false
    } catch {
      this.error = 'unknown_error'
      this.resetData()
    }
  }
}
