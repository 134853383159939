
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import {
  Address,
  DBUser,
  IBasicPhase,
  LatLon,
  Project,
  Team,
} from '@/services/bimoboxApiService/types'

import {
  getPageLoaderModule,
  getPhaseModule,
  getProjectModule,
  getTeamModule,
  getUserModule,
} from '@/store/utils'
import { colors, RouteName } from '@/utils/constants'
import { openGoogleMaps } from '@/utils/openGoogleMaps'

import store from '@/store'

import CreateProjectModal from '@/components/dashboard/create.project.modal.vue'
import AvatarsCarousel from '@/components/project/information/avatars.carousel.vue'
import GuestsDisplayer from '@/components/project/information/guests.displayer.vue'
import MapDisplayer from '@/components/project/information/MapDisplayer.vue'
import PhasesDisplayer from '@/components/project/information/phases.displayer.vue'
import UpdateAddressModal from '@/components/project/information/update.address.modal.vue'
import ActiveTables from '@/components/project/information/active.tables.vue'

const components = {
  PhasesDisplayer,
  GuestsDisplayer,
  UpdateAddressModal,
  CreateProjectModal,
  MapDisplayer,
  AvatarsCarousel,
  ActiveTables,
}

const Store = {
  ...getProjectModule(),
  ...getPageLoaderModule(),
  ...getPhaseModule(),
  ...getTeamModule(),
  ...getUserModule(),
}

const beforeRouteMethod = async (
  _: Route,
  next: (location?: Location | Route) => void
): Promise<void> => {
  store.commit('pageLoader/TOGGLE_PAGE_LOADING', true)
  try {
    await Promise.all([
      store.dispatch('project/fetchProjectAvatars'),
      store.dispatch('project/fetchProjectAddress'),
      store.dispatch('phase/fetchPhases'),
    ])
    next()
  } catch {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    next({ name: RouteName.DASHBOARD })
  }
}

Component.registerHooks(['beforeRouteEnter'])
@Component({ name: 'information', components })
export default class Information extends Vue {
  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location | Route) => void
  ): Promise<void> {
    return beforeRouteMethod(to, next)
  }

  mounted(): void {
    this.TOGGLE_PAGE_LOADING(false)
    this.selectedUserId = this.user.id
  }

  readonly colors = colors

  showUpdateAvatars = false
  expandDescription = true

  addressClass = ''
  addressLocation: LatLon | null = null
  addressAsString: string | null = null
  showUpdateAddressModal = false
  showUpdateProjectModal = false

  @Store.PageLoaderModule.Mutation
  readonly TOGGLE_PAGE_LOADING: (value: boolean) => void

  @Store.ProjectModule.State
  readonly project: Project

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.ProjectModule.Getter
  readonly isCreator: boolean

  @Store.ProjectModule.State
  readonly address: Address | null

  @Store.UserModule.State
  readonly user: DBUser

  forceRefresh(): void {
    this.$forceUpdate()
  }

  openGmaps(): void {
    if (!this.addressLocation) {
      return
    }

    openGoogleMaps(this.addressLocation)
  }

  @Watch('address', { immediate: true, deep: true })
  onAddressChange(value: Address | null): void {
    this.addressClass = value?.location ? 'pointer orangeFont' : ''
    this.addressLocation = value?.location ? value.location : null
    this.addressAsString = value ? `${value.address}, ${value.city.name}` : null
  }

  get trueRights(): boolean {
    return true
  }

  trigger(): void {
    if (this.trueRights) {
      this.showUpdateAvatars = true
    }
  }

  clickMap(): void {
    if (this.addressLocation) {
      openGoogleMaps(this.addressLocation)
    } else {
      this.showUpdateAddressModal = true
    }
  }

  @Store.PhaseModule.State
  readonly phases: IBasicPhase[]

  get phasesToDisplay(): IBasicPhase[] {
    const isCreator = this.project.creator.id === this.selectedUserId
    const guest = this.project.guests.find(
      ({ user }) => user.id === this.selectedUserId
    )

    if (!guest && !isCreator) {
      return []
    }

    if (isCreator || guest?.isAdmin) {
      return this.phases
    }

    return this.getPhaseByUser(this.selectedUserId)
  }

  selectedUserId = ''

  getPhaseByUser(id: string): IBasicPhase[] {
    const userTeam = this.teams.find(({ membersIds }) =>
      membersIds.includes(id)
    )

    if (!userTeam) {
      return []
    }

    return this.phases.filter(({ guestsIds }) =>
      guestsIds.includes(userTeam.id)
    )
  }
}
