
import store from '@/store'
import { isEmail, isStrongPassword } from 'validator'
import { Component, Vue } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import { RegisterPayload } from '@/services/bimoboxApiService/types'
import { AuthFormAction, AuthFormAlert, VForm } from '@/types/global'

import { colors, RouteName } from '@/utils/constants'

import { AuthService } from '@/services/bimoboxApiService'

import AuthForm from '@/components/AuthForm.vue'
import Recaptcha from '@/components/Recaptcha.vue'

const components = { AuthForm, Recaptcha }

interface IRegisterForm {
  email: string
  password: string
  passwordConfirmation: string
  name: string
  lastName: string
  company: string
}

Component.registerHooks(['beforeRouteEnter'])
@Component({ name: 'register', components })
export default class Register extends Vue {
  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location) => void
  ): Promise<void> {
    store.commit('user/SET_USER', null)

    return next()
  }

  readonly authService = new AuthService()
  readonly colors = colors

  loading = false
  authFormAlert: AuthFormAlert | null = {
    type: 'error',
    text: 'register_disabled',
  }

  show = {
    password: false,
    confirmation: false,
  }

  data: IRegisterForm = {
    email: '',
    password: '',
    lastName: '',
    name: '',
    passwordConfirmation: '',
    company: '',
  }

  captcha = {
    reset: false,
    token: '',
  }

  readonly rightAction: AuthFormAction = {
    text: 'login',
    route: RouteName.LOGIN,
  }

  readonly leftAction: AuthFormAction = {
    text: 'reset_password',
    route: RouteName.SEND_RESET_PASSWORD,
  }

  readonly rules = {
    required: (value: string): string | boolean =>
      !!value || (this.$t('rule_required_error') as string),
    email: (value: string): string | boolean =>
      isEmail(value) || (this.$t('rule_invalid_email_error') as string),
    security: (value: string): string | boolean =>
      isStrongPassword(value) ||
      (this.$t('user_password_security_error') as string),
  }

  get arePwdAndConfirmationSame(): boolean | string {
    return (
      this.data.password === this.data.passwordConfirmation ||
      (this.$t('user_password_match_confirmation_error') as string)
    )
  }

  get isFormComplete(): boolean {
    return (
      this.data.email.length > 0 &&
      isEmail(this.data.email) &&
      this.data.name.length > 0 &&
      this.data.lastName.length > 0 &&
      this.data.company.length > 0 &&
      this.data.password.length > 0 &&
      isStrongPassword(this.data.password) === true &&
      this.arePwdAndConfirmationSame === true &&
      this.captcha.token.length > 0
    )
  }

  form(): VForm {
    return this.$refs.form as VForm
  }

  resetData(): void {
    this.loading = false
    this.data.email = ''
    this.data.password = ''
    this.data.name = ''
    this.data.lastName = ''
    this.data.passwordConfirmation = ''
    this.data.company = ''
    this.captcha.reset = true
    this.form().resetValidation()
  }

  async callRegister(): Promise<void> {
    if (!this.isFormComplete) {
      return
    }

    this.authFormAlert = null
    this.loading = true
    try {
      const payload: RegisterPayload = {
        name: this.data.name,
        lastName: this.data.lastName,
        company: this.data.company,
        email: this.data.email,
        password: this.data.password,
        recaptcha: this.captcha.token,
      }

      await this.authService.register(payload)
      this.authFormAlert = {
        type: 'success',
        text: 'register_success',
      }
    } catch (error) {
      console.error(error)
      this.authFormAlert = {
        type: 'error',
        text: error as string,
      }
    }

    this.resetData()
  }
}
