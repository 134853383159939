import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  CreateProjectPayload,
  DashboardProject,
  Project,
} from '@/services/bimoboxApiService/project/project.types'
import { Address, Endpoint, FileUrl } from '@/services/bimoboxApiService/types'
import { apiBaseUrl } from '@/utils/constants'
import { enrichUserAvatar } from '@/utils/imageUrlGetter'

export class ProjectService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createProject(
    payload: CreateProjectPayload
  ): Promise<DashboardProject> {
    const url = this.buildUrl()
    try {
      return this.apiService
        .post<DashboardProject>(url, payload)
        .then((p) => this.enrichDashboardProject(p))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getUserProjects(): Promise<DashboardProject[]> {
    const url = this.buildUrl()
    try {
      return this.apiService
        .get<DashboardProject[]>(url)
        .then((projects) => projects.map((p) => this.enrichDashboardProject(p)))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private enrichDashboardProject(project: DashboardProject): DashboardProject {
    project.avatar = project.avatar
      ? new URL(`${apiBaseUrl}/file/${project.avatar}`).toString()
      : undefined

    return project
  }

  async fetchProject(id: string): Promise<Project> {
    const url = this.buildUrl(id)
    try {
      return this.apiService
        .get<Project>(url)
        .then((p) => this.enrichProject(p))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateProject(
    id: string,
    data: CreateProjectPayload
  ): Promise<{ project: Project; address: Address | null }> {
    const url = this.buildUrl(id)
    try {
      return this.apiService
        .put<{
          project: Project
          address: Address | null
        }>(url, data)
        .then((res) => ({
          project: this.enrichProject(res.project),
          address: res.address,
        }))
    } catch (error) {
      console.error(error)
      throw error
    }
  }
  private enrichProject(project: Project): Project {
    project.creator.avatar = enrichUserAvatar(project.creator.avatar)

    project.guests = project.guests.map((g) => {
      const user = g.user
      user.avatar = enrichUserAvatar(user.avatar)

      return { isKicked: g.isKicked, isAdmin: g.isAdmin, user }
    })

    return project
  }

  async fetchProjectAvatars(id: string): Promise<FileUrl[]> {
    const url = this.buildUrl(`${id}/avatars`)
    try {
      return this.apiService
        .get<Omit<FileUrl, 'url'>[]>(url)
        .then((a) => this.enrichProjectAvatars(a))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateProjectAvatars(
    id: string,
    avatars: string[]
  ): Promise<FileUrl[]> {
    const url = this.buildUrl(`${id}/avatars`)
    try {
      return this.apiService
        .put<Omit<FileUrl, 'url'>[]>(url, {
          avatars,
        })
        .then((a) => this.enrichProjectAvatars(a))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private enrichProjectAvatars(avatars: Omit<FileUrl, 'url'>[]): FileUrl[] {
    return avatars.map(
      ({ id, name }): FileUrl => ({
        id,
        name,
        url: new URL(`${apiBaseUrl}/file/${name}`).toString(),
      })
    )
  }

  async fetchProjectAddress(id: string): Promise<{ address: Address | null }> {
    const url = this.buildUrl(`${id}/address`)
    try {
      return this.apiService.get<{ address: Address | null }>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateProjectAddress(id: string, address: Address): Promise<Address> {
    const url = this.buildUrl(`${id}/address`)
    try {
      return this.apiService.put<Address>(url, { address })
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
