
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Address, Project } from '@/services/bimoboxApiService/types'

import { getAlertModule, getProjectModule } from '@/store/utils'
import { colors } from '@/utils/constants'
import { isSameAddress } from '@/utils/isSameAddress'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import SetAddressCard from '@/components/dashboard/set.address.card.vue'

const components = {
  BaseModal,
  FormActions,
  SetAddressCard,
}

const Store = {
  ...getAlertModule(),
  ...getProjectModule(),
}

@Component({ name: 'update-address-modal', components })
export default class UpdateAddressModal extends Vue {
  @Prop({ required: true, type: Boolean })
  value: boolean

  @Store.ProjectModule.State
  readonly project: Project

  @Store.ProjectModule.State
  readonly address: Address | null

  @Store.ProjectModule.Action
  readonly updateProjectAddress: (address: Address) => Promise<void>

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS: (message: string) => void

  readonly colors = colors

  error = ''
  loading = false

  created(): void {
    this.fetchedAddress = this.address
  }

  fetchedAddress: Address | null = null

  isAddressCardLoading = false
  onAddressCardLoading(value: boolean): void {
    this.isAddressCardLoading = value
  }

  get isFormValid(): boolean {
    return (
      this.fetchedAddress !== undefined &&
      this.fetchedAddress !== null &&
      !isSameAddress(this.fetchedAddress, this.address)
    )
  }

  async primaryAction(): Promise<void> {
    if (!this.fetchedAddress) {
      return
    }

    this.loading = true
    try {
      await this.updateProjectAddress(this.fetchedAddress)
      this.ALERT_SUCCESS('project_address_update_success')
      this.$emit('addressUpdated')
      this.show = false
    } catch (error) {
      this.resetData()
      this.error = error as string
    }
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.error = ''
    this.resetData()
    this.$emit('input', value)
  }

  resetData(): void {
    this.isAddressCardLoading = false
    this.fetchedAddress = this.address
    this.loading = false
  }
}
