
import { Component, Vue, Prop } from 'vue-property-decorator'
import { IconsSelectorItem } from '@/types/global'
import { PropType } from 'vue'
import { colors } from '@/utils/constants'

@Component({ name: 'icons-selector' })
export default class IconsSelector extends Vue {
  @Prop({ required: true, type: Array as PropType<IconsSelectorItem[]> })
  readonly items: IconsSelectorItem[]

  @Prop({ required: true, type: String })
  readonly id: string

  @Prop({ required: false, default: '', type: String })
  cssStyle: string

  readonly colors = colors

  showMenu = false

  selectedItem: IconsSelectorItem

  created(): void {
    this.selectedItem = this.items[0]
  }

  setSelectedItem(item: IconsSelectorItem): void {
    this.selectedItem = item
    this.$emit('itemSelected', item.value)
  }
}
