import { GuestService } from '@/services/bimoboxApiService'
import {
  ICreateGuestPayload,
  IUpdateGuestPayload,
  ProjectGuest,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

const createGuestPyalodToUpdateTeamCommitData = (
  payloads: ICreateGuestPayload[],
  createdGuests: ProjectGuest[]
): { teamId: string; usersIds: string[] }[] => {
  const teamsIds = [
    ...new Set(
      payloads
        .filter(({ team }) => team !== undefined)
        .map(({ team }) => team as string)
    ),
  ]

  if (teamsIds.length === 0) {
    return []
  }

  return teamsIds.map((teamId) => ({
    teamId,
    usersIds: payloads
      .filter(({ team }) => team === teamId)
      .map(
        ({ email }) =>
          createdGuests.find(({ user }) => user.email === email)?.user
            .id as string
      ),
  }))
}

@Module({ namespaced: true })
export default class GuestModule extends VuexModule {
  private readonly guestService = new GuestService()

  @Mutation
  private ADD_GUESTS(data: { rootState; val: ProjectGuest[] }): void {
    const newGuestsIds = data.val.map(({ user }) => user.id)
    data.rootState.project.project.guests = [
      ...data.rootState.project.project.guests.filter(
        ({ user }) => !newGuestsIds.includes(user.id)
      ),
      ...data.val,
    ]
  }

  @Mutation
  UPDATE_GUESTS(data: { rootState; val: ProjectGuest[] }): void {
    for (const guestToUpdate of data.val) {
      const index = data.rootState.project.project.guests.findIndex(
        ({ user }) => user.id === guestToUpdate.user.id
      )
      if (index > -1) {
        data.rootState.project.project.guests.splice(index, 1, guestToUpdate)
      }
    }
  }

  @Action({ rawError: true, commit: 'ADD_GUESTS' })
  async createGuests(
    payloads: ICreateGuestPayload[]
  ): Promise<{ rootState; val: ProjectGuest[] }> {
    try {
      const guests = await this.guestService.createGuests(
        this.context.rootState.project.project.id,
        payloads
      )
      const teamCommitData = createGuestPyalodToUpdateTeamCommitData(
        payloads,
        guests
      )
      if (teamCommitData.length > 0) {
        this.context.commit('team/UPDATE_GUEST_TEAM', teamCommitData, {
          root: true,
        })
      }

      return { rootState: this.context.rootState, val: guests }
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'UPDATE_GUESTS' })
  async updateGuest(
    payload: IUpdateGuestPayload
  ): Promise<{ rootState; val: ProjectGuest[] }> {
    try {
      const guest = await this.guestService.updateGuest(
        this.context.rootState.project.project.id,
        payload
      )
      this.context.commit(
        'team/UPDATE_GUEST_TEAM',
        [{ teamId: payload.team, usersIds: [payload.id] }],
        { root: true }
      )
      return { rootState: this.context.rootState, val: [guest] }
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'UPDATE_GUESTS' })
  async kickGuests(ids: string[]): Promise<{ rootState; val: ProjectGuest[] }> {
    try {
      const guests = await this.guestService.kickGuests(
        this.context.rootState.project.project.id,
        ids
      )
      this.context.commit('team/REMOVE_GUESTS', ids, { root: true })
      return { rootState: this.context.rootState, val: guests }
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true })
  resendLink(id: string): Promise<void> {
    try {
      return this.guestService.resendLink(
        this.context.rootState.project.project.id,
        id
      )
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }
}
