
import { Component, Vue } from 'vue-property-decorator'

import { FileUrl } from '@/services/bimoboxApiService/types'

import { getProjectModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import UpdateAvatarsModal from '@/components/project/information/update.avatars.modal.vue'

const components = {
  UpdateAvatarsModal,
}

const Store = getProjectModule()

@Component({ name: 'avatars-carousel', components })
export default class AvatarsCarousel extends Vue {
  @Store.ProjectModule.State
  readonly avatars: FileUrl[]

  @Store.ProjectModule.Getter
  readonly isCreator: boolean

  readonly colors = colors

  showUpdateAvatars = false

  trigger(): void {
    if (this.isCreator) {
      this.showUpdateAvatars = true
    }
  }
}
