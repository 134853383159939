
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { DashboardProject } from '@/services/bimoboxApiService/types'
import { ProjectTypeFilter, SortBy } from '@/types/global'

import { getDashboardModule } from '@/store/utils'
import { filterProjects } from '@/utils/projectsFilter'

import ProjectCard from '@/components/dashboard/ProjectCard.vue'

const Store = getDashboardModule()

@Component({ name: 'projects-cards', components: { ProjectCard } })
export default class ProjectsCards extends Vue {
  @Prop({ type: String, required: true })
  readonly search: string

  @Prop({ type: String as PropType<SortBy>, required: true })
  readonly sort: SortBy

  @Prop({ type: String as PropType<ProjectTypeFilter>, required: true })
  readonly filter: ProjectTypeFilter

  @Store.DashboardModule.State
  readonly projects: DashboardProject[]

  get filteredProjects(): DashboardProject[] {
    return filterProjects(this.projects, this.filter, this.search, this.sort)
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }
}
