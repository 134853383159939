
import { Component, Prop, Vue } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

@Component({ name: 'page-loader' })
export default class PageLoader extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly show: boolean

  readonly colors = colors
}
