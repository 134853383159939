
import store from '@/store'
import { isEmail } from 'validator'
import { Component, Vue } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import { SendActivationPayload } from '@/services/bimoboxApiService/types'
import { AuthFormAction, AuthFormAlert, VForm } from '@/types/global'

import { colors, RouteName } from '@/utils/constants'

import { AuthService } from '@/services/bimoboxApiService'

import AuthForm from '@/components/AuthForm.vue'
import Recaptcha from '@/components/Recaptcha.vue'

const components = { AuthForm, Recaptcha }

Component.registerHooks(['beforeRouteEnter'])
@Component({ name: 'sendActivation', components })
export default class SendResetPassword extends Vue {
  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location) => void
  ): Promise<void> {
    store.commit('user/SET_USER', null)

    return next()
  }

  readonly authService = new AuthService()
  readonly colors = colors

  loading = false
  authFormAlert: AuthFormAlert | null = null

  showPassword = false

  data = { email: '' }

  captcha = {
    reset: false,
    token: '',
  }

  readonly rightAction: AuthFormAction = {
    text: 'register',
    route: RouteName.REGISTER,
  }

  readonly leftAction: AuthFormAction = {
    text: 'login',
    route: RouteName.LOGIN,
  }

  readonly rules = {
    required: (value: string): string | boolean =>
      !!value || (this.$t('rule_required_error') as string),
    email: (value: string): string | boolean =>
      isEmail(value) || this.$t('rule_invalid_email_error'),
  }

  get isFormComplete(): boolean {
    return isEmail(this.data.email) && this.captcha.token.length > 0
  }

  form(): VForm {
    return this.$refs.form as VForm
  }

  resetData(): void {
    this.loading = false
    this.data.email = ''
    this.captcha.reset = true
    this.form().resetValidation()
  }

  async sendActivation(): Promise<void> {
    if (!this.isFormComplete) {
      return
    }

    this.authFormAlert = null
    this.loading = true
    try {
      const payload: SendActivationPayload = {
        recaptcha: this.captcha.token,
        ...this.data,
      }

      await this.authService.sendActivationEmail(payload)
      this.authFormAlert = {
        type: 'success',
        text: 'send_activation_success',
      }
    } catch (error) {
      this.authFormAlert = {
        type: 'error',
        text: error as string,
      }
    }

    this.resetData()
  }
}
