
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { IDocument, IDocumentVersion } from '@/services/bimoboxApiService/types'
import ViewerService from '@/services/viewer.service'

import { getDocumentModule, getDocumentVersionModule } from '@/store/utils'

const Store = {
  ...getDocumentModule(),
  ...getDocumentVersionModule(),
}

@Component({ name: 'forge-viewer' })
export default class ForgeViewer extends Vue {
  @Prop({ required: true, type: Array as PropType<string[]> })
  readonly documentsIds: string[]

  @Prop({ type: Boolean, default: false })
  readonly screenshot: boolean

  readonly viewerService = new ViewerService()

  @Store.DocumentModule.Getter
  readonly getDocumentById: (documentId: string) => IDocument

  @Store.DocumentVersionModule.Getter
  readonly getLastDocumentVersion: (documentId: string) => IDocumentVersion

  async mounted(): Promise<void> {
    this.viewerService.setEnableScreenshot(this.screenshot)
    this.viewerService.setIsMobile(this.isMobile)
    await this.viewerService.initViewer()
    this.loadDocuments(this.documentsIds)
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  @Watch('isMobile')
  onMobileChange(value: boolean): void {
    this.viewerService.setIsMobile(value)
  }

  @Watch('screenshot')
  onScreenshotChange(value: boolean): void {
    this.viewerService.setEnableScreenshot(value)
  }

  @Watch('documentsIds')
  async onDocumentsChange(): Promise<void> {
    this.loadDocuments(this.documentsIds)
  }

  loadDocuments(documentsIds: string[]): void {
    if (this.documentsIds.length === 0) {
      return
    }

    const documents = documentsIds.map(this.getDocumentById)
    const urns = documents
      .map(({ id }) => this.getLastDocumentVersion(id))
      .filter(({ status, urn }) => urn && urn.length > 0 && status === 100)
      .map(({ urn }) => urn as string)

    this.viewerService.loadModels(urns)
  }
}
