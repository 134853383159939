import { namespace } from 'vuex-class'
import { BindingHelpers } from 'vuex-class/lib/bindings'

export const getUserModule = (): { UserModule: BindingHelpers } => ({
  UserModule: namespace('user'),
})

export const getAlertModule = (): { AlertModule: BindingHelpers } => ({
  AlertModule: namespace('alert'),
})

export const getDashboardModule = (): { DashboardModule: BindingHelpers } => ({
  DashboardModule: namespace('dashboard'),
})

export const getPageLoaderModule = (): {
  PageLoaderModule: BindingHelpers
} => ({ PageLoaderModule: namespace('pageLoader') })

export const getProjectModule = (): { ProjectModule: BindingHelpers } => ({
  ProjectModule: namespace('project'),
})

export const getTeamModule = (): { TeamModule: BindingHelpers } => ({
  TeamModule: namespace('team'),
})

export const getPhaseModule = (): { PhaseModule: BindingHelpers } => ({
  PhaseModule: namespace('phase'),
})

export const getGuestModule = (): { GuestModule: BindingHelpers } => ({
  GuestModule: namespace('guest'),
})

export const getFolderModule = (): { FolderModule: BindingHelpers } => ({
  FolderModule: namespace('folder'),
})

export const getDocumentModule = (): { DocumentModule: BindingHelpers } => ({
  DocumentModule: namespace('document'),
})

export const getDocumentVersionModule = (): {
  DocumentVersionModule: BindingHelpers
} => ({ DocumentVersionModule: namespace('documentVersion') })

export const getServicebusModule = (): {
  ServicebusModule: BindingHelpers
} => ({ ServicebusModule: namespace('servicebus') })

export const getVisaObservationModule = (): {
  VisaObservationModule: BindingHelpers
} => ({ VisaObservationModule: namespace('visaObservation') })

export const getVisaModule = (): { VisaModule: BindingHelpers } => ({
  VisaModule: namespace('visa'),
})

export const getAdviceModule = (): { AdviceModule: BindingHelpers } => ({
  AdviceModule: namespace('advice'),
})
