import { DocumentService } from '@/services/bimoboxApiService'
import {
  ICreateDocumentPayload,
  IDocument,
  IDocumentEmailDTO,
  IUpdateDocumentPayload,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class DocumentModule extends VuexModule {
  private readonly documentService = new DocumentService()
  documents: IDocument[] | null = null

  get getDocumentById(): (documentId: string) => IDocument | null {
    return (documentId: string): IDocument | null => {
      const document = this.documents?.find(({ id }) => id === documentId)
      return document === undefined ? null : document
    }
  }

  @Mutation
  ADD_DOCUMENTS(documents: IDocument[]): void {
    if (!this.documents) {
      this.documents = []
    }

    this.documents.push(...documents)
  }

  @Mutation
  RESET_DOCUMENT_STATES(): void {
    this.documents = null
  }

  @Mutation
  UPDATE_DOCUMENT(document: IDocument): void {
    if (!this.documents) {
      return
    }

    const index = this.documents.findIndex(({ id }) => id === document.id)
    if (index > -1) {
      this.documents.splice(index, 1, document)
    }
  }

  @Mutation
  ADD_DOCUMENT_VERSION(data: { id: string; versionId: string }): void {
    if (!this.documents) {
      return
    }

    const index = this.documents.findIndex(({ id }) => id === data.id)
    if (index > -1) {
      const document = { ...this.documents[index] }
      document.versionsIds.push(data.versionId)
      this.documents.splice(index, 1, document)
    }
  }

  @Mutation
  DELETE_DOCUMENTS_BY_IDS(ids: string[]): void {
    if (!this.documents) {
      return
    }

    this.documents = [...this.documents.filter(({ id }) => !ids.includes(id))]
  }

  @Action({ rawError: true })
  async createDocument(data: {
    folderId: string
    payload: ICreateDocumentPayload
  }): Promise<void> {
    try {
      const { document, versions } = await this.documentService.createDocument(
        this.projectId,
        this.currentPhaseId,
        data.folderId,
        data.payload
      )
      this.context.commit('documentVersion/ADD_VERSIONS', versions, {
        root: true,
      })
      this.context.commit('ADD_DOCUMENTS', [document])
      this.context.commit(
        'folder/ADD_CONTENT_IN_FOLDER',
        { id: data.folderId, folders: [], documents: [document.id] },
        { root: true }
      )
    } catch (error) {
      console.error('createDocument', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true })
  async getDocumentEmailOpts(data: {
    folderId: string
    documentId: string
  }): Promise<IDocumentEmailDTO> {
    return this.documentService
      .getDocumentEmailOpts(
        this.projectId,
        this.currentPhaseId,
        data.folderId,
        data.documentId
      )
      .catch((error) => {
        console.error('updateDocument', error)
        throw 'unknown_error'
      })
  }

  @Action({ rawError: true, commit: 'UPDATE_DOCUMENT' })
  async updateDocument(data: {
    folderId: string
    documentId: string
    payload: IUpdateDocumentPayload
  }): Promise<IDocument> {
    try {
      const { document } = await this.documentService.updateDocumentById(
        this.projectId,
        this.currentPhaseId,
        data.folderId,
        data.documentId,
        data.payload
      )
      return document
    } catch (error) {
      console.error('updateDocument', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true })
  async retryUpload(data: {
    folderId: string
    documentId: string
  }): Promise<void> {
    return this.documentService
      .retryTrdDocumentUpload(
        this.projectId,
        this.currentPhaseId,
        data.folderId,
        data.documentId
      )
      .catch((err) => {
        console.error('retryUpload', err)
        throw 'unknown_error'
      })
  }

  @Action({ rawError: true })
  async deleteDocument(data: {
    folderId: string
    documentId: string
  }): Promise<void> {
    const docToDelete = this.documents?.find(({ id }) => id === data.documentId)
    if (!docToDelete) {
      return
    }

    try {
      await this.documentService.deleteDocumentById(
        this.projectId,
        this.currentPhaseId,
        data.folderId,
        data.documentId
      )
      this.context.dispatch(
        'documentVersion/deleteVersionsByIds',
        [docToDelete.versionsIds],
        {
          root: true,
        }
      )
      this.context.commit('DELETE_DOCUMENTS_BY_IDS', [data.documentId])
      this.context.commit(
        'folder/DELET_CONTENT_IN_FOLDER',
        { id: data.folderId, folders: [], documents: [data.documentId] },
        { root: true }
      )
    } catch (error) {
      console.error('deleteDocument', error)
      throw 'unknown_error'
    }
  }

  private get projectId(): string {
    return this.context.rootState.project.project.id
  }

  private get currentPhaseId(): string {
    return this.context.rootState.phase.currentPhaseId
  }
}
