import { TeamService } from '@/services/bimoboxApiService'
import {
  IPostTeamPayload,
  IUpdateTeamPayload,
  Team,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/team/team.types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

const getTeamByUserId = (teams: Team[] | null, userId: string) => {
  if (!teams) {
    return null
  }

  const ret = teams.find(({ membersIds }) => membersIds.includes(userId))
  if (!ret) {
    return null
  }

  return ret
}

@Module({ namespaced: true })
export default class TeamModule extends VuexModule {
  private readonly teamService = new TeamService()
  teams: Team[] | null = null

  get userTeam(): Team | null {
    return getTeamByUserId(this.teams, this.context.rootState.user.user.id)
  }

  get getTeamByUserId(): (userId: string) => Team | null {
    return (userId: string): Team | null => getTeamByUserId(this.teams, userId)
  }

  get guestTeam(): (id: string) => Team | null {
    return (id: string): Team | null => {
      return getTeamByUserId(this.teams, id)
    }
  }

  get hasRight(): (group: TeamRightGroup, right: TeamRight) => boolean {
    return (group: TeamRightGroup, right: TeamRight): boolean => {
      const isCreatorOrAdmin =
        this.context.rootGetters['project/isCreatorOrAdmin']
      if (isCreatorOrAdmin) {
        return true
      }

      if (this.userTeam === null) {
        return false
      }

      return this.userTeam[group][right]
    }
  }

  @Mutation
  SET_TEAMS(val: Team[] | null): void {
    this.teams = val ? [...val] : null
  }

  @Mutation
  private ADD_TEAM(val: Team): void {
    this.teams = this.teams ? [...this.teams, val] : [val]
  }

  @Mutation
  private UPDATE_TEAM(val: Team): void {
    if (!this.teams) {
      return
    }

    const index = this.teams.findIndex(({ id }) => id === val.id)
    if (index > -1) {
      this.teams.splice(index, 1, val)
    }
  }

  @Mutation
  UPDATE_GUEST_TEAM(data: { teamId: string; usersIds: string[] }[]): void {
    if (!this.teams) {
      return
    }

    const usersIds: string[] = []
    for (const dataItem of data) {
      usersIds.push(...dataItem.usersIds)
    }

    this.teams = [
      ...this.teams.map((t) => {
        const teamInData = data.find(({ teamId }) => t.id === teamId)
        if (teamInData) {
          t.membersIds.push(...teamInData.usersIds)
        } else {
          t.membersIds = t.membersIds.filter((id) => !usersIds.includes(id))
        }

        return t
      }),
    ]
  }

  @Mutation
  REMOVE_GUESTS(ids: string[]): void {
    if (!this.teams) {
      return
    }

    this.teams = [
      ...this.teams.map((t) => {
        t.membersIds = t.membersIds.filter((id) => !ids.includes(id))
        return t
      }),
    ]
  }

  @Mutation
  private DELETE_TEAM(toDeleteId: string): void {
    if (!this.teams) {
      return
    }

    this.teams = [...this.teams.filter(({ id }) => id !== toDeleteId)]
  }

  @Action({ rawError: true, commit: 'SET_TEAMS' })
  async fetchTeams(projectId: string): Promise<Team[]> {
    try {
      if (this.teams) {
        return this.teams
      }

      return this.teamService.getProjectTeams(projectId)
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'ADD_TEAM' })
  createTeam(payload: IPostTeamPayload): Promise<Team> {
    try {
      return this.teamService.createTeam(
        this.context.rootState.project.project.id,
        payload
      )
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'UPDATE_TEAM' })
  updateTeam(payload: IUpdateTeamPayload): Promise<Team> {
    try {
      return this.teamService.updateTeamById(
        this.context.rootState.project.project.id,
        payload.id,
        payload.data
      )
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'DELETE_TEAM' })
  async deleteTeam(id: string): Promise<string> {
    try {
      await this.teamService.deleteTeam(
        this.context.rootState.project.project.id,
        id
      )
      return id
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }
}
