
import { v4 as uuid } from 'uuid'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

@Component({ name: 'resizable-table' })
export default class ResizableTable extends Vue {
  @Prop({ default: null })
  readonly buttonText: string | null

  @Prop({ default: false })
  readonly disableSearch: boolean

  @Prop({ default: '' })
  readonly search: string

  readonly colors = colors

  cardUuid = uuid()
  cardTitleUuid = uuid()
  dataSearch = ''
  pageCount = 1
  page = 1
  pageItems = 0
  actionsHeight = 92

  @Watch('dataSearch')
  onDataSearchChange(val: string): void {
    this.$emit('update:search', val)
  }

  emitButtonClicked(): void {
    this.$emit('buttonClicked')
  }

  created(): void {
    this.onResize()
  }

  onResize(): void {
    const occupiedHeight =
      this.getCardHeight() -
      this.actionsHeight -
      (this.buttonText || !this.disableSearch ? this.getHeaderHeight() : 0)
    const rowsPerPage = Math.floor(occupiedHeight / this.getRowHeight())
    this.pageItems = rowsPerPage
  }

  getCardHeight(): number {
    return this.getHtmlElementHeightById(this.cardUuid) ?? 0
  }

  getHeaderHeight(): number {
    return this.getHtmlElementHeightById(this.cardTitleUuid) ?? 0
  }

  getRowHeight(): number {
    return (
      this.getHtmlElementHeightByClass('v-data-table__mobile-table-row') ?? 48
    )
  }

  updatePageCount(newCount: number): void {
    this.pageCount = newCount
  }

  getHtmlElementHeightById(id: string): number | null {
    const element: Element | null = document.getElementById(id)
    if (!element) {
      return null
    }

    const htmlElement = element as HTMLElement
    return htmlElement.offsetHeight
  }

  getHtmlElementHeightByClass(className: string): number | null {
    const element: Element | null | undefined =
      document.getElementsByClassName(className)[0]
    if (!element) {
      return null
    }

    const htmlElement = element as HTMLElement
    return htmlElement.offsetHeight
  }
}
