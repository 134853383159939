
import { Component, Prop, Vue } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import {
  ICancelVisaPayload,
  ICreateVisaObservationPayload,
  IDocument,
  IDocumentVersion,
  IFolder,
  IVisa,
  VisaObservationStatus,
} from '@/services/bimoboxApiService/types'
import { getAlertModule, getFolderModule, getVisaModule } from '@/store/utils'
import { PropType } from 'vue'

const Store = {
  ...getVisaModule(),
  ...getFolderModule(),
  ...getAlertModule(),
}

const components = {
  BaseModal,
  FormActions,
}

@Component({ name: 'cancel-visa-modal', components })
export default class CancelVisaModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<IDocumentVersion>, required: true })
  readonly version: IDocumentVersion

  @Prop({ type: Object as PropType<IDocument>, required: true })
  readonly document: IDocument

  @Prop({ type: Object as PropType<IVisa>, required: true })
  readonly visa: IVisa

  readonly colors = colors

  reason = ''
  loading = false
  error = ''

  @Store.VisaModule.Action
  readonly cancelVisa: (payload: ICancelVisaPayload) => Promise<void>

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS: (message: string) => void

  get isFormValid(): boolean {
    return this.reason.length > 0
  }

  get show(): boolean {
    this.reason = ''
    this.loading = false
    this.error = ''
    return this.value
  }

  set show(value: boolean) {
    this.reason = ''
    this.$emit('input', value)
  }

  visaCompleted(): void {
    const payload: ICreateVisaObservationPayload = {
      status: VisaObservationStatus.OUT_OF_MISSION,
      content: [{ text: this.reason, files: [] }],
    }

    this.$emit('visaCompleted', payload)
  }

  async primaryAction(): Promise<void> {
    this.loading = true
    this.error = ''

    try {
      await this.cancelVisa({
        documentId: this.document.id,
        versionId: this.version.id,
        visaId: this.visa.id,
        folderId: this.getParentByChildId(this.document.id).id,
        data: {
          reason: this.reason,
        },
      })

      this.ALERT_SUCCESS('visa_cancel_success')
    } catch (err) {
      this.reason = ''
      this.error = `${err}`
    }

    this.loading = false
  }
}
