import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  FindCityPayload,
  City,
  FindAddressPayload,
  Address,
  Endpoint,
} from '@/services/bimoboxApiService/types'
import { HttpService } from '@/services/httpSerivce'

export class AddressService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.ADDRESS)
  }

  async findCity(payload: FindCityPayload): Promise<City[]> {
    const url = this.buildUrl(
      `city${HttpService.objectToQueryParamsString(payload)}`
    )
    try {
      return await this.apiService.get<City[]>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async findAddress(payload: FindAddressPayload): Promise<Address | null> {
    const url = this.buildUrl()
    try {
      return await this.apiService.post<Address | null>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}
