import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  IVisaContent,
  Endpoint,
  ICreateVisaObservationPayload,
  IVisaObservation,
} from '@/services/bimoboxApiService/types'

export class VisaObservationService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createObs(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string,
    versionId: string,
    visaId: string,
    payload: ICreateVisaObservationPayload
  ): Promise<IVisaContent> {
    const url = this.buildFullUrl(
      projectId,
      phaseId,
      folderId,
      documentId,
      versionId,
      visaId
    )
    try {
      return await this.apiService.post<IVisaContent>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateObs(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string,
    versionId: string,
    visaId: string,
    observationId: string,
    payload: ICreateVisaObservationPayload
  ): Promise<IVisaObservation> {
    const url = this.buildFullUrl(
      projectId,
      phaseId,
      folderId,
      documentId,
      versionId,
      visaId,
      observationId
    )
    try {
      return await this.apiService.put<IVisaObservation>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async deleteObs(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string,
    versionId: string,
    visaId: string,
    observationId: string
  ): Promise<void> {
    const url = this.buildFullUrl(
      projectId,
      phaseId,
      folderId,
      documentId,
      versionId,
      visaId,
      observationId
    )
    try {
      return await this.apiService.delete<void>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private buildFullUrl(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string,
    versionId: string,
    visaId: string,
    params = ''
  ): string {
    return this.buildUrl(
      `${projectId}/${Endpoint.PHASE}/${phaseId}/${Endpoint.FOLDER}/${folderId}/${Endpoint.DOCUMENT}/${documentId}/${Endpoint.VERSION}/${versionId}/${Endpoint.VISA}/${visaId}/${Endpoint.OBSERVATION}/${params}`
    )
  }
}
