
import { isStrongPassword } from 'validator'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  DBUser,
  UpdatePasswordPayload,
} from '@/services/bimoboxApiService/types'
import { VForm } from '@/types/global'

import { getAlertModule, getUserModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import { UserService } from '@/services/bimoboxApiService'

import FormActions from '@/components/common/actions/form.actions.vue'
import BaseModal from '@/components/common/modals/base.modal.vue'
import { LocalStorageService } from '@/services/localStorage.service'

const components = { BaseModal, FormActions }
const Store = {
  ...getAlertModule(),
  ...getUserModule(),
}

@Component({ name: 'update-password-modal', components })
export default class UpdatePasswordModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS: (message: string) => void

  @Store.UserModule.Action
  readonly revokeTokens: () => Promise<void>

  @Store.UserModule.State
  readonly user: DBUser

  readonly colors = colors
  readonly userService = new UserService()
  readonly localStorageService = new LocalStorageService()
  loading = false

  formData = {
    newPassword: '',
    newPasswordConfirmation: '',
    revokeTokens: false,
  }

  showFields = {
    newPassword: false,
    newPasswordConfirmation: false,
  }

  error = ''

  rules = {
    required: (value: string): string | boolean =>
      value !== undefined || (this.$t('rule_required_error') as string),
    security: (value: string): string | boolean =>
      isStrongPassword(value) ||
      (this.$t('user_password_security_error') as string),
  }

  get passwordMatchRule(): string | boolean {
    return (
      this.formData.newPassword === this.formData.newPasswordConfirmation ||
      (this.$t('user_password_match_confirmation_error') as string)
    )
  }

  get isFormValid(): boolean {
    return this.passwordMatchRule && isStrongPassword(this.formData.newPassword)
  }

  async primaryAction(): Promise<void> {
    if (!this.isFormValid) {
      return
    }

    this.loading = true
    this.error = ''

    try {
      const payload: UpdatePasswordPayload = {
        password: this.formData.newPassword,
        revokeTokens: this.formData.revokeTokens,
      }

      await this.userService.updatePassword(this.user, payload)
      this.ALERT_SUCCESS('user_password_update_success')
      this.show = false
    } catch (error) {
      this.error = error as string
      this.loading = false
      this.resetForm()
    }
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (!value) {
      this.resetForm()
      this.error = ''
      this.loading = false
    }

    this.$emit('input', value)
  }

  resetForm(): void {
    const form = this.$refs.form as VForm
    this.formData.newPassword = ''
    this.formData.newPasswordConfirmation = ''
    this.formData.revokeTokens = false
    form.resetValidation()
  }
}
