import { FolderService } from '@/services/bimoboxApiService'
import {
  FolderType,
  IArboPayload,
  IBasicPhase,
  ICreateFolderPayload,
  IFolder,
  IUpdateFolderPayload,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

// utils
const getFolderContent = (
  folderId: string,
  folders: IFolder[]
): { documentsIds: string[]; foldersIds: string[] } => {
  const folder = folders.find(({ id }) => id === folderId)
  if (!folder) {
    return { documentsIds: [], foldersIds: [] }
  }

  const currentFolderFoldersIds = [...folder.folders]
  const documentsIds = [...folder.documents]

  const foldersIds: string[] = []
  for (const id of currentFolderFoldersIds) {
    const ret = getFolderContent(id, folders)
    documentsIds.push(...ret.documentsIds)
    foldersIds.push(...ret.foldersIds)
  }

  return { documentsIds, foldersIds }
}

// store
@Module({ namespaced: true })
export default class FolderModule extends VuexModule {
  private readonly folderService = new FolderService()
  folders: IFolder[] | null = null

  get currentPhaseFolders(): IFolder[] {
    if (this.folders === null) {
      return []
    }

    const currentPhase: IBasicPhase | null =
      this.context.rootGetters['phase/currentPhase']
    if (currentPhase === null || currentPhase === undefined) {
      return []
    }

    const phaseFolders = this.folders.filter(
      ({ phaseId }) => phaseId === currentPhase.id
    )
    const rootFolders = phaseFolders.filter(
      ({ rootId }) => rootId === undefined
    )
    rootFolders.sort((a: IFolder, b: IFolder): number => {
      return a.name === b.name ? 0 : a.name > b.name ? 1 : -1
    })

    return rootFolders
  }

  get getFolderById(): (folderId: string) => IFolder | null {
    return (folderId: string): IFolder | null => {
      const folder = this.folders?.find(({ id }) => id === folderId)
      return folder === undefined ? null : folder
    }
  }

  get getParentByChildId(): (id: string) => IFolder | null {
    return (id: string): IFolder | null => {
      const parent = this.folders?.find(
        ({ folders, documents }) =>
          folders.includes(id) || documents.includes(id)
      )
      return parent === undefined ? null : parent
    }
  }

  @Mutation
  ADD_FOLDERS(data: { folder: IFolder; parentId?: string }[]): void {
    if (!this.folders) {
      this.folders = []
    }

    for (const dataItem of data) {
      this.folders.push(dataItem.folder)
      if (dataItem.parentId !== undefined) {
        const index = this.folders.findIndex(
          ({ id }) => id === dataItem.parentId
        )
        if (index > -1) {
          const parentCopy = { ...this.folders[index] }
          parentCopy.folders.push(dataItem.folder.id)
          this.folders.splice(index, 1, parentCopy)
        }
      }
    }
  }

  @Mutation
  RESET_FODLER_STATES(): void {
    this.folders = null
  }

  @Mutation
  ADD_CONTENT_IN_FOLDER(data: {
    id: string
    folders: string[]
    documents: string[]
  }): void {
    if (!this.folders) {
      return
    }

    const index = this.folders.findIndex(({ id }) => id === data.id)
    if (index === -1) {
      return
    }

    const folderCopy = { ...this.folders[index] }
    folderCopy.documents.push(...data.documents)
    folderCopy.folders.push(...data.folders)
    this.folders.splice(index, 1, folderCopy)
  }

  @Mutation
  UPDATE_FOLDER(data: { id: string; name: string; type: FolderType }): void {
    if (!this.folders) {
      return
    }

    const index = this.folders.findIndex(({ id }) => id === data.id)
    if (index > -1) {
      const folderCopy = { ...this.folders[index] }
      folderCopy.name = data.name
      folderCopy.type = data.type
      this.folders.splice(index, 1, folderCopy)
    }
  }

  @Mutation
  DELETE_FOLDERS_BY_IDS(ids: string[]): void {
    if (!this.folders) {
      return
    }

    this.folders = [...this.folders.filter(({ id }) => !ids.includes(id))]
    const parentsIndexes = ids
      .map((id) => this.folders?.findIndex((f) => f.id === id))
      .filter((i) => i !== undefined && i > -1) as number[]
    for (const index of parentsIndexes) {
      const parentCopy = { ...this.folders[index] }
      parentCopy.folders = parentCopy.folders.filter((id) => !ids.includes(id))
      this.folders.splice(index, 1, parentCopy)
    }
  }

  @Mutation
  DELET_CONTENT_IN_FOLDER(data: {
    id: string
    folders: string[]
    documents: string[]
  }): void {
    if (!this.folders) {
      return
    }

    this.folders = [
      ...this.folders.filter(({ id }) => !data.folders.includes(id)),
    ]
    const index = this.folders.findIndex(({ id }) => data.id === id)
    if (index === -1) {
      return
    }

    const parentCopy = { ...this.folders[index] }
    parentCopy.documents = parentCopy.documents.filter(
      (id) => !data.documents.includes(id)
    )
    parentCopy.folders = parentCopy.folders.filter(
      (id) => !data.folders.includes(id)
    )
    this.folders.splice(index, 1, parentCopy)
  }

  @Action({ rawError: true, commit: 'ADD_FOLDERS' })
  async createFolder(
    payload: ICreateFolderPayload
  ): Promise<{ folder: IFolder; parentId?: string }[]> {
    try {
      const folder = await this.folderService.createFolder(
        this.projectId,
        this.currentPhaseId,
        payload
      )
      return [{ folder, parentId: payload.parentId }]
    } catch (error) {
      console.error('createFolder', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'UPDATE_FOLDER' })
  async updateFolder(
    payload: IUpdateFolderPayload
  ): Promise<{ id: string; name: string; type: FolderType }> {
    try {
      const { name, type, id } = await this.folderService.updateFolderById(
        this.projectId,
        this.currentPhaseId,
        payload
      )

      const updateSubfoldersTypes = (f: IFolder | null) => {
        if (!f) {
          return
        }

        this.context.commit('UPDATE_FOLDER', {
          id: f.id,
          name: f.name,
          type: payload.type,
        })

        f.folders
          .map((childID) => this.getFolderById(childID))
          .forEach((c) => updateSubfoldersTypes(c))
      }

      const folder = this.getFolderById(payload.id)
      if (folder) {
        folder.folders
          .map((childID) => this.getFolderById(childID))
          .forEach((c) => updateSubfoldersTypes(c))
      }

      return { id, name, type }
    } catch (error) {
      console.error('updateFolder', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true })
  async deleteFolder(id: string): Promise<void> {
    try {
      await this.folderService.deleteFolderById(
        this.projectId,
        this.currentPhaseId,
        id
      )
      if (!this.folders) {
        return
      }

      const { foldersIds, documentsIds } = getFolderContent(id, [
        ...this.folders,
      ])
      foldersIds.push(id)
      this.context.commit('DELETE_FOLDERS_BY_IDS', foldersIds)
      this.context.commit('document/DELETE_DOCUMENTS_BY_IDS', documentsIds, {
        root: true,
      })
    } catch (error) {
      console.error('updateFolder', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true })
  async createArborescence(payload: IArboPayload): Promise<void> {
    try {
      const { folders, documents, versions, rootFolders, rootDocuments } =
        await this.folderService.createArborescence(
          this.projectId,
          this.currentPhaseId,
          payload
        )
      this.context.commit('documentVersion/ADD_VERSIONS', versions, {
        root: true,
      })
      this.context.commit('document/ADD_DOCUMENTS', documents, { root: true })
      this.context.commit(
        'ADD_FOLDERS',
        folders.map((folder) => ({ folder }))
      )
      this.context.commit('ADD_CONTENT_IN_FOLDER', {
        id: payload.parentId,
        folders: rootFolders,
        documents: rootDocuments,
      })
    } catch (error) {
      console.error('createDocument', error)
      throw 'unknown_error'
    }
  }

  private get projectId(): string {
    return this.context.rootState.project.project.id
  }

  private get currentPhaseId(): string {
    return this.context.rootState.phase.currentPhaseId
  }
}
