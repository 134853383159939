
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { LatLon } from '@/services/bimoboxApiService/types'

import { colors } from '@/utils/constants'

@Component({ name: 'map-displayer' })
export default class MapDisplayer extends Vue {
  @Prop({ required: false, type: Object as PropType<LatLon>, default: null })
  readonly location: LatLon | null

  @Prop({ required: false, type: Boolean, default: false })
  readonly loading: boolean

  readonly colors = colors
  readonly zoom = 18
  readonly rotation = 0

  get center(): number[] {
    if (!this.location) {
      return []
    }

    return [Number(this.location.lon), Number(this.location.lat)]
  }
}
