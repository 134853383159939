
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  Project,
  PublicUser,
  Team,
  TeamRightGroups,
} from '@/services/bimoboxApiService/types'
import { VuetifyDataTableHeader } from '@/types/global'

import { getProjectModule } from '@/store/utils'
import { colors, emptyRights } from '@/utils/constants'

import ResizableTable from '@/components/common/resizableTable.vue'
import RightsCards from '@/components/project/teams/RightsCards.vue'

const components = {
  ResizableTable,
  RightsCards,
}

const Store = {
  ...getProjectModule(),
}

@Component({ name: 'team-tab', components })
export default class TeamTab extends Vue {
  @Prop({ required: true, type: Object as PropType<Team> })
  readonly team: Team

  @Store.ProjectModule.State
  readonly project: Project

  readonly colors = colors

  get rights(): TeamRightGroups {
    return emptyRights(this.team)
  }

  get membersHeaderInfo(): VuetifyDataTableHeader[] {
    return [
      {
        value: 'avatar',
        align: 'start',
        filterable: false,
        sortable: false,
        width: '40',
      },
      {
        text: this.$t('name') as string,
        value: 'displayName',
        align: 'center',
      },
    ]
  }

  search = ''

  get members(): PublicUser[] {
    if (!this.team) {
      return []
    }

    const selectedTeamMembersIds = this.team.membersIds

    const members = this.project.guests
      .filter(({ user }) => selectedTeamMembersIds.includes(user.id))
      .map(({ user }) => user)

    if (selectedTeamMembersIds.includes(this.project.creator.id)) {
      members.push(this.project.creator)
    }

    return this.search.length === 0
      ? members
      : members.filter((t) =>
          t.displayName.toLowerCase().startsWith(this.search.toLowerCase())
        )
  }
}
