
import { v4 as uuid } from 'uuid'
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  DBFile,
  FileUrl,
  UploadFilePayload,
} from '@/services/bimoboxApiService/types'

import { colors } from '@/utils/constants'

import { FileService } from '@/services/bimoboxApiService'

@Component({ name: 'create-visa-observation' })
export default class CreateVisaObservation extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean

  @Prop({ type: String, required: true })
  readonly text: string

  @Prop({ type: Array as PropType<FileUrl[]>, required: true })
  readonly files: FileUrl[]

  @Prop({ type: Boolean, required: true })
  readonly validated: boolean

  @Prop({ type: Boolean, required: true })
  readonly loading: boolean

  readonly colors = colors
  readonly fileService = new FileService()
  readonly fileInputRef = uuid()

  description: string | null = ''

  @Watch('description')
  onDescriptionChange(newVal: string | null): void {
    if (this.text !== newVal) {
      this.$emit('update:text', newVal === null ? '' : newVal)
    }
  }

  @Watch('text')
  onTextChange(newVal: string): void {
    if (this.description !== newVal) {
      this.description = newVal
    }
  }

  mounted(): void {
    this.description = this.text
  }

  setLoading(val: boolean): void {
    this.$emit('update:loading', val)
  }

  onPickFile(): void {
    if (this.loading === true) {
      return
    }

    const fileInput = this.$refs[this.fileInputRef] as HTMLInputElement
    fileInput.click()
  }

  async uploadFiles(evt): Promise<void> {
    this.setLoading(true)
    const files = [...evt.target.files]
    if (files.length === 0) {
      this.setLoading(false)
      return
    }

    const promises = files.map((file: File) => {
      const payload: UploadFilePayload = { file }
      return this.fileService.uploadFile(payload)
    })

    try {
      const files: DBFile[] = await Promise.all(promises)
      this.addFiles(files)
    } catch (error) {
      console.error(error)
    }

    this.setLoading(false)
  }

  addFiles(newFiles: DBFile[]): void {
    const fileUrl: FileUrl[] = newFiles.map((f) => ({
      id: f.id,
      name: f.fileName,
      url: `${f.url}/${f.containerName}/${f.fileName}`,
    }))

    this.$emit('update:files', [...this.files, ...fileUrl])
  }

  async deleteFile(file: DBFile): Promise<void> {
    this.$emit(
      'update:files',
      this.files.filter((f) => f.id !== file.id)
    )
  }

  async validateOrDelete(): Promise<void> {
    if (this.disabled) {
      return
    }

    if (!this.validated && !this.canValidate) {
      return
    }

    this.$emit(this.validated ? 'delete' : 'validate')
  }

  get canValidate(): boolean {
    return this.text !== '' || this.files.length > 0
  }
}
