
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  IBasicPhase,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'

import {
  getAlertModule,
  getPhaseModule,
  getProjectModule,
  getTeamModule,
} from '@/store/utils'
import { colors } from '@/utils/constants'

import ToggleLockPhaseModal from '@/components/project/phase/toggleLock.phase.modal.vue'
import CreatePhaseModal from '@/components/project/phase/create.phase.modal.vue'
import DeletePhaseModal from '@/components/project/phase/delete.phase.modal.vue'
import UpdatePhaseModal from '@/components/project/phase/update.phase.modal.vue'
import CreateFolderModal from '@/components/project/documents/folders/create.folder.modal.vue'
import PhaseSelector from '@/components/project/PhaseSelector.vue'
import GetVisaReportModal from '@/components/project/documents/get.visa.report.modal.vue'

const components = {
  ToggleLockPhaseModal,
  CreatePhaseModal,
  DeletePhaseModal,
  UpdatePhaseModal,
  CreateFolderModal,
  PhaseSelector,
  GetVisaReportModal,
}

const Store = {
  ...getAlertModule(),
  ...getProjectModule(),
  ...getPhaseModule(),
  ...getTeamModule(),
}

interface IDocumentHeaderMenuItem {
  name: string
  action: () => void
}

@Component({ name: 'documents-header', components })
export default class DocumentsHeader extends Vue {
  @Prop({ type: String, required: true })
  readonly search: string

  @Prop({ type: Boolean, default: false })
  readonly selectFiles: boolean

  readonly colors = colors

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase | null

  @Store.ProjectModule.Getter
  readonly isCreatorOrAdmin: boolean

  @Store.PhaseModule.Getter
  readonly userPhases: IBasicPhase[]

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  @Store.PhaseModule.Getter
  readonly doesPhaseContainsDocuments: boolean

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  showTogglePhaseLockConfirmation = false
  showCreatePhaseModal = false
  showCreateFolderModal = false
  showUpdatePhaseModal = false
  showDeletePhaseModal = false
  actionsBtnLoading = false
  showGetVisaReportModal = false

  get searchDocument(): string {
    return this.search
  }

  set searchDocument(val: string | null) {
    this.$emit('update:search', val ?? '')
  }

  get menuItems(): IDocumentHeaderMenuItem[] {
    const items = [
      {
        right: this.hasRight(TeamRightGroup.PHASE, TeamRight.CREATE),
        name: 'phase_create',
        action: () => (this.showCreatePhaseModal = true),
      },
      {
        right:
          this.currentPhase?.locked === false &&
          this.hasRight(TeamRightGroup.FOLDER, TeamRight.CREATE),
        name: 'folder_create',
        action: () => (this.showCreateFolderModal = true),
      },
      {
        right:
          this.currentPhase !== null &&
          this.hasRight(TeamRightGroup.PHASE, TeamRight.UPDATE),
        name: 'phase_update',
        action: () => (this.showUpdatePhaseModal = true),
      },
      {
        right:
          this.currentPhase !== null &&
          this.hasRight(TeamRightGroup.PHASE, TeamRight.DELETE),
        name: 'phase_delete',
        action: () => (this.showDeletePhaseModal = true),
      },
      {
        right:
          this.currentPhase !== null &&
          this.doesPhaseContainsDocuments === true,
        name: 'documents_download',
        action: () => this.toggleFileSelection(true),
      },
      {
        right: this.currentPhase !== null && this.doesPhaseContainsDocuments,
        name: 'generate_phase_report',
        action: () => (this.showGetVisaReportModal = true),
      },
    ]

    return items.filter(({ right }) => right === true)
  }

  isChoiceAvailable(nameToFind: string): boolean {
    return this.menuItems
      .map(({ name }) => {
        const splitedName = name.split('.')
        return splitedName[splitedName.length - 1]
      })
      .includes(nameToFind)
  }

  @Watch('userPhases')
  onUserPhasesChange(newVal: IBasicPhase[], oldVal: IBasicPhase[]): void {
    if (oldVal.length === 0 && newVal.length > 0) {
      const query = {
        ...this.$route.query,
        phase: this.userPhases[0].id,
      }

      this.$router.replace({ query })
    }
  }

  toggleFileSelection(val: boolean): void {
    this.$emit('toggleFileSelection', val)
  }

  @Watch('currentPhase')
  onCurrentPhaseChange(val: IBasicPhase | null): void {
    this.toggleFileSelection(false)
    if (!val && this.showDeletePhaseModal === true) {
      this.showDeletePhaseModal = false
      const query = {
        ...this.$route.query,
        phase: this.userPhases.length > 0 ? this.userPhases[0].id : undefined,
      }
      this.$router.replace({ query })
    }
  }
}
