export default class Debouncer {
  private timerId = 0

  constructor(
    private readonly onDebounce: (...args) => void,
    private readonly debounceTime: number = 500
  ) {}

  debounce(...args): void {
    clearTimeout(this.timerId)
    this.timerId = window.setTimeout(() => {
      this.onDebounce(...args)
    }, this.debounceTime)
  }
}
