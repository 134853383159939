import { render, staticRenderFns } from "./ResetPassword.vue?vue&type=template&id=010c7ffe&"
import script from "./ResetPassword.vue?vue&type=script&lang=ts&"
export * from "./ResetPassword.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_@vue+compiler-sfc@2.7.14_cache-loader@4.1.0_webpack@4.46.0__css-loader@3.6_ju5lionjccu4x6spbwsmqeqe6i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports