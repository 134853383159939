import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  Endpoint,
  IArboPayload,
  IArborescenceContent,
  ICreateFolderPayload,
  IFolder,
  IUpdateFolderPayload,
} from '@/services/bimoboxApiService/types'

export class FolderService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createFolder(
    projectId: string,
    phaseId: string,
    payload: ICreateFolderPayload
  ): Promise<IFolder> {
    const url = this.buildFullUrl(projectId, phaseId)
    try {
      return await this.apiService.post<IFolder>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getFolderById(
    projectId: string,
    phaseId: string,
    folderId: string
  ): Promise<IFolder> {
    const url = this.buildFullUrl(projectId, phaseId, folderId)
    try {
      return await this.apiService.get<IFolder>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateFolderById(
    projectId: string,
    phaseId: string,
    payload: IUpdateFolderPayload
  ): Promise<IFolder> {
    const { id, name, type } = payload
    const url = this.buildFullUrl(projectId, phaseId, id)
    try {
      return await this.apiService.put<IFolder>(url, { name, type })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async deleteFolderById(
    projectId: string,
    phaseId: string,
    folderId: string
  ): Promise<void> {
    const url = this.buildFullUrl(projectId, phaseId, folderId)
    try {
      return await this.apiService.delete<void>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async createArborescence(
    projectId: string,
    phaseId: string,
    payload: IArboPayload
  ): Promise<IArborescenceContent> {
    const url = this.buildFullUrl(projectId, phaseId, 'arbo')
    try {
      return await this.apiService.post<IArborescenceContent>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private buildFullUrl(
    projectId: string,
    phaseId: string,
    params = ''
  ): string {
    return this.buildUrl(
      `${projectId}/${Endpoint.PHASE}/${phaseId}/${Endpoint.FOLDER}/${params}`
    )
  }
}
