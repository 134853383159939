import { FileUrl, PublicUser } from '@/services/bimoboxApiService/types'

export enum VisaObservationStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  SUSPENDED = 'suspended',
  ACCEOTED_WITH_OBS = 'accepted_with_comment',
  OUT_OF_MISSION = 'out_of_mission',
}

export interface IVisaObservationContent {
  text: string
  files: FileUrl[]
}

export interface IVisaObservation {
  id: string
  user: PublicUser
  content: IVisaObservationContent[]
  status: VisaObservationStatus
  date: number
}

export interface ICreateVisaObservationPayload {
  status: VisaObservationStatus
  content: { text: string; files: string[] }[]
}
