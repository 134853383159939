import Vue from 'vue'
import Vuex from 'vuex'

import advice from '@/store/modules/advice.module'
import alert from '@/store/modules/alert.module'
import dashboard from '@/store/modules/dashboard.module'
import document from '@/store/modules/document.module'
import documentVersion from '@/store/modules/documentVersion.module'
import folder from '@/store/modules/folder.module'
import guest from '@/store/modules/guest.module'
import pageLoader from '@/store/modules/pageLoader.module'
import phase from '@/store/modules/phase.module'
import project from '@/store/modules/project.module'
import servicebus from '@/store/modules/serviceBus.module'
import team from '@/store/modules/team.module'
import user from '@/store/modules/user.module'
import visa from '@/store/modules/visa.module'
import visaObservation from '@/store/modules/visaObservation.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    alert,
    dashboard,
    pageLoader,
    project,
    team,
    phase,
    guest,
    folder,
    document,
    servicebus,
    visaObservation,
    visa,
    documentVersion,
    advice,
  },
})
