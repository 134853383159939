
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { PublicUser } from '@/services/bimoboxApiService/types'
import { AlertWithData, I18NText } from '@/types/global'

import { getAlertModule, getGuestModule } from '@/store/utils'

import ConfirmationModal from '@/components/common/modals/confirmation.modal.vue'

const components = {
  ConfirmationModal,
}

const Store = {
  ...getAlertModule(),
  ...getGuestModule(),
}

@Component({ name: 'resendlink-modal', components })
export default class ResendlinkModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<PublicUser>, required: true })
  readonly guest: PublicUser

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.GuestModule.Action
  readonly resendLink: (id: string) => Promise<void>

  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.loading = false
    this.$emit('input', value)
  }

  get confirmationModalText(): I18NText {
    return {
      key: 'guest_send_link_confirmation',
      data: { name: this.guest.displayName },
    }
  }

  async action(): Promise<void> {
    this.loading = true

    try {
      await this.resendLink(this.guest.id)
      this.ALERT_SUCCESS_WITH_DATA({
        message: 'guest_send_link_success',
        data: { name: this.guest.displayName },
      })
    } catch (error) {
      this.ALERT_ERROR(error as string)
    }

    this.show = false
  }
}
