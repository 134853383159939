
import { Component, Vue, Watch } from 'vue-property-decorator'

import { Project, Team } from '@/services/bimoboxApiService/types'
import { VuetifyDataTableHeader, VuetifyDataTableRow } from '@/types/global'

import { getAlertModule, getProjectModule, getTeamModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import store from '@/store'

import ResizableTable from '@/components/common/resizableTable.vue'
import CreateTeamModal from '@/components/project/teams/create.team.modal.vue'
import DeleteTeamModal from '@/components/project/teams/delete.team.modal.vue'
import TeamModal from '@/components/project/teams/team.modal.vue'
import TeamTab from '@/components/project/teams/team.tab.vue'
import TeamsUsersNavDrawer from '@/components/project/teams/TeamsUsersNavDrawer.vue'
import UpdateTeamModal from '@/components/project/teams/update.team.modal.vue'

const components = {
  CreateTeamModal,
  UpdateTeamModal,
  TeamModal,
  DeleteTeamModal,
  ResizableTable,
  TeamTab,
  TeamsUsersNavDrawer,
}

const Store = {
  ...getProjectModule(),
  ...getTeamModule(),
  ...getAlertModule(),
}

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate'])
@Component({ name: 'teams', components })
export default class Teams extends Vue {
  async beforeRouteEnter(to, from, next) {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    return next()
  }

  async beforeRouteUpdate(to, from, next) {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    return next()
  }

  @Store.ProjectModule.Getter
  readonly isCreator: boolean

  @Store.ProjectModule.Getter
  readonly isAdmin: boolean

  @Store.ProjectModule.State
  readonly project: Project

  readonly colors = colors

  get isCreatorOrAdmin(): boolean {
    return this.isCreator || this.isAdmin
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  @Store.TeamModule.State
  readonly teams: Team[]

  search = ''

  get sortedTeams(): Team[] {
    const teamsCopy = [...this.teams]
    teamsCopy.sort((a: Team, b: Team): number => {
      const aName = a.name.toLowerCase()
      const bName = b.name.toLowerCase()

      return aName === bName ? 0 : a > b ? 1 : -1
    })

    if (this.search.length === 0) {
      return teamsCopy
    }

    return teamsCopy.filter(({ name }) =>
      name.toLowerCase().startsWith(this.search.toLowerCase())
    )
  }

  selectedTeam: Team | null = null

  @Watch('selectedTeam', { deep: true, immediate: true })
  onSelectedTeamChange(value: Team | null): void {
    if (value !== null) {
      if (this.teamToUpdate || this.teamToDelete) {
        this.selectedTeam = null
      } else {
        if (this.isMobile) {
          this.showTeamModal = true
        }
      }
    }
  }

  @Watch('teams', { deep: true, immediate: true })
  onTeamsChange(): void {
    if (this.selectedTeam !== null) {
      const teamToReselect = this.teams.find(
        ({ id }) => this.selectedTeam?.id === id
      )
      this.selectedTeam = teamToReselect === undefined ? null : teamToReselect
    }
  }

  showTeamModal = false

  @Watch('showTeamModal')
  onShowTeamModalChange(value: boolean): void {
    if (value === false) {
      this.selectedTeam = null
    }
  }

  selectedRow: VuetifyDataTableRow | null = null

  selectTeam(team: Team | null, row: VuetifyDataTableRow): void {
    if (this.selectedRow) {
      this.selectedRow.select(false)
    }

    this.selectedRow = row
    this.selectedRow.select(true)
    this.selectedTeam = team
  }

  get headerInfo(): VuetifyDataTableHeader[] {
    return [
      {
        value: 'color',
        align: 'center',
        filterable: false,
        sortable: false,
        width: '40',
      },
      {
        text: (this.$tc('team', 1) as string).toUpperCase(),
        value: 'name',
        align: 'start',
      },
      {
        text: (this.$t('team_members') as string).toUpperCase(),
        value: 'members',
        align: 'center',
        filterable: false,
      },
      {
        text: (this.$t('actions') as string).toUpperCase(),
        value: 'actions',
        filterable: false,
        sortable: false,
        align: 'end',
      },
    ]
  }

  showCreateTeamModal = false
  showUpdateTeamModal = false
  showDeleteTeamConfirmationModal = false
  teamToUpdate: Team | null = null
  teamToDelete: Team | null = null

  @Watch('showUpdateTeamModal')
  onShowUpdateTeamModalChange(value: boolean): void {
    if (value === false) {
      this.teamToUpdate = null
    }
  }

  @Watch('showDeleteTeamConfirmationModal')
  onShowDeleteTeamChange(value: boolean): void {
    if (!value) {
      this.teamToDelete = null
    }
  }
}
