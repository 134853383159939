import { ProjectService } from '@/services/bimoboxApiService'
import {
  Address,
  CreateProjectPayload,
  FileUrl,
  Project,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class ProjectModule extends VuexModule {
  private readonly projectService = new ProjectService()
  project: Project | null = null

  avatars: FileUrl[] | null = null

  address: Address | null = null

  get isCreator(): boolean {
    return this.project?.creator.id === this.context.rootState.user.user?.id
  }

  get isAdmin(): boolean {
    return (
      this.project?.guests
        .filter(({ isAdmin }) => isAdmin === true)
        .map(({ user }) => user.id)
        .includes(this.context.rootState.user.user?.id) ?? false
    )
  }

  get isCreatorOrAdmin(): boolean {
    return this.isAdmin || this.isCreator
  }

  @Mutation
  private RESET_PROJECT_STATES(): void {
    this.project = null
    this.address = null
    this.avatars = null
  }

  @Mutation
  private SET_PROJECT(val: Project | null): void {
    this.project = val
  }

  @Mutation
  private SET_PROJECT_AVATARS(val: FileUrl[]): void {
    this.avatars = [...val]
  }

  @Mutation
  private UPDATE_PROJECT(val: { project: Project; address: Address }): void {
    if (!this.project) {
      return
    }

    this.address = val.address
    this.project.name = val.project.name
    this.project.description = val.project.description
    this.project.dueDate = val.project.dueDate
  }

  @Mutation
  private SET_PROJECT_ADDRESS(val: Address): void {
    this.address = val
  }

  @Action({ rawError: true, commit: 'SET_PROJECT' })
  async fetchProject(id: string): Promise<Project> {
    try {
      const project = await this.projectService.fetchProject(id)
      this.context.commit('RESET_PROJECT_STATES')
      return project
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  @Action({ rawError: true, commit: 'UPDATE_PROJECT' })
  updateProject(
    data: CreateProjectPayload
  ): Promise<{ project: Project; address: Address | null }> {
    try {
      const project = this.project as Project
      return this.projectService.updateProject(project.id, data)
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'SET_PROJECT_AVATARS' })
  async fetchProjectAvatars(): Promise<FileUrl[]> {
    if (!this.project) {
      return []
    }

    return (
      this.avatars ?? this.projectService.fetchProjectAvatars(this.project.id)
    )
  }

  @Action({ rawError: true, commit: 'SET_PROJECT_AVATARS' })
  async updateProjectAvatars(avatarsIds: string[]): Promise<FileUrl[]> {
    try {
      return this.project
        ? this.projectService.updateProjectAvatars(this.project.id, avatarsIds)
        : []
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'SET_PROJECT_ADDRESS' })
  async fetchProjectAddress(): Promise<Address | null> {
    if (!this.project) {
      return null
    }

    if (this.address) {
      return this.address
    }

    const { address } = await this.projectService.fetchProjectAddress(
      this.project.id
    )
    return address
  }

  @Action({ rawError: true, commit: 'SET_PROJECT_ADDRESS' })
  updateProjectAddress(address: Address): Promise<Address> {
    try {
      const id = this.project?.id as string
      return this.projectService.updateProjectAddress(id, address)
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ commit: 'RESET_PROJECT_STATES' })
  resetProjectStates(all: boolean): void {
    this.context.commit('phase/RESET_PHASE_STATES', all, { root: true })
    this.context.commit('team/SET_TEAMS', null, { root: true })
    this.context.commit('document/RESET_DOCUMENT_STATES', undefined, {
      root: true,
    })
    this.context.commit('folder/RESET_FODLER_STATES', undefined, { root: true })
    this.context.dispatch('servicebus/resetSbStates', undefined, { root: true })
  }
}
