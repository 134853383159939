
import { Component, Vue, Watch } from 'vue-property-decorator'

import { RequestAlert } from '@/types/global'

import { getAlertModule } from '@/store/utils'
import { colors } from '@/utils/constants'

const Store = getAlertModule()

@Component({ name: 'request-alert' })
export default class RequestAlertComponent extends Vue {
  @Store.AlertModule.State
  readonly requestAlert: RequestAlert | null

  showRequestAlert = false

  @Watch('requestAlert')
  onRequestAlertChange(val: RequestAlert | null): void {
    this.showRequestAlert = val !== null
  }

  @Store.AlertModule.Mutation
  readonly RESELT_ALERT_STATES: () => void

  readonly colors = colors
  show = false

  @Watch('requestAlert')
  onRequestAlertChanged(value: boolean): void {
    this.show = !!value
  }

  get message(): string {
    if (this.requestAlert === null) {
      return ''
    }

    if (this.requestAlert.pluralize) {
      return this.$tc(
        this.requestAlert.message,
        this.requestAlert.messageData.count
      )
    }

    return this.$t(
      this.requestAlert.message,
      this.requestAlert.messageData
    ) as string
  }

  close(): void {
    this.RESELT_ALERT_STATES()
  }

  get alertColor(): string {
    return this.requestAlert?.type || ''
  }
}
