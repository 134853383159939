
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IBasicPhase, Team } from '@/services/bimoboxApiService/types'
import { AlertWithData, I18NText } from '@/types/global'

import { getAlertModule, getPhaseModule } from '@/store/utils'

import ConfirmationModal from '@/components/common/modals/confirmation.modal.vue'

const components = {
  ConfirmationModal,
}

const Store = {
  ...getAlertModule(),
  ...getPhaseModule(),
}

@Component({ name: 'delete-phase-modal', components })
export default class DeletePhaseModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<Team>, required: true })
  readonly phase: IBasicPhase

  @Store.PhaseModule.Action
  readonly deletePhases: (ids: string[]) => Promise<void>

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.loading = false
    this.$emit('input', value)
  }

  get confirmationModalText(): I18NText {
    if (!this.show) {
      return { key: '' }
    }

    return {
      key: 'phase_delete_confirmation',
      data: { name: this.phase.name },
    }
  }

  async action(): Promise<void> {
    this.loading = true

    try {
      await this.deletePhases([this.phase.id])
      this.ALERT_SUCCESS_WITH_DATA({
        message: `phase_delete_success`,
        data: { name: this.phase.name },
      })
    } catch (error) {
      this.ALERT_ERROR(error as string)
    }

    this.show = false
  }
}
