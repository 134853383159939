import { VisaService } from '@/services/bimoboxApiService'
import {
  ICancelVisaPayload,
  ICreateVisaPayload,
  IVisa,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class VisaModule extends VuexModule {
  private readonly visaService = new VisaService()

  visas: IVisa[] | null = null

  get getVisaById(): (visaId: string) => IVisa | null {
    return (visaId: string): IVisa | null => {
      const visa = this.visas?.find(({ id }) => id === visaId)
      return visa === undefined ? null : visa
    }
  }

  @Mutation
  ADD_VISAS(visas: IVisa[]): void {
    if (!this.visas) {
      this.visas = []
    }

    this.visas = this.visas.filter(
      ({ id }) => !visas.map((o) => o.id).includes(id)
    )
    this.visas.push(...visas)
  }

  @Mutation
  RESET_VISA_STATES(): void {
    this.visas = null
  }

  @Mutation
  UPDATE_VISA(visa: IVisa): void {
    if (!this.visas) {
      return
    }

    const index = this.visas.findIndex(({ id }) => id === visa.id)
    if (index > -1) {
      this.visas.splice(index, 1, visa)
    }
  }

  @Mutation
  DELETE_VISAS_BY_IDS(ids: string[]): void {
    if (!this.visas) {
      return
    }

    this.visas = [...this.visas.filter(({ id }) => !ids.includes(id))]
  }

  @Mutation
  REMOVE_OBSERVATION_IN_VISA(data: {
    id: string
    observationsIds: string
  }): void {
    if (!this.visas) {
      return
    }

    const index = this.visas.findIndex(({ id }) => data.id === id)
    if (index > -1) {
      const visa = { ...this.visas[index] }
      visa.observationsIds = visa.observationsIds.filter(
        (id) => !data.observationsIds.includes(id)
      )
      this.visas.splice(index, 1, visa)
    }
  }

  @Action({ rawError: true })
  async createVisa(payload: ICreateVisaPayload): Promise<void> {
    try {
      const { visa } = await this.visaService.createVisa(
        this.projectId,
        this.currentPhaseId,
        payload
      )
      this.context.commit('ADD_VISAS', [visa])
      this.context.commit(
        'documentVersion/ADD_VERSION_VISA',
        { id: payload.versionId, visaId: visa.id },
        { root: true }
      )
    } catch (error) {
      console.error('createVisa', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true })
  async cancelVisa(payload: ICancelVisaPayload): Promise<void> {
    try {
      await this.visaService.cancelVisa(
        this.projectId,
        this.currentPhaseId,
        payload
      )
      this.context.commit('DELETE_VISAS_BY_IDS', [payload.visaId])
      this.context.commit(
        'documentVersion/DELETE_VERSION_VISA',
        { id: payload.versionId, visaId: payload.visaId },
        { root: true }
      )
    } catch (error) {
      console.error('createVisa', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'DELETE_VISAS_BY_IDS' })
  deleteVisasByIds(ids: string[]): string[] {
    if (!this.visas) {
      return []
    }

    const observationsToDelete = this.visas
      .filter(({ id }) => ids.includes(id))
      .flatMap(({ observationsIds }) => observationsIds)
    this.context.commit(
      'visaObservation/DELETE_OBSERVATIONS_BY_IDS',
      observationsToDelete,
      {
        root: true,
      }
    )
    return ids
  }

  private get projectId(): string {
    return this.context.rootState.project.project.id
  }

  private get currentPhaseId(): string {
    return this.context.rootState.phase.currentPhaseId
  }
}
