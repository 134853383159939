import { ProjectService } from '@/services/bimoboxApiService'
import {
  CreateProjectPayload,
  DashboardProject,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class DashboardModule extends VuexModule {
  private readonly projectService = new ProjectService()
  projects: DashboardProject[] | null = null

  @Mutation
  RESET_DASHBOARD_STATES(): void {
    this.projects = null
  }

  @Mutation
  private SET_PROEJCTS(val: DashboardProject[]): void {
    this.projects = val
  }

  @Mutation
  private APPEND_PROJECT(val: DashboardProject): void {
    this.projects ? this.projects.push(val) : [val]
  }

  @Action({ rawError: true, commit: 'SET_PROEJCTS' })
  async fetchUserProjects(): Promise<DashboardProject[]> {
    try {
      return this.projects || (await this.projectService.getUserProjects())
    } catch {
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'APPEND_PROJECT' })
  createProject(data: CreateProjectPayload): Promise<DashboardProject> {
    try {
      return this.projectService.createProject(data)
    } catch {
      throw 'unknown_error'
    }
  }
}
