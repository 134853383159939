
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Team } from '@/services/bimoboxApiService/types'
import { AlertWithData, I18NText } from '@/types/global'

import { getAlertModule, getPhaseModule, getTeamModule } from '@/store/utils'

import ConfirmationModal from '@/components/common/modals/confirmation.modal.vue'

const components = {
  ConfirmationModal,
}

const Store = {
  ...getAlertModule(),
  ...getTeamModule(),
  ...getPhaseModule(),
}

@Component({ name: 'delete-team-modal', components })
export default class DeleteTeamModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<Team>, required: true })
  readonly team: Team

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.TeamModule.Action
  readonly deleteTeam: (id: string) => Promise<void>

  @Store.PhaseModule.Action
  readonly fetchPhases: () => Promise<void>

  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.loading = false
    this.$emit('input', value)
  }

  get confirmationModalText(): I18NText {
    if (this.team && this.show) {
      return {
        key: 'team_delete_confirmation',
        data: { name: this.team.name },
      }
    }

    return { key: '' }
  }

  async action(): Promise<void> {
    this.loading = true

    try {
      await this.deleteTeam(this.team.id)
      await this.fetchPhases()
      this.ALERT_SUCCESS_WITH_DATA({
        message: 'team_delete_success',
        data: { name: this.team.name },
      })
    } catch (error) {
      this.ALERT_ERROR(error as string)
    }

    this.show = false
  }
}
