
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Project, PublicUser, Team } from '@/services/bimoboxApiService/types'
import { AlertWithData } from '@/types/global'

import {
  getAlertModule,
  getGuestModule,
  getProjectModule,
  getTeamModule,
} from '@/store/utils'

import FormActions from '@/components/common/actions/form.actions.vue'
import BaseModal from '@/components/common/modals/base.modal.vue'
import GuestsSelector from '@/components/project/GuestsSelector.vue'

const components = {
  FormActions,
  BaseModal,
  GuestsSelector,
}

const Store = {
  ...getAlertModule(),
  ...getTeamModule(),
  ...getProjectModule(),
  ...getGuestModule(),
}

@Component({ name: 'delete-guests-modal', components })
export default class DeleteGuestsModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  error = ''
  loading = false
  guestsToDelete: string[] = []

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.ProjectModule.State
  readonly project: Project

  @Store.ProjectModule.Getter
  readonly isCreator: boolean

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.GuestModule.Action
  readonly kickGuests: (ids: string[]) => Promise<void>

  get projectGuests(): PublicUser[] {
    return this.project.guests
      .filter(
        ({ isKicked, isAdmin }) =>
          !isKicked && (this.isCreator ? true : !isAdmin)
      )
      .map(({ user }) => user)
  }

  resetData(): void {
    this.loading = false
    this.guestsToDelete = []
  }

  get isFormValid(): boolean {
    return this.guestsToDelete.length > 0
  }

  async primaryAction(): Promise<void> {
    this.loading = true
    try {
      await this.kickGuests(this.guestsToDelete)
      const alertData: AlertWithData = {
        message: 'guests_delete_success',
        data: { count: this.guestsToDelete.length },
        pluralize: true,
      }

      this.ALERT_SUCCESS_WITH_DATA(alertData)
      this.show = false
    } catch (error) {
      console.error(error)
      this.error = error as string
      this.resetData()
    }
  }
}
