import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import { Endpoint } from '@/services/bimoboxApiService/types'
import {
  IActiveAdvice,
  IAdvice,
  ICreateAdvicePayload,
  IEmitAdvicePayload,
} from './advice.types'

export class AdviceService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createAdvice(
    projectId: string,
    phaseId: string,
    payload: ICreateAdvicePayload
  ): Promise<IAdvice> {
    const { versionId, documentId, folderId, data } = payload

    const url = this.buildFullUrl(
      projectId,
      phaseId,
      folderId,
      documentId,
      versionId
    )

    return this.apiService.post<IAdvice>(url, data).catch((err) => {
      console.error(err)

      throw err
    })
  }

  async emitAdvice(
    projectId: string,
    phaseId: string,
    payload: IEmitAdvicePayload
  ): Promise<IAdvice> {
    const { versionId, documentId, adviceId, folderId, data } = payload

    const url = this.buildFullUrl(
      projectId,
      phaseId,
      folderId,
      documentId,
      versionId,
      adviceId
    )

    return this.apiService.post<IAdvice>(url, data).catch((err) => {
      console.error(err)

      throw err
    })
  }

  async getActiveAdvices(projectId: string): Promise<IActiveAdvice[]> {
    const url = `${Endpoint.PROJECT}/${projectId}/advice/active`
    try {
      return await this.apiService.get<IActiveAdvice[]>(url)
    } catch (err) {
      console.error(err)

      throw err
    }
  }

  private buildFullUrl(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string,
    versionId: string,
    params = ''
  ): string {
    return this.buildUrl(
      `${projectId}/${Endpoint.PHASE}/${phaseId}/${Endpoint.FOLDER}/${folderId}/${Endpoint.DOCUMENT}/${documentId}/${Endpoint.VERSION}/${versionId}/${Endpoint.ADVICE}/${params}`
    )
  }
}
