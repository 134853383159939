import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  ActivateGuestPayload,
  Endpoint,
  LoginPayload,
  RegisterPayload,
  ResetPasswordPayload,
  SendActivationPayload,
  SendResetPasswordPayload,
} from '@/services/bimoboxApiService/types'

export class AuthService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.AUTH)
  }

  async login(payload: LoginPayload): Promise<void> {
    const url = this.buildUrl('local')
    try {
      await this.apiService.post<void>(url, payload, { validateStatus: null })
    } catch (error) {
      console.error(error)
      throw 'login_error'
    }
  }

  async register(payload: RegisterPayload): Promise<void> {
    const url = this.buildUrl('register')
    try {
      await this.apiService.post<void>(url, payload)
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  async sendActivationEmail(payload: SendActivationPayload): Promise<void> {
    const url = this.buildUrl(`activate/${payload.email}`)
    try {
      await this.apiService.post<void>(url, { recaptcha: payload.recaptcha })
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  async sendResetPassword(payload: SendResetPasswordPayload): Promise<void> {
    const url = this.buildUrl(`password/reset/${payload.email}`)
    try {
      await this.apiService.post<void>(url, { recaptcha: payload.recaptcha })
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  async resetPassword(payload: ResetPasswordPayload): Promise<void> {
    const url = this.buildUrl('password/reset')
    try {
      await this.apiService.post<void>(url, payload)
    } catch (error) {
      console.error(error)
      throw 'reset_password_error'
    }
  }

  async signOut(): Promise<void> {
    await this.apiService.post<void>(this.buildUrl('sign-out'), {})
  }

  async activateGuest(
    email: string,
    payload: ActivateGuestPayload
  ): Promise<void> {
    const url = this.buildUrl(`activate/guest/${email}`)
    try {
      await this.apiService.post<void>(url, payload)
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }
}
