import { IDocument } from '@/services/bimoboxApiService/document/document.types'
import { IFolder } from '@/services/bimoboxApiService/folder/folder.types'
import {
  IAdvice,
  IDocumentVersion,
  IVisa,
  IVisaObservation,
} from '@/services/bimoboxApiService/types'

export enum PhaseGroup {
  CONCEPTION = 'conception',
  CONSTRUCTION = 'construction',
  EXPLOITATION = 'exploitation',
}

export interface IBasicPhase {
  id: string
  name: string
  group: PhaseGroup
  locked: boolean
  guestsIds: string[]
  visaDelay: number
  visaEmailDelay: number
  documentUpdateDelay: number
}

export interface ICreatePhasePayload {
  name: string
  group: PhaseGroup
  guestsIds: string[]
  visaDelay: number
  visaEmailDelay: number
  documentUpdateDelay: number
}

export interface ILockPhasePayload {
  locked: boolean
}

export interface IUpdatePhasePayload {
  name: string
  guestsIds: string[]
  visaDelay: number
  visaEmailDelay: number
  documentUpdateDelay: number
}

export interface IPhaseContent {
  documents: IDocument[]
  folders: IFolder[]
  versions: IDocumentVersion[]
  visas: IVisa[]
  observations: IVisaObservation[]
  advices: IAdvice[]
}

export interface IGetVisaReportQuery {
  includeTRD: boolean
  includeVisaLess: boolean
}
