
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { PublicFile } from '@/services/bimoboxApiService/types'

import { colors } from '@/utils/constants'

import { FileService } from '@/services/bimoboxApiService'

@Component({ name: 'image-preview-modal' })
export default class ImagePreviewModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ required: true, type: Object as PropType<PublicFile> })
  readonly file: PublicFile

  readonly colors = colors
  readonly fileService = new FileService()

  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.loading = false
    this.$emit('input', value)
  }

  async callDownloadFile(): Promise<void> {
    this.loading = true
    try {
      const namedBlob = await this.fileService.downloadFile(
        this.file.id,
        this.file.name
      )
      namedBlob.downloadLocal()
    } catch (error) {
      console.error('[ImagePreviewModal]', error)
    }

    this.loading = false
  }
}
