
import { AuthRoutes, colors } from '@/utils/constants'
import { Component, Vue, Watch } from 'vue-property-decorator'

import { getPageLoaderModule } from '@/store/utils'

import PageLoader from '@/components/common/page.loader.vue'

const Store = getPageLoaderModule()

@Component({ name: 'app', components: { PageLoader } })
export default class App extends Vue {
  @Store.PageLoaderModule.State
  readonly pageLoading: boolean

  @Store.PageLoaderModule.State
  readonly hasEntered: boolean

  get currentRouteName(): string {
    return this.$route.name || ''
  }

  mounted(): void {
    this.setBackgroundColor()
  }

  setBackgroundColor(): void {
    const appElement: HTMLElement | null = document.getElementById('app')
    if (appElement !== null) {
      const bgColor: string = AuthRoutes.includes(this.currentRouteName)
        ? colors.grey
        : '#ffffff'
      appElement.style['background-color'] = bgColor
    }
  }

  @Watch('currentRouteName')
  onRouteChange(): void {
    this.setBackgroundColor()
  }

  get showPageLoader(): boolean {
    return this.pageLoading && !this.hasEntered
  }
}
