import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  Endpoint,
  ICreateDocumentPayload,
  IDocumentContent,
  IDocumentEmailDTO,
  IUpdateDocumentPayload,
} from '@/services/bimoboxApiService/types'
import { apiBaseUrl } from '@/utils/constants'
import { enrichUserAvatar } from '@/utils/imageUrlGetter'

export class DocumentService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createDocument(
    projectId: string,
    phaseId: string,
    folderId: string,
    payload: ICreateDocumentPayload
  ): Promise<IDocumentContent> {
    const url = this.buildFullUrl(projectId, phaseId, folderId)
    try {
      return this.apiService
        .post<IDocumentContent>(url, payload)
        .then((d) => this.enrichDocument(d))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getDocumentById(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string
  ): Promise<IDocumentContent> {
    const url = this.buildFullUrl(projectId, phaseId, folderId, documentId)
    try {
      return this.apiService
        .get<IDocumentContent>(url)
        .then((d) => this.enrichDocument(d))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateDocumentById(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string,
    payload: IUpdateDocumentPayload
  ): Promise<IDocumentContent> {
    const url = this.buildFullUrl(projectId, phaseId, folderId, documentId)
    try {
      return this.apiService
        .put<IDocumentContent>(url, payload)
        .then((d) => this.enrichDocument(d))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getDocumentEmailOpts(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string
  ): Promise<IDocumentEmailDTO> {
    const url = this.buildFullUrl(
      projectId,
      phaseId,
      folderId,
      `${documentId}/notification`
    )

    return this.apiService.get<IDocumentEmailDTO>(url).catch((error) => {
      console.error(error)
      throw error
    })
  }

  async deleteDocumentById(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string
  ): Promise<void> {
    const url = this.buildFullUrl(projectId, phaseId, folderId, documentId)
    return this.apiService.delete<void>(url).catch((error) => {
      console.error(error)
      throw error
    })
  }

  async retryTrdDocumentUpload(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string
  ): Promise<void> {
    const url = this.buildFullUrl(
      projectId,
      phaseId,
      folderId,
      `${documentId}/retry`
    )

    return this.apiService.post<void>(url).catch((err) => {
      throw err
    })
  }

  private enrichDocument(d: IDocumentContent): IDocumentContent {
    d.versions = d.versions.map((v) => {
      v.fileUrl = new URL(`${apiBaseUrl}/file/${v.fileUrl}`).toString()
      v.creator.avatar = enrichUserAvatar(v.creator.avatar)

      return v
    })

    d.visas = d.visas.map((v) => {
      v.requester.avatar = enrichUserAvatar(v.requester.avatar)

      return v
    })

    d.observations = d.observations.map((o) => {
      o.user.avatar = enrichUserAvatar(o.user.avatar)

      o.content = o.content.map((c) => {
        c.files = c.files.map((f) => {
          f.url = new URL(`${apiBaseUrl}/file/${f.name}`).toString()

          return f
        })

        return c
      })

      return o
    })

    return d
  }

  private buildFullUrl(
    projectId: string,
    phaseId: string,
    folderId: string,
    params = ''
  ): string {
    return this.buildUrl(
      `${projectId}/${Endpoint.PHASE}/${phaseId}/${Endpoint.FOLDER}/${folderId}/${Endpoint.DOCUMENT}/${params}`
    )
  }
}
