
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

@Component({ name: 'number-input' })
export default class NumberInput extends Vue {
  @Prop({ required: true, type: Number })
  readonly value: number

  @Prop({ type: String, required: true })
  readonly label: string

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean

  @Prop({ type: Number, default: null })
  readonly min: number | null

  @Prop({ type: Number, default: null })
  readonly max: number | null

  readonly colors = colors

  data = ''

  created(): void {
    this.data = `${this.value}`
  }

  @Watch('value')
  onValueChange(val: number): void {
    this.data = `${val}`
  }

  @Watch('max')
  onMaxChange(val: number | null): void {
    if (val === null) {
      return
    }

    if (this.value > val) {
      this.$emit('update:value', val)
    }
  }

  decrement(): void {
    if (this.min === null || this.value > this.min) {
      this.$emit('update:value', this.value - 1)
    }
  }

  increment(): void {
    if (this.max === null || this.value < this.max) {
      this.$emit('update:value', this.value + 1)
    }
  }
}
