import { Address } from '@/services/bimoboxApiService/types'

export const isSameAddress = (
  a: Address | null,
  b: Address | null
): boolean => {
  if (typeof a === typeof '') {
    a = null
  }

  if (typeof b === typeof '') {
    b = null
  }

  const isSameAddressLabel = a?.address === b?.address
  const isSameCity =
    a?.city.name === b?.city.name && a?.city.postalCode === b?.city.postalCode
  const isSameLocation =
    a?.location?.lat === b?.location?.lat &&
    a?.location?.lon === b?.location?.lon

  return isSameAddressLabel && isSameCity && isSameLocation
}
