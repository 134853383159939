import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { RouteName } from '@/utils/constants'

import Activation from '@/views/auth/Activation.vue'
import Login from '@/views/auth/Login.vue'
import NewGuestActivation from '@/views/auth/NewGuestActivation.vue'
import Register from '@/views/auth/Register.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'
import SendActivation from '@/views/auth/SendActivation.vue'
import SendResetPassword from '@/views/auth/SendResetPassword.vue'
import Dashboard from '@/views/Dashboard.vue'
import Project from '@/views/Project.vue'
import Documents from '@/views/project/Documents.vue'
import Information from '@/views/project/Information.vue'
import Teams from '@/views/project/Teams.vue'
import Trd from '@/views/project/Trd.vue'
import Users from '@/views/project/Users.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/auth/login',
    name: RouteName.LOGIN,
    component: Login,
    meta: { guest: true, requiresAuth: false },
  },
  {
    path: '/auth/register',
    name: RouteName.REGISTER,
    component: Register,
    meta: { guest: true, requiresAuth: false },
  },
  {
    path: '/auth/activation',
    name: RouteName.SEND_ACTIVATION,
    component: SendActivation,
    meta: { guest: true, requiresAuth: false },
  },
  {
    path: '/auth/activation/:hash',
    name: RouteName.ACTIVATION,
    component: Activation,
    meta: { guest: true, requiresAuth: false },
  },
  {
    path: '/auth/password',
    name: RouteName.SEND_RESET_PASSWORD,
    component: SendResetPassword,
    meta: { guest: true, requiresAuth: false },
  },
  {
    path: '/auth/password/:hash',
    name: RouteName.RESET_PASSWORD,
    component: ResetPassword,
    meta: { guest: true, requiresAuth: false },
  },
  {
    path: '/auth/guest/:email/:hash',
    name: RouteName.NEW_GUEST_ACTIVATION,
    component: NewGuestActivation,
    meta: { guest: true, requiresAuth: false },
  },
  {
    path: '/dashboard',
    name: RouteName.DASHBOARD,
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/:id',
    component: Project,
    children: [
      {
        path: 'information',
        name: RouteName.INFORMATIONS,
        component: Information,
        meta: { requiresAuth: true },
      },
      {
        path: 'trd',
        name: RouteName.TRDVIEW,
        component: Trd,
        meta: { requiresAuth: true },
      },
      {
        path: 'documents',
        name: RouteName.DOCUMENTS,
        component: Documents,
        meta: { requiresAuth: true },
      },
      // {
      //   path: 'messenger',
      //   name: RouteName.MESSENGER,
      //   component: Messenger,
      //   meta: requiresAuth
      // },
      {
        path: 'users',
        name: RouteName.USERS,
        component: Users,
        meta: { requiresAuth: true },
      },
      {
        path: 'teams',
        name: RouteName.TEAMS,
        component: Teams,
        meta: { requiresAuth: true },
      },
      // {
      //   path: 'bcf',
      //   name: RouteName.BCF,
      //   component: Bcf,
      //   meta: requiresAuth
      // }
    ],
    meta: { requiresAuth: true },
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path === '/' || !to.name) {
    return next({ name: RouteName.DASHBOARD })
  }

  return next()
})

export default router
