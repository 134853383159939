
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { DBUser } from '@/services/bimoboxApiService/types'
import { NavBarProfileItem } from '@/types/global'

import { getUserModule } from '@/store/utils'
import { bimoboxVersion, colors, defaultAvatarUrl } from '@/utils/constants'

const Store = {
  ...getUserModule(),
}

export interface NavBarDrawerItem {
  icon: string
  title: string
  click: (param?: string) => void
}

@Component({ name: 'nav-bar-drawer' })
export default class NavBarDrawer extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly value: boolean

  @Prop({ type: Array as PropType<NavBarProfileItem[]>, default: [] })
  readonly profileItems: NavBarProfileItem[]

  @Store.UserModule.State
  readonly user: DBUser

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl
  readonly version = bimoboxVersion

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }
}
