
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IDocument, IFolder } from '@/services/bimoboxApiService/types'
import { AlertWithData, I18NText } from '@/types/global'

import {
  getAlertModule,
  getDocumentModule,
  getFolderModule,
} from '@/store/utils'

import ConfirmationModal from '@/components/common/modals/confirmation.modal.vue'

const components = {
  ConfirmationModal,
}

const Store = {
  ...getAlertModule(),
  ...getFolderModule(),
  ...getDocumentModule(),
}

@Component({ name: 'delete-folder-modal', components })
export default class DeleteFolderModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<IDocument>, required: true })
  readonly document: IDocument

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.DocumentModule.Action
  readonly deleteDocument: (data: {
    folderId: string
    documentId: string
  }) => Promise<void>

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder

  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.loading = false
    this.$emit('input', value)
  }

  get confirmationModalText(): I18NText {
    if (!this.show) {
      return { key: '' }
    }

    return {
      key: 'document_delete_confirmation',
      data: { name: this.document.name },
    }
  }

  async action(): Promise<void> {
    this.loading = true

    try {
      await this.deleteDocument({
        folderId: this.parentFolder.id,
        documentId: this.document.id,
      })
      this.ALERT_SUCCESS_WITH_DATA({
        message: `document_delete_success`,
        data: { name: this.document.name },
      })
    } catch (error) {
      this.ALERT_ERROR(error as string)
    }

    this.show = false
  }

  get parentFolder(): IFolder {
    return this.getParentByChildId(this.document.id)
  }
}
