import { BimoboxApiService } from '@/services/bimoboxApiService/api-bimobox.service'
import { Endpoint } from '@/services/bimoboxApiService/endpoint.enum'

export class BimoboxEndpointService {
  protected readonly apiService = new BimoboxApiService()

  constructor(private readonly endpoint: Endpoint) {}

  protected buildUrl(params = ''): string {
    return `${this.endpoint}/${params}`
  }
}
