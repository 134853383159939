
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  Address,
  City,
  FindAddressPayload,
  LatLon,
} from '@/services/bimoboxApiService/types'

import { colors } from '@/utils/constants'
import Debouncer from '@/utils/debouncer'
import { isSameAddress } from '@/utils/isSameAddress'

import { AddressService } from '@/services/bimoboxApiService'

import MapDisplayer from '@/components/project/information/MapDisplayer.vue'

const components = {
  MapDisplayer,
}

interface ICityItem {
  text: string
  value: City
}

@Component({ name: 'set-address', components })
export default class SetAddressCard extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isParentLoading: LatLon

  @Prop({ type: Object as PropType<Address>, default: null })
  readonly currentAddress: Address | null

  error = ''

  readonly fetchAddressDebouncer = new Debouncer(this.fetchFullAddress)
  readonly fetchCityDebouncer = new Debouncer(this.fetchCity)

  readonly colors = colors
  readonly addressService = new AddressService()

  rules = {
    required: (value: string): string | boolean =>
      value !== undefined || (this.$t('rule_required_error') as string),
  }

  address = ''
  city: ICityItem | null = null

  @Watch('currentAddress', { deep: true })
  onCurrentAddressChange(value: Address | null): void {
    this.setAddressData(value)
  }

  mounted(): void {
    this.setAddressData(this.currentAddress)
  }

  setAddressData(value: Address | null): void {
    if (value !== null) {
      this.address = value.address
      this.fetchedAddress = { ...value }
      this.city = {
        text: `${value.city.name} (${value.city.postalCode})`,
        value: value.city,
      }
      this.search = this.city.text
    } else {
      this.city = null
      this.address = ''
      this.fetchedAddress = null
    }
  }

  search = ''
  isSearchLoading = false
  items: ICityItem[] = []

  get isComponentLoading(): boolean {
    return this.isSearchLoading || this.isFetchAddressLoading
  }

  @Watch('search')
  async onSearchChanged(value: string): Promise<void> {
    if (!value?.length) {
      this.items = []
      return
    }

    this.search = value.replaceAll(' ', '-').replaceAll('-(', ' (')
    this.fetchCityDebouncer.debounce(value)
  }

  get isFromComplete(): boolean {
    return this.city !== null && this.address.length > 0
  }

  async fetchCity(name: string): Promise<void> {
    this.isSearchLoading = true

    try {
      const cities = await this.addressService.findCity({ name })
      this.items = cities.map((city) => ({
        text: city.name + ' (' + city.postalCode + ')',
        value: city,
      }))
    } catch (e) {
      console.error(e)
    }

    this.isSearchLoading = false
  }

  @Watch('city')
  onCityChange(val: ICityItem | null, oldVal: ICityItem | null): void {
    if (val?.text !== oldVal?.text) {
      this.fetchAddressDebouncer.debounce()
    }
  }

  @Watch('address')
  onAddressChange(val: string, oldVal: string): void {
    if (val !== oldVal && val !== '') {
      this.fetchAddressDebouncer.debounce()
    }
  }

  @Watch('isComponentLoading')
  onComponentLoadingChange(value: boolean): void {
    this.$emit('loading', value)
  }

  @Watch('fetchedAddress', { immediate: true, deep: true })
  onFetchedAddressChange(val: Address | null, oldVal: Address | null): void {
    if (!isSameAddress(oldVal, val)) {
      this.$emit('update:currentAddress', val)
    }
  }

  @Watch('error')
  onErrorChanged(value: string): void {
    if (value.length > 0) {
      this.address = ''
      this.city = null
      this.search = ''
      this.items = []
      this.isSearchLoading = false
    }
  }

  fetchedAddress: Address | null = null

  get location(): LatLon | null {
    if (this.fetchedAddress?.location !== undefined) {
      return this.fetchedAddress?.location
    }

    return null
  }

  isFetchAddressLoading = false
  async fetchFullAddress(): Promise<void> {
    if (!this.isFromComplete || !this.city) {
      return
    }

    this.isFetchAddressLoading = true
    try {
      const payload: FindAddressPayload = {
        address: this.address,
        city: this.city.value,
      }

      const res = await this.addressService.findAddress(payload)
      if (!isSameAddress(this.fetchedAddress, res)) {
        this.fetchedAddress = res
      }
    } catch (e) {
      this.error = 'address_invalid_error'
    }

    this.isFetchAddressLoading = false
  }
}
