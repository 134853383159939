import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import { Endpoint } from '@/services/bimoboxApiService/endpoint.enum'
import {
  IBasicPhase,
  ICreatePhasePayload,
  IGetVisaReportQuery,
  ILockPhasePayload,
  IPhaseContent,
  IUpdatePhasePayload,
  NamedBlob,
} from '@/services/bimoboxApiService/types'
import { apiBaseUrl } from '@/utils/constants'
import { enrichUserAvatar } from '@/utils/imageUrlGetter'
import { AxiosRequestConfig } from 'axios'
import * as mime from 'mime-types'

export class PhaseService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createPhase(
    projectId: string,
    payload: ICreatePhasePayload
  ): Promise<IBasicPhase> {
    const url = this.buildFullUrl(projectId)
    try {
      return this.apiService.post<IBasicPhase>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getProjectPhases(projectId: string): Promise<IBasicPhase[]> {
    const url = this.buildFullUrl(projectId)
    try {
      return this.apiService.get<IBasicPhase[]>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getPhaseContent(
    projectId: string,
    phaseId: string
  ): Promise<IPhaseContent> {
    const url = this.buildFullUrl(projectId, phaseId)
    try {
      return this.apiService
        .get<IPhaseContent>(url)
        .then((c) => this.enrichPhaseContent(c))
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updatePhaseById(
    projectId: string,
    phaseId: string,
    payload: IUpdatePhasePayload
  ): Promise<IBasicPhase> {
    const url = this.buildFullUrl(projectId, phaseId)
    try {
      return this.apiService.put<IBasicPhase>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async lockPhaseById(
    projectId: string,
    phaseId: string,
    payload: ILockPhasePayload
  ): Promise<IBasicPhase> {
    const url = this.buildFullUrl(projectId, `${phaseId}/lock`)
    try {
      return await this.apiService.put<IBasicPhase>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async deletePhaseById(projectId: string, phaseId: string): Promise<void> {
    const url = this.buildFullUrl(projectId, phaseId)
    try {
      return await this.apiService.delete<void>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getVisaReport(
    projectId: string,
    phaseId: string,
    fileName: string,
    query: IGetVisaReportQuery
  ): Promise<NamedBlob> {
    const q = [
      `includeTRD=${query.includeTRD}`,
      `includeVisaLess=${query.includeVisaLess}`,
    ]
    const url = this.buildFullUrl(projectId, `${phaseId}/report?${q.join('&')}`)
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/octet-stream',
      },
    }

    const type = mime.lookup(fileName)

    try {
      const data = await this.apiService.get<ArrayBuffer>(url, config)
      const blob = new Blob([data], {
        type: type || 'application/octet-stream',
      })
      return new NamedBlob(fileName, blob)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private buildFullUrl(projectId: string, params = ''): string {
    return this.buildUrl(`${projectId}/${Endpoint.PHASE}/${params}`)
  }

  private enrichPhaseContent(phaseContent: IPhaseContent): IPhaseContent {
    phaseContent.versions = phaseContent.versions.map((v) => {
      v.fileUrl = new URL(`${apiBaseUrl}/file/${v.fileUrl}`).toString()
      v.creator.avatar = enrichUserAvatar(v.creator.avatar)

      return v
    })

    phaseContent.visas = phaseContent.visas.map((v) => {
      v.requester.avatar = v.requester.avatar
        ? new URL(`${apiBaseUrl}/file/${v.requester.avatar}`).toString()
        : undefined

      return v
    })

    phaseContent.observations = phaseContent.observations.map((o) => {
      o.user.avatar = o.user.avatar
        ? new URL(`${apiBaseUrl}/file/${o.user.avatar}`).toString()
        : undefined

      o.content = o.content.map((c) => {
        c.files = c.files.map((f) => {
          f.url = new URL(`${apiBaseUrl}/file/${f.name}`).toString()

          return f
        })

        return c
      })

      return o
    })

    phaseContent.advices = phaseContent.advices.map((a) => {
      a.requester.avatar = a.requester.avatar
        ? new URL(`${apiBaseUrl}/file/${a.requester.avatar}`).toString()
        : undefined

      return a
    })

    return phaseContent
  }
}
