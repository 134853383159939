
import { Component, Prop, Vue } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

import Action from '@/components/common/actions/action.vue'

@Component({ name: 'form-actions', components: { Action } })
export default class FormActions extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly loading: boolean

  @Prop({ type: Boolean, default: false })
  readonly deletion: boolean

  @Prop({ type: Boolean, default: false })
  readonly top: boolean

  @Prop({ type: Boolean, default: true })
  readonly marginBottom: boolean

  @Prop({ required: true, type: String })
  readonly primaryText: string

  @Prop({ type: String, default: 'primaryButton' })
  readonly primaryId: string

  @Prop({ required: true, type: String })
  readonly secondaryText: string

  @Prop({ type: String, default: 'secondaryButton' })
  readonly secondaryId: string

  @Prop({ type: String, default: undefined })
  readonly progress: string

  @Prop({ type: Boolean, default: true })
  readonly isOk: boolean

  readonly colors = colors

  primaryAction(): void {
    this.$emit('primaryAction')
  }

  secondaryAction(): void {
    this.$emit('secondaryAction')
  }

  get isLittleMobile(): boolean {
    return this.$vuetify.breakpoint.xs
  }
}
