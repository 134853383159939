
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { colors, defaultAvatarUrl } from '@/utils/constants'
import {
  getPhaseModule,
  getProjectModule,
  getTeamModule,
  getUserModule,
} from '@/store/utils'
import {
  CreateDocumentEmailTo,
  DBUser,
  IBasicPhase,
  Project,
  PublicUser,
  Team,
} from '@/services/bimoboxApiService/types'

const Store = {
  ...getTeamModule(),
  ...getPhaseModule(),
  ...getProjectModule(),
  ...getUserModule(),
}

@Component({ name: 'create-document-email' })
export default class CreateDocumentEmail extends Vue {
  @Prop({ type: String as PropType<CreateDocumentEmailTo>, required: true })
  readonly emailTo: CreateDocumentEmailTo

  @Prop({ type: Array as PropType<string[]>, required: true })
  readonly emailTargets: string[]

  @Prop({ type: String, required: true })
  readonly emailMessage: string

  @Prop({ type: Boolean, required: true })
  readonly disabled: string

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase

  @Store.ProjectModule.State
  readonly project: Project

  @Store.UserModule.State
  readonly user: DBUser

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl

  to: CreateDocumentEmailTo = 'nobody'
  targets: string[] = []
  message = ''

  get toTeams(): boolean {
    return this.emailTo === 'team'
  }

  set toTeams(value: boolean) {
    if (value === true) {
      this.to = 'team'
    }
  }

  get toNobody(): boolean {
    return this.emailTo === 'nobody'
  }

  set toNobody(value: boolean) {
    if (value === true) {
      this.to = 'nobody'
    }
  }

  get toUsers(): boolean {
    return this.emailTo === 'user'
  }

  set toUsers(value: boolean) {
    if (value === true) {
      this.to = 'user'
    }
  }

  mounted(): void {
    this.to = this.emailTo
    this.targets = this.emailTargets
    this.message = this.emailMessage
  }

  get currentPhaseTeams(): Team[] {
    return this.teams.filter((t) =>
      this.currentPhase.guestsIds.includes(t.id) &&
      t.membersIds.includes(this.user.id)
        ? t.membersIds.length > 1
        : t.membersIds.length > 0
    )
  }

  get currentPhaseUsers(): PublicUser[] {
    const ids = this.teams.flatMap(({ membersIds }) => membersIds)
    const users = this.project.guests
      .filter((g) => !g.isKicked && ids.includes(g.user.id))
      .map(({ user }) => user)
    users.push(this.project.creator)

    return users.filter(({ id }) => id !== this.user.id)
  }

  rules = {
    arrayRequired: (value: string[]): string | boolean => {
      console.error(value)
      return (
        (value !== undefined && value.length > 0) ||
        (this.$t('rule_required_error') as string)
      )
    },
  }

  removeTarget(id: string): void {
    this.targets = this.targets.filter((t) => t !== id)
  }

  getTeamNameByUser(id: string): string {
    if (id === this.project.creator.id) {
      return this.$t('guest_owner') as string
    }

    return (
      this.currentPhaseTeams.find(({ membersIds }) => membersIds.includes(id))
        ?.name ?? ''
    )
  }

  @Watch('emailTo')
  onEmailToChange(val: CreateDocumentEmailTo): void {
    if (val !== this.to) {
      this.to = val
    }
  }

  @Watch('emailTargets')
  onEmailTargetsChange(val: string[]): void {
    if (val.length !== this.targets.length) {
      this.targets = val
    }
  }

  @Watch('emailMessage')
  onEmailMessageChange(val: string): void {
    if (val !== this.message) {
      this.message = val
    }
  }

  @Watch('to')
  onToChange(val: CreateDocumentEmailTo): void {
    if (val !== this.emailTo) {
      this.$emit('update:emailTo', val)
      this.targets = []
    }
  }

  @Watch('targets')
  onTargetsChange(val: string[]): void {
    if (val.length !== this.emailTargets.length) {
      this.$emit('update:emailTargets', val)
    }
  }

  @Watch('message')
  onMessageChange(val: string): void {
    if (val !== this.emailMessage) {
      this.$emit('update:emailMessage', val)
    }
  }
}
