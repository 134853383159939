
import { Component, Vue, Watch } from 'vue-property-decorator'

import {
  Project,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'
import { NavBarTab } from '@/types/global'

import { getProjectModule, getTeamModule } from '@/store/utils'
import { colors, RouteName } from '@/utils/constants'

const Store = {
  ...getProjectModule(),
  ...getTeamModule(),
}

@Component({ name: 'nav-bar-tabs' })
export default class NavBarTabs extends Vue {
  @Store.ProjectModule.State
  readonly project: Project

  @Store.ProjectModule.Getter
  readonly isCreatorOrAdmin: boolean

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  readonly colors = colors

  get tabs(): NavBarTab[] {
    const items = [
      {
        title: 'route_information',
        icon: 'mdi-information',
        dataCy: 'menuInformationTitle',
        link: { name: RouteName.INFORMATIONS },
        hasRight: true,
      },
      {
        title: 'route_trd',
        icon: 'mdi-video-3d',
        dataCy: 'menuTrdViewTitle',
        link: { name: RouteName.TRDVIEW },
        hasRight: this.hasRight(TeamRightGroup.TRD, TeamRight.ACCESS),
      },
      {
        title: 'route_documents',
        icon: 'mdi-file-document-multiple',
        dataCy: 'menuDocumentsTitle',
        link: { name: RouteName.DOCUMENTS },
        hasRight: this.hasRight(TeamRightGroup.DOCUMENT, TeamRight.ACCESS),
      },
      // {
      //   title: "route_bcf",
      //   icon: "mdi-note",
      //   dataCy: "",
      //   link: { name: RouteName.BCF },
      // },
      {
        title: 'route_users',
        icon: 'mdi-account-supervisor',
        dataCy: 'menuUsersTitle',
        link: { name: RouteName.USERS },
        hasRight: true,
      },
      {
        title: 'route_teams',
        icon: 'mdi-account-group',
        link: { name: RouteName.TEAMS },
        dataCy: 'menuTeamsTitle',
        hasRight: this.isMobile && this.isCreatorOrAdmin,
      },
      // {
      //   title: "route_messages",
      //   icon: "mdi-forum",
      //   dataCy: "menuMessagesTitle",
      //   link: { name: RouteName.MESSENGER },
      // },
    ]

    return items
      .filter(({ hasRight }) => hasRight === true)
      .map(({ title, icon, link, dataCy }) => ({ title, icon, link, dataCy }))
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  currentTab = 0

  created(): void {
    this.currentTab = this.currentTabValue
  }

  get currentTabValue(): number {
    const routeName = this.$router.currentRoute.name as RouteName

    return this.tabs.findIndex((t) => {
      if (
        !this.isMobile &&
        routeName === RouteName.TEAMS &&
        t.link.name === RouteName.USERS
      ) {
        return true
      }

      return routeName === t.link.name
    })
  }

  @Watch('currentTabValue')
  onCurrentTablValueChange(val: number): void {
    this.currentTab = val
  }
}
