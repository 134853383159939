
import { isEmail } from 'validator'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  ICreateGuestPayload,
  Project,
  Team,
} from '@/services/bimoboxApiService/types'

import {
  getAlertModule,
  getGuestModule,
  getPhaseModule,
  getProjectModule,
  getTeamModule,
  getUserModule,
} from '@/store/utils'
import { AlertWithData, VForm } from '@/types/global'
import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import Action from '@/components/common/actions/action.vue'

const components = {
  BaseModal,
  FormActions,
  Action,
}

const Store = {
  ...getTeamModule(),
  ...getPhaseModule(),
  ...getAlertModule(),
  ...getUserModule(),
  ...getProjectModule(),
  ...getGuestModule(),
}

@Component({ name: 'create-guests-modal', components })
export default class CreateGuestsModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  readonly colors = colors
  error = ''
  loading = false
  email = ''
  selectedTeamId = ''
  isGuestAdmin = false
  guestsToCreate: ICreateGuestPayload[] = []

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.ProjectModule.State
  readonly project: Project

  @Store.ProjectModule.Getter
  readonly isCreator: boolean

  @Store.GuestModule.Action
  readonly createGuests: (guests: ICreateGuestPayload[]) => Promise<void>

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  readonly rules = {
    required: (value: string): true | string =>
      !!value || (this.$t('rule_required_error') as string),
    email: (value: string): true | string =>
      isEmail(value) || (this.$t('rule_invalid_email_error') as string),
    doNotExist: (value: string): true | string =>
      !this.isInProject(value) ||
      (this.$t('rule_guest_in_project_error') as string),
  }

  isInProject(val: string): boolean {
    if (this.project.creator.email === val) {
      return true
    }

    return (
      this.project.guests
        .filter(({ isKicked }) => !isKicked)
        .map(({ user }) => user.email)
        .includes(val) ||
      this.guestsToCreate.map(({ email }) => email).includes(val)
    )
  }

  get isUserFormValid(): boolean {
    return (
      this.email.length > 0 &&
      isEmail(this.email) &&
      !this.isInProject(this.email)
    )
  }

  get isFormValid(): boolean {
    return this.guestsToCreate.length > 0
  }

  addUser(): void {
    this.guestsToCreate.push({
      isAdmin: this.isGuestAdmin,
      team: this.selectedTeamId.length > 0 ? this.selectedTeamId : undefined,
      email: this.email,
    })
    this.resetUserData()
  }

  removeGuest(guestToRemoveEmail: string): void {
    this.guestsToCreate = this.guestsToCreate.filter(
      ({ email }) => email !== guestToRemoveEmail
    )
  }

  form(): VForm {
    return this.$refs.form as VForm
  }

  resetUserData(): void {
    this.email = ''
    this.selectedTeamId = ''
    this.isGuestAdmin = false
    this.form().resetValidation()
  }

  resetData(): void {
    this.resetUserData()
    this.guestsToCreate = []
    this.loading = false
  }

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  async primaryAction(): Promise<void> {
    this.loading = true
    try {
      await this.createGuests(this.guestsToCreate)
      const alertData: AlertWithData = {
        message: 'guest_create_success',
        data: { count: this.guestsToCreate.length },
        pluralize: true,
      }

      this.ALERT_SUCCESS_WITH_DATA(alertData)
      this.show = false
    } catch (error) {
      console.error(error)
      this.error = 'unknown_error'
      this.resetData()
    }
  }
}
