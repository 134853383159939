
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  CreateDocumentEmailTo,
  DBFile,
  DBUser,
  FolderType,
  IBasicPhase,
  ICreateDocumentVersionPayload,
  IDocument,
  IDocumentEmailDTO,
  IFolder,
  Project,
  Team,
  UploadFilePayload,
} from '@/services/bimoboxApiService/types'
import { AlertWithData, VPond } from '@/types/global'

import {
  getAlertModule,
  getDocumentModule,
  getDocumentVersionModule,
  getFolderModule,
  getGuestModule,
  getPhaseModule,
  getProjectModule,
  getTeamModule,
  getUserModule,
} from '@/store/utils'
import { getFileExtension } from '@/utils/fileExtensionChecker'

import { FileService } from '@/services/bimoboxApiService'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import FilePondHandler from '@/components/common/FilePondHandler.vue'
import CreateDocumentEmail from './create.document.email.vue'

const components = {
  BaseModal,
  FormActions,
  FilePondHandler,
  CreateDocumentEmail,
}

const Store = {
  ...getDocumentVersionModule(),
  ...getAlertModule(),
  ...getFolderModule(),
  ...getDocumentModule(),
  ...getTeamModule(),
  ...getGuestModule(),
  ...getPhaseModule(),
  ...getUserModule(),
  ...getProjectModule(),
}

@Component({ name: 'create-document-version-modal', components })
export default class CreateVersionModal extends Vue {
  @Prop({ type: String, required: true })
  readonly documentId: string

  @Prop({ type: String, required: true })
  readonly acceptedType: string

  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  readonly fileService = new FileService()

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder

  @Store.DocumentVersionModule.Action
  readonly createVersion: (data: {
    documentId: string
    payload: ICreateDocumentVersionPayload
  }) => Promise<void>

  @Store.ProjectModule.State
  readonly project: Project

  @Store.DocumentModule.Getter
  readonly getDocumentById: (documentId: string) => IDocument

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.DocumentModule.Action
  readonly getDocumentEmailOpts: (data: {
    folderId: string
    documentId: string
  }) => Promise<IDocumentEmailDTO>

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase

  @Store.UserModule.State
  readonly user: DBUser

  get show(): boolean {
    if (this.value === true) {
      this.loadEmailOpts()
    }

    return this.value
  }

  set show(value: boolean) {
    this.onShowChanged(value)
  }

  get document(): IDocument {
    return this.getDocumentById(this.documentId)
  }

  get folder(): IFolder {
    return this.getParentByChildId(this.documentId)
  }

  get currentPhaseTeams(): Team[] {
    return this.teams.filter((t) =>
      this.currentPhase.guestsIds.includes(t.id) &&
      t.membersIds.includes(this.user.id)
        ? t.membersIds.length > 1
        : t.membersIds.length > 0
    )
  }

  pond(): VPond {
    return this.$refs.pond as VPond
  }

  error = ''
  loading = false
  newVersionId = ''
  isFilepondLoading = false
  preload = false
  emailTo: CreateDocumentEmailTo = 'nobody'
  emailTargets: string[] = []
  emailMessage = ''

  get isFormValid(): boolean {
    if (this.loading || this.isFilepondLoading) {
      return false
    }

    return this.newVersionId !== ''
  }

  async loadEmailOpts(): Promise<void> {
    this.preload = true
    try {
      const opts = await this.getDocumentEmailOpts({
        folderId: this.folder.id,
        documentId: this.documentId,
      })

      this.emailTo = opts.type
      switch (opts.type) {
        case 'team':
          this.emailTargets = opts.targets.filter((t) =>
            this.currentPhaseTeams.map(({ id }) => id).includes(t)
          )
          break
        case 'user':
          const phaseUsers = [
            ...new Set(
              ...this.currentPhaseTeams.map((t) => t.membersIds),
              this.project.creator.id,
              this.project.guests.filter((g) => g.isAdmin).map((g) => g.user.id)
            ),
          ].filter((id) => id !== this.user.id)

          this.emailTargets = opts.targets.filter((t) => phaseUsers.includes(t))
          break
        case 'nobody':
          this.emailTargets = []
          break
      }

      this.emailTo = opts.type
      this.emailMessage = opts.message
    } catch (err) {
      console.error('cannot fetch document opts', err)
    }

    this.preload = false
  }

  async onShowChanged(value: boolean): Promise<void> {
    this.error = ''
    if (value === false) {
      this.pond().removeFiles()
      await this.deleteFile()
    }

    this.resetData()
    this.$emit('input', value)
  }

  async deleteFile(): Promise<void> {
    this.loading = true
    if (this.newVersionId.length > 0) {
      await this.fileService.deleteFile(this.newVersionId)
    }

    this.loading = false
  }

  resetData(): void {
    this.newVersionId = ''
    this.loading = false
    this.preload = false
  }

  async uploadFile(
    file: File,
    onUploadProgress: (progressEvent) => void
  ): Promise<string> {
    const payload: UploadFilePayload = { file, onUploadProgress }

    if (getFileExtension(file.name) !== this.acceptedType) {
      throw 'unvalid file type'
    }

    const res: DBFile =
      this.folder.type === FolderType.Trd
        ? await this.fileService.uploadTrdFile(payload)
        : await this.fileService.uploadFile(payload)
    this.newVersionId = res.id
    return res.id
  }

  filepondToggled(state: boolean): void {
    this.isFilepondLoading = state
  }

  fileDeleted(deletedId: string): void {
    if (deletedId === this.newVersionId) {
      this.newVersionId = ''
    }
  }

  async primaryAction(): Promise<void> {
    this.loading = true

    try {
      await this.createVersion({
        documentId: this.documentId,
        payload: {
          file: this.newVersionId,
          emailOpts: {
            targets: this.emailTargets,
            message: this.emailMessage,
            type: this.emailTo,
          },
        },
      })

      this.ALERT_SUCCESS_WITH_DATA({
        message: 'document_version_create_success',
        data: { documentName: this.document.name },
      })

      this.newVersionId = ''
      this.show = false
    } catch (error) {
      try {
        await this.deleteFile()
      } catch (error) {
        console.error(error)
      }

      this.pond().removeFiles()
      this.error = error as string
      this.resetData()
    }
  }
}
