
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { AuthFormAction, AuthFormAlert } from '@/types/global'

import {
  getDashboardModule,
  getProjectModule,
  getUserModule,
} from '@/store/utils'
import { colors } from '@/utils/constants'

import Locale from '@/components/Locale.vue'

const Store = {
  ...getUserModule(),
  ...getDashboardModule(),
  ...getProjectModule(),
}

@Component({ name: 'auth-form', components: { Locale } })
export default class AuthForm extends Vue {
  @Prop({
    required: false,
    type: Object as PropType<AuthFormAlert>,
    default: null,
  })
  readonly alert: AuthFormAlert | null

  @Prop({ required: false, type: Boolean, default: false })
  readonly hideActionButton: boolean

  @Prop({
    required: false,
    type: Object as PropType<AuthFormAction>,
    default: null,
  })
  readonly rightAction: AuthFormAction

  @Prop({
    required: false,
    type: Object as PropType<AuthFormAction>,
    default: null,
  })
  readonly leftAction: AuthFormAction

  @Prop({ required: true, type: Boolean })
  readonly loading: boolean

  @Prop({ required: true, type: Boolean })
  readonly isFormComplete: boolean

  readonly colors = colors

  @Store.ProjectModule.Action
  readonly resetProjectStates: (all: boolean) => void

  @Store.UserModule.Mutation
  readonly SET_USER: () => void

  @Store.DashboardModule.Mutation
  readonly RESET_DASHBOARD_STATES: () => void

  created(): void {
    this.resetProjectStates(true)
    this.SET_USER()
    this.RESET_DASHBOARD_STATES()
  }

  get width(): number {
    return this.$vuetify.breakpoint.xs ? 320 : 400
  }

  get imageHeight(): number {
    return this.$vuetify.breakpoint.xs ? 60 : 75
  }

  leftActionRoute(): void {
    if (!this.loading) {
      this.$router.push({ name: this.leftAction.route })
    }
  }

  rightActionRoute(): void {
    if (!this.loading) {
      this.$router.push({ name: this.rightAction.route })
    }
  }

  primaryAction(): void {
    this.$emit('primaryAction')
  }
}
