
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { PublicFile } from '@/services/bimoboxApiService/types'

import { colors } from '@/utils/constants'

import VuePdfApp from 'vue-pdf-app'

import { FileService } from '@/services/bimoboxApiService'
import { getAlertModule } from '@/store/utils'

const Store = getAlertModule()

@Component({ name: 'pdf-viewer-modal', components: { VuePdfApp } })
export default class PdfViewerModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ required: true, type: Object as PropType<PublicFile> })
  readonly file: PublicFile

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (mesasge: string) => void

  readonly fileService = new FileService()
  readonly colors = colors
  readonly pdfConfig = {
    toolbar: {
      toolbarViewerLeft: {},
      toolbarViewerRight: {
        openFile: false,
        viewBookmark: false,
      },
      toolbarViewerMiddle: {
        scaleSelectContainer: false,
      },
    },
    secondaryToolbar: {
      spreadNone: false,
      spreadOdd: false,
      spreadEven: false,
      documentProperties: false,
    },
  }

  arrayBuffer: ArrayBuffer | null = null
  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  @Watch('show')
  onShowChange(val: boolean): void {
    if (val) {
      this.downloadFile()
    } else {
      this.arrayBuffer = null
    }
  }

  async downloadFile(): Promise<void> {
    this.loading = true

    try {
      this.arrayBuffer = await this.fileService.getFile(this.file.id)
    } catch (err) {
      this.ALERT_ERROR(err as string)

      this.loading = false
      this.show = false
    }
  }

  openHandler(pdfApp): void {
    // eslint-disable-next-line
    ;(window as any)._pdfApp = pdfApp
  }
}
