
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  IUpdateGuestPayload,
  Project,
  ProjectGuest,
  Team,
} from '@/services/bimoboxApiService/types'
import { AlertWithData } from '@/types/global'

import {
  getAlertModule,
  getGuestModule,
  getProjectModule,
  getTeamModule,
} from '@/store/utils'
import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'

const components = {
  BaseModal,
  FormActions,
}

const Store = {
  ...getTeamModule(),
  ...getAlertModule(),
  ...getProjectModule(),
  ...getGuestModule(),
}

@Component({ name: 'update-guest-modal', components })
export default class UpdateGuestModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<ProjectGuest>, required: true })
  readonly guest: ProjectGuest

  @Store.ProjectModule.Getter
  readonly isCreator: boolean

  @Store.ProjectModule.State
  readonly project: Project

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.TeamModule.Getter
  readonly guestTeam: (id: string) => Team | null

  @Store.GuestModule.Action
  readonly updateGuest: (payload: IUpdateGuestPayload) => Promise<void>

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  readonly colors = colors
  error = ''
  loading = false
  isGuestAdmin = false
  email = ''
  selectedTeamId: string | null = null

  mounted(): void {
    this.resetData()
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (value === true) {
      this.resetData()
      this.error = ''
    }

    this.$emit('input', value)
  }

  resetData(): void {
    this.loading = false
    this.email = this.guest.user.email
    this.isGuestAdmin = this.guest.isAdmin
    const guestTeam = this.guestTeam(this.guest.user.id)
    this.selectedTeamId = guestTeam ? guestTeam.id : null
  }

  get isFormValid(): boolean {
    const guestTeam = this.guestTeam(this.guest.user.id)
    const isSameTeam =
      guestTeam === null
        ? this.selectedTeamId === null
        : guestTeam.id === this.selectedTeamId

    return this.isGuestAdmin !== this.guest.isAdmin || !isSameTeam
  }

  async primaryAction(): Promise<void> {
    this.loading = true
    try {
      const payload: IUpdateGuestPayload = {
        id: this.guest.user.id,
        isAdmin: this.isGuestAdmin,
        team: this.selectedTeamId ? this.selectedTeamId : undefined,
      }

      await this.updateGuest(payload)
      const alertData: AlertWithData = {
        message: 'guest_update_success',
        data: { name: this.guest.user.displayName },
      }

      this.ALERT_SUCCESS_WITH_DATA(alertData)
      this.show = false
    } catch (error) {
      this.error = error as string
      this.resetData()
    }
  }
}
