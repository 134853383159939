import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  Endpoint,
  IActiveVisa,
  ICancelVisaPayload,
  ICreateVisaPayload,
  IVisaContent,
} from '@/services/bimoboxApiService/types'

export class VisaService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createVisa(
    projectId: string,
    phaseId: string,
    payload: ICreateVisaPayload
  ): Promise<IVisaContent> {
    const { folderId, documentId, versionId, data } = payload
    const url = this.buildFullUrl(
      projectId,
      phaseId,
      folderId,
      documentId,
      versionId
    )
    try {
      return await this.apiService.post<IVisaContent>(url, data)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async cancelVisa(
    projectId: string,
    phaseId: string,
    payload: ICancelVisaPayload
  ): Promise<void> {
    const { folderId, documentId, versionId, data, visaId } = payload
    const url = this.buildFullUrl(
      projectId,
      phaseId,
      folderId,
      documentId,
      versionId,
      visaId
    )
    try {
      await this.apiService.post<void>(url, data)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getActiveVisas(projectId: string): Promise<IActiveVisa[]> {
    const url = `${Endpoint.PROJECT}/${projectId}/visa/active`
    try {
      return await this.apiService.get<IActiveVisa[]>(url)
    } catch (err) {
      console.error(err)

      throw err
    }
  }

  private buildFullUrl(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string,
    versionId: string,
    params = ''
  ): string {
    return this.buildUrl(
      `${projectId}/${Endpoint.PHASE}/${phaseId}/${Endpoint.FOLDER}/${folderId}/${Endpoint.DOCUMENT}/${documentId}/${Endpoint.VERSION}/${versionId}/${Endpoint.VISA}/${params}`
    )
  }
}
