
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IBasicPhase, PhaseGroup } from '@/services/bimoboxApiService/types'
import { AlertWithData } from '@/types/global'

import { getAlertModule, getPhaseModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import PhaseGroupDisplayer from '@/components/project/information/phaseGroup.displayer.vue'

const components = {
  BaseModal,
  FormActions,
  PhaseGroupDisplayer,
}

const Store = {
  ...getPhaseModule(),
  ...getAlertModule(),
}

@Component({ name: 'delete-phases-modal', components })
export default class DeletePhasesModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Store.PhaseModule.State
  readonly phases: IBasicPhase[]

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.PhaseModule.Action
  readonly deletePhases: (ids: string[]) => Promise<void>

  readonly colors = colors

  error = ''
  loading = false

  created(): void {
    this.resetData()
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  selectedConceptionPhases: string[] = []
  selectedConstructionPhases: string[] = []
  selectedExploitationPhases: string[] = []

  resetData(): void {
    this.selectedConceptionPhases = []
    this.selectedConstructionPhases = []
    this.selectedExploitationPhases = []
    this.loading = false
  }

  get conceptionPhases(): IBasicPhase[] {
    return this.phases.filter(({ group }) => group === PhaseGroup.CONCEPTION)
  }

  get constructionPhases(): IBasicPhase[] {
    return this.phases.filter(({ group }) => group === PhaseGroup.CONSTRUCTION)
  }

  get exploitationPhases(): IBasicPhase[] {
    return this.phases.filter(({ group }) => group === PhaseGroup.EXPLOITATION)
  }

  selectConceptionPhase(id: string): void {
    if (this.selectedConceptionPhases.includes(id)) {
      this.selectedConceptionPhases = this.selectedConceptionPhases.filter(
        (p) => p !== id
      )
    } else {
      this.selectedConceptionPhases.push(id)
    }
  }

  selectConstructionPhase(id: string): void {
    if (this.selectedConstructionPhases.includes(id)) {
      this.selectedConstructionPhases = this.selectedConstructionPhases.filter(
        (p) => p !== id
      )
    } else {
      this.selectedConstructionPhases.push(id)
    }
  }

  selectExploitationPhase(id: string): void {
    if (this.selectedExploitationPhases.includes(id)) {
      this.selectedExploitationPhases = this.selectedExploitationPhases.filter(
        (p) => p !== id
      )
    } else {
      this.selectedExploitationPhases.push(id)
    }
  }

  get isFormValid(): boolean {
    return (
      this.selectedConceptionPhases.length > 0 ||
      this.selectedConstructionPhases.length > 0 ||
      this.selectedExploitationPhases.length > 0
    )
  }

  async primaryAction(): Promise<void> {
    this.error = ''
    this.loading = true

    try {
      const idsToDelete = [
        ...this.selectedConstructionPhases,
        ...this.selectedExploitationPhases,
        ...this.selectedConceptionPhases,
      ]
      await this.deletePhases(idsToDelete)
      this.ALERT_SUCCESS_WITH_DATA({
        message: 'phases_delete_success',
        data: { count: idsToDelete.length },
        pluralize: true,
      })
      this.show = false
    } catch {
      this.error = 'unknown_error'
      this.resetData()
    }
  }
}
