
import { isEmail } from 'validator'
import { Component, Vue } from 'vue-property-decorator'

import { DBUser, LoginPayload } from '@/services/bimoboxApiService/types'
import { AuthFormAction, AuthFormAlert, VForm } from '@/types/global'

import { colors, RouteName } from '@/utils/constants'

import { AuthService } from '@/services/bimoboxApiService'

import AuthForm from '@/components/AuthForm.vue'
import Recaptcha from '@/components/Recaptcha.vue'
import { getPageLoaderModule, getUserModule } from '@/store/utils'

const components = { AuthForm, Recaptcha }
const Store = { ...getUserModule(), ...getPageLoaderModule() }

@Component({ name: 'login', components })
export default class Login extends Vue {
  readonly colors = colors
  readonly authService = new AuthService()

  @Store.UserModule.Action
  readonly fetchCurrentUser: () => Promise<void>

  @Store.PageLoaderModule.Mutation
  readonly TOGGLE_PAGE_LOADING: (val: boolean) => void

  @Store.UserModule.Mutation
  readonly SET_USER: (user: DBUser | null) => void

  loading = false
  authFormAlert: AuthFormAlert | null = null

  showPassword = false

  data = {
    email: '',
    password: '',
  }

  captcha = {
    reset: false,
    token: '',
  }

  async mounted(): Promise<void> {
    this.TOGGLE_PAGE_LOADING(true)

    try {
      await this.fetchCurrentUser()
      this.TOGGLE_PAGE_LOADING(false)
      this.$router.push({ name: RouteName.DASHBOARD })
    } catch {
      this.SET_USER(null)
      this.TOGGLE_PAGE_LOADING(false)
    }
  }

  readonly rightAction: AuthFormAction = {
    text: 'register',
    route: RouteName.REGISTER,
  }

  readonly leftAction: AuthFormAction = {
    text: 'reset_password',
    route: RouteName.SEND_RESET_PASSWORD,
  }

  readonly rules = {
    required: (value: string): true | string =>
      !!value || (this.$t('rule_required_error') as string),
    email: (value: string): true | string =>
      isEmail(value) || (this.$t('rule_invalid_email_error') as string),
  }

  get isFormComplete(): boolean {
    return (
      !!this.data.email &&
      isEmail(this.data.email) &&
      !!this.data.password &&
      this.captcha.token.length > 0
    )
  }

  form(): VForm {
    return this.$refs.form as VForm
  }

  resetData(): void {
    this.loading = false
    this.data.email = ''
    this.data.password = ''
    this.captcha.reset = true
    this.form().resetValidation()
  }

  sendActivaionLink(): void {
    this.$router.push({ name: RouteName.SEND_ACTIVATION })
  }

  async action(): Promise<void> {
    if (!this.isFormComplete) {
      return
    }

    this.authFormAlert = null
    this.loading = true
    try {
      const payload: LoginPayload = {
        email: this.data.email,
        password: this.data.password,
        recaptcha: this.captcha.token,
      }

      await this.authService.login(payload)
      this.$router.push({ name: RouteName.DASHBOARD })
    } catch (error: unknown) {
      console.error(error)
      this.authFormAlert = {
        type: 'error',
        text: error as string,
      }

      this.resetData()
    }
  }
}
