
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

@Component({ name: 'expansion-panel' })
export default class ExpansionPanel extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly noContent: boolean

  @Prop({ type: Boolean, default: false })
  readonly white: boolean

  @Prop({ type: Boolean, default: false })
  readonly expandable: boolean

  @Prop({ type: Boolean, default: false })
  readonly cancelExpansion: boolean

  @Prop({ type: String, default: 'center' })
  readonly align: string

  readonly colors = colors

  created(): void {
    if (this.expandable === true || this.noContent === true) {
      this.expand = false
    }
  }

  @Watch('expand')
  onExpandChange(val: boolean): void {
    this.headerExpansionClass = val ? 'expandedHeader' : 'nonExpandedHeader'
  }

  @Watch('cancelExpansion')
  onCancelExpansionChange(val: boolean): void {
    if (val === false) {
      return
    }

    this.toggleExpansion()
    this.$emit('update:cancelExpansion', false)
  }

  toggleExpansion(): void {
    if (this.noContent === false && this.expandable === true) {
      this.expand = !this.expand
    }
  }

  expand = true
  headerExpansionClass = 'expandedHeader'
}
