
import { Component, Prop, Vue } from 'vue-property-decorator'

import { Project, PublicUser, Team } from '@/services/bimoboxApiService/types'

import { getProjectModule, getTeamModule } from '@/store/utils'
import { colors, defaultAvatarUrl } from '@/utils/constants'

import CreateGuestsModal from '@/components/project/users/create.guests.modal.vue'
import DeleteGuestsModal from '@/components/project/information/delete.guests.modal.vue'

const Store = {
  ...getProjectModule(),
  ...getTeamModule(),
}

const components = {
  CreateGuestsModal,
  DeleteGuestsModal,
}

@Component({ name: 'guests-displayer', components })
export default class GuestsDisplayer extends Vue {
  @Prop({ type: String, required: true })
  readonly selectedUserId: string

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl

  @Store.ProjectModule.State
  readonly project: Project

  @Store.ProjectModule.Getter
  readonly isCreatorOrAdmin: boolean

  @Store.ProjectModule.Getter
  readonly isCreator: boolean

  @Store.TeamModule.Getter
  readonly guestTeam: (id: string) => Team | null

  get projectGuests(): PublicUser[] {
    return [
      this.project.creator,
      ...this.project.guests
        .filter(({ isKicked }) => !isKicked)
        .map(({ user }) => user),
    ]
  }

  showCreateGuestsModal = false
  showDeleteGuestsModal = false

  updateSelectedUserId(id: string): void {
    this.$emit('update:selectedUserId', id)
  }
}
