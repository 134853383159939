
import { Component, Vue } from 'vue-property-decorator'

import ActiveVisaTable from '@/components/project/information/active.visa.table.vue'
import ActiveAdviceTable from '@/components/project/information/active.advice.table.vue'

const components = {
  ActiveVisaTable,
  ActiveAdviceTable,
}

type RowElementType = 'visa' | 'bc'
interface RowElement {
  label: string
  value: RowElementType
  cssClass: { [key: string]: boolean }
  click(): void
}

@Component({ name: 'active-tables', components })
export default class ActiveTables extends Vue {
  active: RowElementType = 'visa'

  get rows(): RowElement[] {
    return [
      {
        label: (this.$t('active_visa_label') as string).toUpperCase(),
        value: 'visa',
        cssClass: {
          'table-row': true,
          'inactive-table-row': this.active !== 'visa',
          'active-table-row': this.active === 'visa',
        },
        click: () => {
          this.active = 'visa'
        },
      },
      {
        label: (this.$t('active_advice_label') as string).toUpperCase(),
        value: 'bc',
        cssClass: {
          'table-row': true,
          'inactive-table-row': this.active !== 'bc',
          'active-table-row': this.active === 'bc',
        },
        click: () => {
          this.active = 'bc'
        },
      },
    ]
  }
}
