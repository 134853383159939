import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  Endpoint,
  ICreateGuestPayload,
  IUpdateGuestPayload,
  ProjectGuest,
} from '@/services/bimoboxApiService/types'

export class GuestService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createGuests(
    projectId: string,
    guests: ICreateGuestPayload[]
  ): Promise<ProjectGuest[]> {
    const url = this.buildFullUrl(projectId)
    try {
      return await this.apiService.post<ProjectGuest[]>(url, { guests })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async resendLink(projectId: string, guestId: string): Promise<void> {
    const url = this.buildFullUrl(projectId, `${guestId}/sendInvitation`)
    try {
      return await this.apiService.post<void>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateGuest(
    projectId: string,
    guest: IUpdateGuestPayload
  ): Promise<ProjectGuest> {
    const url = this.buildFullUrl(projectId, guest.id)
    const body = {
      team: guest.team,
      isAdmin: guest.isAdmin,
    }

    try {
      return await this.apiService.put<ProjectGuest>(url, body)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async kickGuests(projectId: string, ids: string[]): Promise<ProjectGuest[]> {
    const url = this.buildFullUrl(projectId, 'kick')
    try {
      return await this.apiService.put<ProjectGuest[]>(url, { ids })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private buildFullUrl(projectId: string, params = ''): string {
    return this.buildUrl(`${projectId}/${Endpoint.GUEST}/${params}`)
  }
}
