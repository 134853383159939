
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PropType } from 'vue'

import {
  getAlertModule,
  getFolderModule,
  getVisaObservationModule,
} from '@/store/utils'
import {
  ICreateVisaObservationPayload,
  IFolder,
  IVisa,
  VisaObservationStatus,
} from '@/services/bimoboxApiService/types'
import { VisaExplicitStatus } from '@/utils/visa.utils'
import { colors } from '@/utils/constants'

import Action from '@/components/common/actions/action.vue'
import SignVisaModal from '@/components/project/documents/documents/visa/sign.visa.modal.vue'
import VisaChoiceModal from './VisaChoiceModal/visa.choice.modal.vue'
import RejectVisaModal from './reject.visa.modal.vue'
import OomVisaModal from './oom.visa.modal.vue'

const Store = {
  ...getVisaObservationModule(),
  ...getFolderModule(),
  ...getAlertModule(),
}

const components = {
  Action,
  VisaChoiceModal,
  SignVisaModal,
  RejectVisaModal,
  OomVisaModal,
}

@Component({ name: 'sign-visa-btn', components })
export default class SignVisaBtn extends Vue {
  @Prop({ required: true, type: Object as PropType<IVisa> })
  readonly visa: IVisa

  @Prop({ required: true, type: String })
  readonly versionId: string

  @Prop({ required: true, type: String })
  readonly documentId: string

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS: (message: string) => void

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder

  @Store.VisaObservationModule.Action
  readonly createObservation: (data: {
    folderId: string
    documentId: string
    versionId: string
    visaId: string
    payload: ICreateVisaObservationPayload
  }) => Promise<void>

  readonly colors = colors

  loading = false
  showSignVisaModal = false
  showChoseVisaStatus = false
  showOomModal = false
  showRejectModal = false
  signVisaModalStatus: VisaObservationStatus = VisaObservationStatus.REJECTED

  statusSelected(status: VisaExplicitStatus) {
    this.showChoseVisaStatus = false

    switch (status) {
      case VisaExplicitStatus.ACCEPTED_W_OBS:
        this.signVisaModalStatus = VisaObservationStatus.ACCEOTED_WITH_OBS
        this.showSignVisaModal = true
        break

      case VisaExplicitStatus.OUT_OF_MISSION:
        this.showOomModal = true
        break

      case VisaExplicitStatus.REJECTED:
        this.showRejectModal = true
        break

      case VisaExplicitStatus.SUSPENDEND:
        this.signVisaModalStatus = VisaObservationStatus.SUSPENDED
        this.showSignVisaModal = true
        break

      case VisaExplicitStatus.ACCEPTED:
        return this.signVisa({
          status: VisaObservationStatus.ACCEPTED,
          content: [],
        })

      default:
        return
    }
  }

  visaCompleted(payload: ICreateVisaObservationPayload): void {
    switch (payload.status) {
      case VisaObservationStatus.REJECTED:
        this.showRejectModal = false
        break

      case VisaObservationStatus.OUT_OF_MISSION:
        this.showOomModal = false
        break

      default:
        this.showSignVisaModal = false
    }

    this.signVisa(payload)
  }

  async signVisa(payload: ICreateVisaObservationPayload): Promise<void> {
    this.loading = true

    try {
      await this.createObservation({
        folderId: this.getParentByChildId(this.documentId).id,
        documentId: this.documentId,
        versionId: this.versionId,
        visaId: this.visa.id,
        payload,
      })

      this.ALERT_SUCCESS(this.visaStatusSuccess(payload.status))
    } catch (error) {
      this.ALERT_ERROR(error as string)
    } finally {
      this.loading = false
    }
  }

  visaStatusSuccess(status: VisaObservationStatus): string {
    switch (status) {
      case VisaObservationStatus.ACCEPTED:
        return 'visa_accept_success'
      case VisaObservationStatus.REJECTED:
        return 'visa_reject_success'
      case VisaObservationStatus.SUSPENDED:
        return 'visa_supsend_success'
      case VisaObservationStatus.ACCEOTED_WITH_OBS:
        return 'visa_accept_with_obs_success'
      case VisaObservationStatus.OUT_OF_MISSION:
        return 'visa_oom_success'
    }
  }
}
