
import { Component, Vue } from 'vue-property-decorator'

import { colors, RouteName } from '@/utils/constants'

@Component({ name: 'teams-users-nav-drawer' })
export default class TeamsUsersNavDrawer extends Vue {
  readonly colors = colors
  readonly RouteName = RouteName

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  goToPage(value: RouteName): void {
    this.$router.push({ name: value })
  }

  selectedItem = 0

  created(): void {
    const routeName: RouteName = this.$route.name as RouteName
    switch (routeName) {
      case RouteName.TEAMS:
        this.selectedItem = 1
        break
      case RouteName.USERS:
        this.selectedItem = 0
        break
      default:
        this.selectedItem = 2
        break
    }
  }
}
