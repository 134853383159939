export type UploadFilePayload = {
  file: File
  // eslint-disable-next-line
    onUploadProgress?: (progressEvent: any) => void
}

export type DBFile = {
  id: string
  status: string | 'complete'
  urn: string | null
  containerName: string
  url: string
  fileName: string
}

export type PublicFile = FileUrl & {
  name: string
}

export type FileUrl = {
  id: string
  name: string
  url: string
}

export class NamedBlob {
  public readonly name: string
  public readonly blob: Blob

  constructor(name: string, blob: Blob) {
    this.name = name
    this.blob = blob
  }

  downloadLocal(): void {
    const link = document.createElement('a')
    link.href = URL.createObjectURL(this.blob)
    link.download = this.name
    link.click()
    URL.revokeObjectURL(link.href)
  }
}

export interface NamedArrayBuffer {
  arrayBuffer: ArrayBuffer
  name: string
}
