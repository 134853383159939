
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  Project,
  PublicUser,
  Team,
  TeamRightGroups,
} from '@/services/bimoboxApiService/types'
import { VuetifyDataTableHeader } from '@/types/global'

import { getProjectModule } from '@/store/utils'
import { colors, defaultAvatarUrl, emptyRights } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import RightsCards from '@/components/project/teams/RightsCards.vue'
import FormActions from '@/components/common/actions/form.actions.vue'

const components = {
  BaseModal,
  RightsCards,
  FormActions,
}

const Store = {
  ...getProjectModule(),
}

@Component({ name: 'team-modal', components })
export default class TeamModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<Team>, required: true })
  readonly team: Team

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.stepperValue = 1

    this.$emit('input', value)
  }

  stepperValue = 1

  @Store.ProjectModule.State
  readonly project: Project

  get rights(): TeamRightGroups {
    return emptyRights(this.team)
  }

  get headerInfo(): VuetifyDataTableHeader[] {
    return [
      {
        value: 'avatar',
        align: 'center',
        filterable: false,
        sortable: false,
        width: '40',
      },
      {
        text: this.$t('name') as string,
        value: 'displayName',
        align: 'center',
      },
    ]
  }

  get teamMembers(): PublicUser[] {
    return this.project.guests
      .filter(({ user }) => this.team.membersIds.includes(user.id))
      .map(({ user }) => user)
  }
}
