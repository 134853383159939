
import { Component, Vue } from 'vue-property-decorator'
import { colors, RouteName } from '@/utils/constants'

import {
  IActiveVisa,
  PhaseGroup,
  Project,
  VisaUserRole,
} from '@/services/bimoboxApiService/types'

import { getAlertModule, getProjectModule } from '@/store/utils'

import { VisaService } from '@/services/bimoboxApiService'
import { VuetifyDataTableHeader } from '@/types/global'
import {
  VisaExplicitStatus,
  visaExplicitStatusToColor,
  visaExplicitStatusToIcon,
} from '@/utils/visa.utils'

const Store = {
  ...getProjectModule(),
  ...getAlertModule(),
}

interface DisplayableActiveVisa {
  documentId: string
  documentName: string
  hasSigned: boolean
  isLate: boolean
  phaseId: string
  phaseName: string
  userRole: VisaUserRole
}

@Component({ name: 'active-visa-table' })
export default class ActiveVisaTable extends Vue {
  @Store.ProjectModule.State
  readonly project: Project

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  readonly colors = colors
  readonly visaService = new VisaService()

  visas: IActiveVisa[] = []
  loading = false

  get activeVisas(): DisplayableActiveVisa[] {
    return this.visas.map((v) => ({
      documentId: v.documentId,
      documentName: v.documentName,
      hasSigned: v.hasSigned,
      dueDate: v.dueDate,
      isLate: new Date().getTime() > v.dueDate,
      phaseId: v.phaseId,
      phaseName: this.formatPhaseName(v.phaseName, v.phaseGroup),
      userRole: v.userRole,
    }))
  }

  formatPhaseName(name: string, group: PhaseGroup): string {
    const grpStr = (this.$t(`phase_group_${group}`) as string)
      .slice(0, 4)
      .toUpperCase()

    return `${grpStr} / ${name}`
  }

  get headerInfo(): VuetifyDataTableHeader[] {
    return [
      {
        text: this.$t('active_visa_document_name') as string,
        value: 'documentName',
      },
      {
        text: this.$t('active_visa_role') as string,
        value: 'userRole',
        width: '80px',
        align: 'center',
      },
      {
        text: this.$t('active_visa_status') as string,
        value: 'isLate',
        width: '90px',
        align: 'center',
      },
      {
        text: this.$t('active_visa_due_date') as string,
        value: 'dueDate',
        align: 'end',
      },
    ]
  }

  mounted(): void {
    this.fetchActiveVisa()
  }

  getVisaStatusColor(isLate: boolean): string {
    return visaExplicitStatusToColor(
      isLate ? VisaExplicitStatus.WAITING : VisaExplicitStatus.IN_PROGRESS
    )
  }

  getVisaStatusIcon(isLate: boolean): string {
    return visaExplicitStatusToIcon(
      isLate ? VisaExplicitStatus.WAITING : VisaExplicitStatus.IN_PROGRESS,
      true
    )
  }

  get(isLate: boolean): string {
    return visaExplicitStatusToColor(
      isLate ? VisaExplicitStatus.WAITING : VisaExplicitStatus.IN_PROGRESS
    )
  }

  getUserRoleTooltip(role: VisaUserRole, hasSigned: boolean): string {
    switch (role) {
      case 'none':
        return this.$t('active_visa_role_tooltip_none') as string
      case 'signer':
        return this.$t(
          hasSigned
            ? 'active_visa_role_tooltip_signer_signed'
            : 'active_visa_role_tooltip_signer_not_signed'
        ) as string
      case 'requester':
        return this.$t('active_visa_role_tooltip_requester') as string
    }
  }

  getUserRoleColor(role: VisaUserRole, hasSigned: boolean): string {
    switch (role) {
      case 'none':
        return 'grey'
      case 'signer':
        return hasSigned ? 'success' : 'warning'
      case 'requester':
        return 'info'
    }
  }

  getUserRoleIcon(role: VisaUserRole, hasSigned: boolean): string {
    switch (role) {
      case 'none':
        return 'mdi-account-cancel'
      case 'signer':
        return hasSigned ? 'mdi-account-check' : 'mdi-account-edit'
      case 'requester':
        return 'mdi-account-group'
    }
  }

  selectVisa(item: DisplayableActiveVisa): void {
    this.$router.push({
      name: RouteName.DOCUMENTS,
      query: { document: item.documentId, phase: item.phaseId },
    })
  }

  fetchActiveVisa(): void {
    this.loading = true

    this.visaService
      .getActiveVisas(this.project.id)
      .then((visas) => (this.visas = visas))
      .catch(() => this.ALERT_ERROR('active_visa_load_error'))
      .finally(() => (this.loading = false))
  }
}
