import Vue from 'vue'
import Vuetify from 'vuetify'
import i18n from '@/plugins/i18n'
import { colors } from '@/utils/constants'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params) as string,
  },
  theme: {
    themes: {
      light: {
        primary: colors.orange,
        accent: colors.orange,
        secondary: '#FFFFFF',
      },
      dark: {
        primary: colors.orange,
        accent: colors.orange,
        secondary: '#FFFFFF',
      },
    },
    options: {
      customProperties: true,
    },
    dark: false,
  },
})
