
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  FolderType,
  IFolder,
  IUpdateFolderPayload,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'
import { AlertWithData } from '@/types/global'

import { getAlertModule, getFolderModule, getTeamModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'

const components = {
  BaseModal,
  FormActions,
}

const Store = {
  ...getFolderModule(),
  ...getAlertModule(),
  ...getTeamModule(),
}

@Component({ name: 'update-folder-modal', components })
export default class UpdateFolderModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<IFolder>, required: true })
  readonly folder: IFolder

  readonly colors = colors

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.FolderModule.Action
  readonly updateFolder: (payload: IUpdateFolderPayload) => Promise<void>

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder | null

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  get hasConfidentialRights(): boolean {
    return (
      this.hasRight(TeamRightGroup.CONFIDENTIAL, TeamRight.ACCESS) &&
      this.hasRight(TeamRightGroup.CONFIDENTIAL, TeamRight.CREATE)
    )
  }

  get parent(): IFolder | null {
    return this.getParentByChildId(this.folder.id)
  }

  error = ''
  loading = false
  name = ''
  isConfidential = false

  created(): void {
    this.resetData()
  }

  get canSetConfidential(): boolean {
    if (this.parent !== null) {
      return false
    }

    if (this.folder.type === FolderType.Confidential) {
      return false
    }

    return this.hasConfidentialRights
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  get isFormValid(): boolean {
    return (
      (this.name.length > 0 && this.name !== this.folder.name) ||
      this.isConfidential === true
    )
  }

  resetData(): void {
    this.name = this.folder.name
    this.isConfidential = false
    this.loading = false
  }

  async primaryAction(): Promise<void> {
    this.loading = true
    try {
      await this.updateFolder({
        name: this.name,
        id: this.folder.id,
        type: this.isConfidential ? FolderType.Confidential : this.folder.type,
      })

      const alertData: AlertWithData = {
        message: 'folder_update_success',
        data: { name: this.name },
      }

      this.ALERT_SUCCESS_WITH_DATA(alertData)
      this.show = false
    } catch (error) {
      console.error(error)
      this.error = 'unknown_error'
      this.resetData()
    }
  }
}
