import { IVisaObservation } from '@/services/bimoboxApiService/visaObservation/visaObservation.types'
import { PhaseGroup, PublicUser } from '../types'

export enum VisaStatus {
  IN_PROGRESS = 'inProgress',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  OUT_OF_MISSION = 'out_of_mission',
}

export interface IVisa {
  id: string
  object: string
  dueDate: number
  observationsIds: string[]
  signersTeamsIds: string[]
  status: VisaStatus
  requester: PublicUser
}

export interface IVisaContent {
  visa: IVisa
  observations: IVisaObservation[]
}

export interface ICreateVisaPayload {
  folderId: string
  documentId: string
  versionId: string
  data: {
    object: string
    signersTeams: string[]
  }
}

export interface ICancelVisaPayload {
  folderId: string
  documentId: string
  versionId: string
  visaId: string
  data: {
    reason: string
  }
}

export type VisaUserRole = 'requester' | 'signer' | 'none'

export interface IActiveVisa {
  documentId: string
  documentName: string
  hasSigned: boolean
  dueDate: number
  phaseId: string
  phaseName: string
  phaseGroup: PhaseGroup
  userRole: VisaUserRole
}
