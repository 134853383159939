import { RouteName } from '@/utils/constants'

export type IconsSelectorItem = {
  icon: string
  id: string
  value: unknown
}

export type VForm = Vue & {
  validate: () => boolean
  resetValidation: () => void
  reset: () => void
}

export type VPond = Vue & {
  // eslint-disable-next-line
  getFiles: () => any[]
  removeFiles: () => void
}

export type Ref = Vue & {
  click: () => void
}

export type VuetifyDataTableHeader = {
  text?: string
  value: string
  align?: 'start' | 'center' | 'end'
  sortable?: boolean
  filterable?: boolean
  groupable?: boolean
  divider?: boolean
  class?: string | string[]
  cellClass?: string | string[]
  width?: string | number
  filter?: (value, search: string, item) => boolean
  sort?: (a, b) => number
}

export type VuetifyDataTableRow = {
  expand: (value: boolean) => void
  headers: VuetifyDataTableHeader[]
  isExpanded: boolean
  isMobile: boolean
  isSelected: boolean
  // eslint-disable-next-line
  item: any
  select: (value: boolean) => void
}

export type AuthFormAlert = {
  type: 'success' | 'error'
  text: string
}

export type AuthFormAction = {
  text: string
  route: RouteName
}

export enum SortBy {
  ALPHA_ASC = 'alphabetical-ascending',
  ALPHA_DESC = 'alphabetical-descending',
  DATE_ASC = 'date-ascending',
  DATE_DESC = 'date-descending',
}

export enum ProjectTypeFilter {
  ALL = 'all',
  MY = 'my',
  GUEST = 'guest',
}

export type NavBarTab = {
  title: string
  icon: string
  dataCy: string
  link: { name: RouteName }
}

export type I18NText = {
  key: string
  // eslint-disable-next-line
  data?: any
}

export interface NavBarProfileItem {
  title: string
  id: string
  action: () => void
}
