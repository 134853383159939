
import store from '@/store'
import { isEmail, isStrongPassword } from 'validator'
import { Component, Vue } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import { ResetPasswordPayload } from '@/services/bimoboxApiService/types'
import { AuthFormAction, AuthFormAlert, VForm } from '@/types/global'

import { getUserModule } from '@/store/utils'
import { colors, RouteName } from '@/utils/constants'

import { AuthService } from '@/services/bimoboxApiService'

import AuthForm from '@/components/AuthForm.vue'
import Recaptcha from '@/components/Recaptcha.vue'

const components = { AuthForm, Recaptcha }

const Store = {
  ...getUserModule(),
}

Component.registerHooks(['beforeRouteEnter'])
@Component({ name: 'resetPassword', components })
export default class ResetPassword extends Vue {
  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location) => void
  ): Promise<void> {
    store.commit('user/SET_USER', null)

    return next()
  }

  @Store.UserModule.Action
  readonly revokeTokens: () => Promise<void>

  readonly colors = colors
  readonly authService = new AuthService()

  loading = false
  authFormAlert: AuthFormAlert | null = null

  show = {
    password: false,
    passwordConfirmation: false,
  }

  data = {
    password: '',
    passwordConfirmation: '',
  }

  captcha = {
    reset: false,
    token: '',
  }

  readonly rightAction: AuthFormAction = {
    text: 'register',
    route: RouteName.REGISTER,
  }

  readonly leftAction: AuthFormAction = {
    text: 'login',
    route: RouteName.LOGIN,
  }

  readonly rules = {
    required: (value: string): boolean | string =>
      !!value || (this.$t('rule_required_error') as string),
    email: (value: string): boolean | string =>
      isEmail(value) || (this.$t('rule_invalid_email_error') as string),
    security: (value: string): string | boolean =>
      isStrongPassword(value) ||
      (this.$t('user_password_security_error') as string),
  }

  get arePwdAndConfirmationSame(): boolean | string {
    return (
      this.data.password === this.data.passwordConfirmation ||
      (this.$t('user_password_match_confirmation_error') as string)
    )
  }

  get isFormComplete(): boolean {
    return (
      this.data.password.length > 0 &&
      isStrongPassword(this.data.password) &&
      this.arePwdAndConfirmationSame === true &&
      this.captcha.token.length > 0
    )
  }

  form(): VForm {
    return this.$refs.form as VForm
  }

  resetData(): void {
    this.loading = false
    this.data.password = ''
    this.data.passwordConfirmation = ''
    this.captcha.reset = true
    this.form().resetValidation()
  }

  async callResetPassword(): Promise<void> {
    if (!this.isFormComplete) {
      return
    }

    this.authFormAlert = null
    this.loading = true

    try {
      const payload: ResetPasswordPayload = {
        recaptcha: this.captcha.token,
        code: this.$route.params.hash,
        password: this.data.password,
      }
      await this.authService.resetPassword(payload)
      this.authFormAlert = {
        type: 'success',
        text: 'reset_password_success',
      }
    } catch (error) {
      console.error(error)
      this.authFormAlert = {
        type: 'error',
        text: error as string,
      }
    }

    this.resetData()
  }
}
