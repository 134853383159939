export enum TeamRightGroup {
  TRD = 'trd',
  PHASE = 'phase',
  DOCUMENT = 'documents',
  MESSENGER = 'messenger',
  FOLDER = 'folders',
  CONFIDENTIAL = 'confidential',
}

export enum TeamRight {
  ACCESS = 'access',
  CREATE = 'create',
  UPDATE = 'upd',
  DELETE = 'del',
}

export type TrdRights = {
  access: boolean
  create: boolean
  upd: boolean
  del: boolean
}

export type PhaseRights = {
  create: boolean
  upd: boolean
  del: boolean
}

export type DocumentsRights = {
  access: boolean
  create: boolean
  upd: boolean
  del: boolean
}

export type MessengerRights = {
  create: boolean
  upd: boolean
  del: boolean
}

export type FoldersRights = {
  create: boolean
  upd: boolean
  del: boolean
}

export type ConfidentialRights = {
  access: boolean
  create: boolean
  upd: boolean
  del: boolean
}

export type TeamRightGroups = {
  trd: TrdRights
  phase: PhaseRights
  documents: DocumentsRights
  folders: FoldersRights
  messenger: MessengerRights
  confidential: ConfidentialRights
}

export type Team = TeamRightGroups & {
  id: string
  name: string
  membersIds: string[]
  color: string
}

export interface IPostTeamPayload extends TeamRightGroups {
  name: string
  membersIds: string[]
  color: string
}

export interface IUpdateTeamPayload {
  id: string
  data: IPostTeamPayload
}
