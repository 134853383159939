
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  FileUrl,
  IVisaObservationContent,
} from '@/services/bimoboxApiService/types'

import {
  getAlertModule,
  getUserModule,
  getVisaObservationModule,
} from '@/store/utils'
import { colors, getFormattedDate } from '@/utils/constants'

import { FileService } from '@/services/bimoboxApiService'

const Store = {
  ...getUserModule(),
  ...getAlertModule(),
  ...getVisaObservationModule(),
}

@Component({ name: 'visa-observation-content' })
export default class VisaObservationContent extends Vue {
  @Prop({ type: Object as PropType<IVisaObservationContent>, required: true })
  readonly content: IVisaObservationContent

  readonly colors = colors
  getFormattedDate = getFormattedDate
  readonly fileService = new FileService()

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  async downloadFile(file: FileUrl): Promise<void> {
    try {
      const blob = await this.fileService.downloadFile(file.id, file.name)
      blob.downloadLocal()
    } catch (error) {
      console.error(error)
      this.ALERT_ERROR('unknown_error')
    }
  }
}
