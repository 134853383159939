
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  CreateDocumentEmailTo,
  DBFile,
  FolderType,
  IArboPayload,
  ICreateDocumentPayload,
  IDocumentEmailDTO,
  IFolder,
  UploadFilePayload,
} from '@/services/bimoboxApiService/types'
import { AlertWithData, VPond } from '@/types/global'

import {
  getAlertModule,
  getDocumentModule,
  getFolderModule,
} from '@/store/utils'
import { colors } from '@/utils/constants'
import { getFileExtension } from '@/utils/fileExtensionChecker'
import { acceptedFilesExtensions } from '@/utils/forge.config'

import { FileService } from '@/services/bimoboxApiService'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import FilePondHandler from '@/components/common/FilePondHandler.vue'
import CreateDocumentEmail from './create.document.email.vue'

const components = {
  BaseModal,
  FormActions,
  FilePondHandler,
  CreateDocumentEmail,
}

const Store = {
  ...getDocumentModule(),
  ...getFolderModule(),
  ...getAlertModule(),
}

type PondFile = File & {
  _relativePath?: string
}

type FileToUpload = {
  id: string
  name: string
  path: string[]
  ext: string
}

type PondArborescenceFile = {
  name: string
  file: string
}

type PondFolder = PondArborescence & {
  name: string
}

type PondArborescence = {
  files: PondArborescenceFile[]
  folders: PondFolder[]
}

@Component({ name: 'create-document-modal', components })
export default class CreateDocumentModal extends Vue {
  @Prop({ type: Object as PropType<IFolder>, required: true })
  readonly folder: IFolder

  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  readonly colors = colors
  readonly fileService = new FileService()

  @Store.DocumentModule.Action
  readonly createDocument: (data: {
    folderId: string
    payload: ICreateDocumentPayload
  }) => Promise<void>

  @Store.FolderModule.Action
  readonly createArborescence: (payload: IArboPayload) => Promise<void>

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  error = ''
  loading = false
  newFiles: FileToUpload[] = []
  fileName = ''
  fileDescription = ''
  emailTo: CreateDocumentEmailTo = 'nobody'
  emailTargets: string[] = []
  emailMessage = ''

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.onShowChanged(value)
  }

  get showFileName(): boolean {
    return this.newFiles.length === 1 && this.isFilepondLoading === false
  }

  @Watch('showFileName')
  onShowFileNameChange(val: boolean): void {
    this.fileName = val ? this.newFiles[0].name : ''
    this.fileDescription = ''
    this.emailMessage = ''
    this.emailTargets = []
  }

  pond(): VPond {
    return this.$refs.pond as VPond
  }

  get acceptedTypes(): string {
    if (this.folder.type === FolderType.Trd) {
      return acceptedFilesExtensions.join(', ')
    }

    return ''
  }

  resetData(): void {
    this.newFiles = []
    this.fileName = ''
    this.fileDescription = ''
    this.loading = false
    this.emailTo = 'nobody'
    this.emailTargets = []
    this.emailMessage = ''
  }

  async onShowChanged(value: boolean): Promise<void> {
    this.error = ''
    if (value === false) {
      this.pond().removeFiles()
      if (this.newFiles.length > 0) {
        await this.deleteNewFiles()
      }
    }

    this.resetData()
    this.$emit('input', value)
  }

  async deleteNewFiles(): Promise<void> {
    this.loading = true
    await Promise.all(
      this.newFiles.map(({ id }) => this.fileService.deleteFile(id))
    )
    this.loading = false
  }

  async uploadFile(
    file: PondFile,
    onUploadProgress: (progressEvent) => void
  ): Promise<string> {
    const payload: UploadFilePayload = {
      file,
      onUploadProgress,
    }

    if (
      this.folder.type === FolderType.Trd &&
      !acceptedFilesExtensions.includes(getFileExtension(file.name))
    ) {
      throw 'unvalid file type'
    }

    const res: DBFile =
      this.folder.type === FolderType.Trd
        ? await this.fileService.uploadTrdFile(payload)
        : await this.fileService.uploadFile(payload)
    const splitedName = file.name.split('.')
    const ext = splitedName[splitedName.length - 1].toLowerCase()
    splitedName.pop()
    this.newFiles.push({
      id: res.id,
      name: splitedName.join('.'),
      path: file._relativePath?.length
        ? file._relativePath.slice(1).split('/')
        : [],
      ext,
    })
    return res.id
  }

  get isFormValid(): boolean {
    if (this.loading == true || this.isFilepondLoading === true) {
      return false
    }

    if (this.newFiles.length === 1) {
      if (this.fileName === '') {
        return false
      }

      if (this.emailTo !== 'nobody' && this.emailTargets.length === 0) {
        return false
      }

      return true
    }

    return this.newFiles.length > 0
  }

  isFilepondLoading = false

  filepondToggled(state: boolean): void {
    this.isFilepondLoading = state
  }

  fileDeleted(deletedId: string): void {
    this.newFiles = this.newFiles.filter(({ id }) => id !== deletedId)
  }

  async primaryAction(): Promise<void> {
    this.loading = true
    const alertData: AlertWithData = {
      message: '',
      data: {},
    }

    const emailOpts: IDocumentEmailDTO = {
      message: this.emailMessage,
      type: this.emailTo,
      targets: this.emailTargets,
    }

    try {
      if (this.newFiles.length === 1) {
        const payload: ICreateDocumentPayload = {
          name: `${this.fileName}.${this.newFiles[0].ext}`,
          file: this.newFiles[0].id,
          description:
            this.fileDescription.length === 0
              ? undefined
              : this.fileDescription.trim(),
          emailOpts,
        }

        await this.createDocument({ folderId: this.folder.id, payload })

        alertData.message = 'document_create_success'
        alertData.data = { name: payload.name }
      } else {
        alertData.message = 'documents_create_success'
        alertData.data = { count: this.newFiles.length }
        alertData.pluralize = true

        await this.createArborescence({
          parentId: this.folder.id,
          ...this.getFileArborescence(this.newFiles),
          emailOpts,
        })
      }

      this.newFiles = []
      this.ALERT_SUCCESS_WITH_DATA(alertData)
      this.show = false
    } catch (error) {
      try {
        await this.deleteNewFiles()
        this.pond().removeFiles()
      } catch (error) {
        console.error(error)
      }

      this.error = error as string
      this.resetData()
    }
  }

  getFileArborescence(files: FileToUpload[]): PondArborescence {
    const rootFiles: PondArborescenceFile[] = files
      .filter(({ path }) => path.length <= 1)
      .map((f) => ({ name: `${f.name}.${f.ext}`, file: f.id }))
    const subFiles = files.filter(({ path }) => path.length > 1)
    const foldersToCreate = [
      ...new Set(
        subFiles
          .filter(({ path }) => path.length > 1)
          .map(({ path }) => path[0])
      ),
    ]

    const folders = foldersToCreate.map((name) => ({
      name,
      files: subFiles.filter(({ path }) => path[0] === name),
    }))

    const retFolders: PondFolder[] = []
    for (const folder of folders) {
      const remapedPathFiles = folder.files.map((file) => {
        file.path.shift()
        return file
      })

      retFolders.push({
        name: folder.name,
        ...this.getFileArborescence(remapedPathFiles),
      })
    }

    return {
      files: rootFiles,
      folders: retFolders,
    }
  }
}
