
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  DBUser,
  FolderType,
  IAdvice,
  IDocument,
  IDocumentVersion,
  IFolder,
  IVisa,
  Team,
  TeamRight,
  TeamRightGroup,
  VisaStatus,
} from '@/services/bimoboxApiService/types'

import {
  getAdviceModule,
  getAlertModule,
  getDocumentModule,
  getDocumentVersionModule,
  getFolderModule,
  getProjectModule,
  getTeamModule,
  getUserModule,
  getVisaModule,
  getVisaObservationModule,
} from '@/store/utils'
import { colors } from '@/utils/constants'
import { getFileExtension } from '@/utils/fileExtensionChecker'

import BaseModal from '@/components/common/modals/base.modal.vue'
import DocumentVersion from '@/components/project/documents/documents/documentVersion.vue'
import CreateVersionModal from '@/components/project/documents/documents/create.version.modal.vue'
import Action from '@/components/common/actions/action.vue'
import SignVisaBtn from './visa/sign.visa.btn.vue'
import CreateVisaBtn from './visa/create.visa.btn.vue'
import CreateAdviceBtn from './advice/create.advice.btn.vue'
import EmitAdviceBtn from './advice/emit.advice.btn.vue'
import CancelVisaBtn from './visa/cancel.visa.btn.vue'

const components = {
  BaseModal,
  DocumentVersion,
  CreateVersionModal,
  Action,
  SignVisaBtn,
  CreateVisaBtn,
  CreateAdviceBtn,
  EmitAdviceBtn,
  CancelVisaBtn,
}

const Store = {
  ...getDocumentModule(),
  ...getDocumentVersionModule(),
  ...getUserModule(),
  ...getTeamModule(),
  ...getProjectModule(),
  ...getVisaModule(),
  ...getVisaObservationModule(),
  ...getFolderModule(),
  ...getAlertModule(),
  ...getAdviceModule(),
}

@Component({ name: 'document-modal', components })
export default class DocumentModal extends Vue {
  @Prop({ type: String, required: true })
  readonly documentId: string

  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  readonly colors = colors
  expandDescription = true
  showCreateVersionModal = false

  @Store.DocumentModule.Getter
  readonly getDocumentById: (id: string) => IDocument

  @Store.DocumentVersionModule.Getter
  readonly getVersionsByDocumentId: (id: string) => IDocumentVersion[]

  @Store.UserModule.State
  readonly user: DBUser

  @Store.TeamModule.Getter
  readonly userTeam: Team | null

  @Store.TeamModule.Getter
  readonly getTeamByUserId: (userId: string) => Team | null

  @Store.ProjectModule.Getter
  readonly isCreator: boolean

  @Store.ProjectModule.Getter
  readonly isAdmin: boolean

  @Store.VisaModule.Getter
  readonly getVisaById: (visaId: string) => IVisa

  @Store.AdviceModule.Getter
  readonly getAdviceById: (adviceId: string) => IAdvice

  @Store.VisaObservationModule.Getter
  readonly hasUserSigned: (userId: string, observationsIds: string[]) => boolean

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.DocumentModule.Action
  readonly retryUpload: (data: {
    folderId: string
    documentId: string
  }) => Promise<void>

  get isVersionUploaded(): boolean {
    return this.currentVersion.status === 100
  }

  get document(): IDocument {
    return this.getDocumentById(this.documentId)
  }

  get documentVersions(): IDocumentVersion[] {
    return this.getVersionsByDocumentId(this.documentId)
  }

  get documentFileType(): string {
    return getFileExtension(this.currentVersion.fileUrl)
  }

  get currentVersionVisa(): IVisa | null {
    if (this.currentVersion.visaId === null) {
      return null
    }

    return this.getVisaById(this.currentVersion.visaId)
  }

  get currentVersionAdvice(): IAdvice | null {
    if (this.currentVersion.adviceId === null) {
      return null
    }

    return this.getAdviceById(this.currentVersion.adviceId)
  }

  get isVisaInProgress(): boolean {
    if (this.currentVersionVisa === null) {
      return false
    }

    return this.currentVersionVisa.status === VisaStatus.IN_PROGRESS
  }

  get enableCreateVisaModal(): boolean {
    if (this.currentVersion.visaId !== null) {
      return false
    }

    if (this.folder.type === FolderType.Confidential) {
      return false
    }

    return (
      this.isCreator ||
      this.isAdmin ||
      this.userTeam === this.getTeamByUserId(this.document.creator)
    )
  }

  get enableDeleteVisaModal(): boolean {
    if (
      !this.currentVersionVisa ||
      this.currentVersionVisa.status !== VisaStatus.IN_PROGRESS
    ) {
      return false
    }

    return (
      this.isCreator ||
      this.isAdmin ||
      this.userTeam ===
        this.getTeamByUserId(this.currentVersionVisa.requester.id)
    )
  }

  get enableCreateAdviceModal(): boolean {
    if (this.currentVersion.adviceId !== null) {
      return false
    }

    if (this.folder.type !== FolderType.Normal) {
      return false
    }

    return (
      this.isCreator ||
      this.isAdmin ||
      this.userTeam === this.getTeamByUserId(this.document.creator)
    )
  }

  get enableCreateVersionModal(): boolean {
    if (
      this.currentVersion.visaId !== null &&
      this.currentVersionVisa?.status === VisaStatus.IN_PROGRESS
    ) {
      return false
    }

    if (
      this.currentVersion.adviceId !== null &&
      this.currentVersionAdvice?.advice === null
    ) {
      return false
    }

    return (
      this.isCreator ||
      this.isAdmin ||
      this.userTeam === this.getTeamByUserId(this.document.creator)
    )
  }

  get enableEmitAdviceModal(): boolean {
    if (!this.currentVersionAdvice) {
      return false
    }

    if (this.currentVersionAdvice.advice !== null) {
      return false
    }

    if (
      this.user.id === this.currentVersionAdvice.requester.id ||
      this.user.id === this.currentVersion.creator.id
    ) {
      return false
    }

    return (
      this.userTeam !== null &&
      this.currentVersionAdvice?.signersTeamsIds.includes(this.userTeam.id)
    )
  }

  get enableSignVisaModal(): boolean {
    if (this.currentVersionVisa === null) {
      return false
    }

    if (this.currentVersionVisa.status !== VisaStatus.IN_PROGRESS) {
      return false
    }

    if (
      this.user.id === this.currentVersionVisa.requester.id ||
      this.user.id === this.currentVersion.creator.id
    ) {
      return false
    }

    const hasUserSigned = this.hasUserSigned(
      this.user.id,
      this.currentVersionVisa.observationsIds
    )

    if (hasUserSigned) {
      return false
    }

    return (
      this.userTeam !== null &&
      this.currentVersionVisa?.signersTeamsIds.includes(this.userTeam.id)
    )
  }

  get currentVersion(): IDocumentVersion {
    return this.documentVersions[0]
  }

  get perviousVersions(): IDocumentVersion[] {
    const versionsCopy = [...this.documentVersions]
    versionsCopy.shift()
    return versionsCopy
  }

  get folder(): IFolder {
    return this.getParentByChildId(this.documentId)
  }

  get showRetryUploadBanner(): boolean {
    return (
      this.currentVersion.status === -1 &&
      this.folder.type === FolderType.Trd &&
      this.hasRight(TeamRightGroup.TRD, TeamRight.ACCESS)
    )
  }

  get currentVersionAdviceTeams(): Team[] {
    if (!this.currentVersionAdvice) {
      return []
    }

    return this.teams.filter(({ id }) =>
      this.currentVersionAdvice?.signersTeamsIds.includes(id)
    )
  }

  uploadLoading = false

  async doRetryUpload(): Promise<void> {
    const data = {
      documentId: this.documentId,
      folderId: this.folder.id,
    }

    this.uploadLoading = true

    this.retryUpload(data)
      .catch((error) => {
        this.ALERT_ERROR(error as string)
      })
      .finally(() => {
        this.uploadLoading = false
      })
  }
}
