
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  IBasicPhase,
  ILockPhasePayload,
  Team,
} from '@/services/bimoboxApiService/types'
import { AlertWithData, I18NText } from '@/types/global'

import { getAlertModule, getPhaseModule } from '@/store/utils'

import ConfirmationModal from '@/components/common/modals/confirmation.modal.vue'

const components = {
  ConfirmationModal,
}

const Store = {
  ...getAlertModule(),
  ...getPhaseModule(),
}

@Component({ name: 'toggle-lock-phase-modal', components })
export default class ToggleLockPhaseModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<Team>, required: true })
  readonly phase: IBasicPhase

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.PhaseModule.Action
  readonly lockPhase: (data: {
    id: string
    payload: ILockPhasePayload
  }) => Promise<void>

  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.loading = false
    this.$emit('input', value)
  }

  get confirmationModalText(): I18NText {
    if (!this.show) {
      return { key: '' }
    }

    const key = `phase_${this.phase.locked ? 'unlock' : 'lock'}_confirmation`
    return {
      key,
      data: { name: this.phase.name },
    }
  }

  async action(): Promise<void> {
    this.loading = true

    const payload: ILockPhasePayload = {
      locked: !this.phase.locked,
    }

    try {
      await this.lockPhase({ id: this.phase.id, payload })
      this.ALERT_SUCCESS_WITH_DATA({
        message: `phase_${payload.locked ? 'lock' : 'unlock'}_success`,
        data: { name: this.phase.name },
      })
    } catch (error) {
      this.ALERT_ERROR(error as string)
    }

    this.show = false
  }
}
