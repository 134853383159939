import router from '@/router'
import { getAnalytics } from 'firebase/analytics'
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { applyActionCode, getAuth } from 'firebase/auth'
import { AuthService } from './bimoboxApiService'

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

const firebase = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebase)

export { analytics }

export const verifyEmail = async (oobCode: string): Promise<void> => {
  try {
    await applyActionCode(getAuth(), oobCode)
  } catch (e) {
    console.error(e)
    throw 'confirm_account_error'
  }
}

export const logout = async (): Promise<void> => {
  try {
    await new AuthService().signOut()
    router.push({ path: '/auth/login' })
  } catch (e) {
    console.error(`[logout]`, e)
  }
}
