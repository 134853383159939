
import store from '@/store'
import { isStrongPassword } from 'validator'
import { Component, Vue } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import {
  ActivateGuestPayload,
  DBUser,
  UpdateProfilePayload,
} from '@/services/bimoboxApiService/types'
import { AuthFormAlert, VForm } from '@/types/global'

import { getUserModule } from '@/store/utils'
import { colors, RouteName } from '@/utils/constants'

import { AuthService } from '@/services/bimoboxApiService'

import AuthForm from '@/components/AuthForm.vue'
import Recaptcha from '@/components/Recaptcha.vue'

const components = { AuthForm, Recaptcha }

const Store = {
  ...getUserModule(),
}

Component.registerHooks(['beforeRouteEnter'])
@Component({ name: 'newGuestActivation', components })
export default class NewGuestActivation extends Vue {
  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location) => void
  ): Promise<void> {
    store.commit('user/SET_USER', null)

    return next()
  }

  @Store.UserModule.Action
  readonly fetchCurrentUser: () => Promise<void>

  @Store.UserModule.Action
  readonly updateProfile: (data: UpdateProfilePayload) => Promise<DBUser>

  readonly colors = colors
  readonly authService = new AuthService()
  loading = false

  show = {
    password: false,
    passwordConfirmation: false,
  }

  captcha = {
    reset: false,
    token: '',
  }

  email: string = this.$route.params.email
  code: string = this.$route.params.hash

  mounted(): void {
    if (this.email?.length === 0 || this.code?.length === 0) {
      this.$router.push({ name: RouteName.LOGIN })
    }
  }

  authFormAlert: AuthFormAlert | null = null

  updateUserData = {
    name: '',
    company: '',
    lastName: '',
  }

  updatePasswordData = {
    password: '',
    passwordConfirmation: '',
  }

  readonly rules = {
    required: (value: string): boolean | string =>
      !!value || (this.$t('rule_required_error') as string),
    security: (value: string): string | boolean =>
      isStrongPassword(value) ||
      (this.$t('user_password_security_error') as string),
  }

  get arePwdAndConfirmationSame(): boolean | string {
    return (
      this.updatePasswordData.password ===
        this.updatePasswordData.passwordConfirmation ||
      (this.$t('user_password_match_confirmation_error') as string)
    )
  }

  get isPasswordOk(): boolean {
    return (
      this.updatePasswordData.password.length > 0 &&
      isStrongPassword(this.updatePasswordData.password) &&
      this.arePwdAndConfirmationSame === true
    )
  }

  get isUserDataOk(): boolean {
    return (
      this.updateUserData.name.length > 0 &&
      this.updateUserData.company.length > 0 &&
      this.updateUserData.lastName.length > 0 &&
      this.captcha.token.length > 1
    )
  }

  form(): VForm {
    return this.$refs.form as VForm
  }

  resetData(): void {
    this.loading = false
    this.updatePasswordData.password = ''
    this.updatePasswordData.passwordConfirmation = ''
    this.updateUserData.name = ''
    this.updateUserData.company = ''
    this.updateUserData.lastName = ''
    this.captcha.reset = true
    this.form().resetValidation()
  }

  async primaryAction(): Promise<void> {
    if (!this.isUserDataOk) {
      return
    }

    this.loading = true
    try {
      const payload: ActivateGuestPayload = {
        code: this.code,
        password: this.updatePasswordData.password,
        recaptcha: this.captcha.token,
        ...this.updateUserData,
      }

      await this.authService.activateGuest(this.email, payload)
      this.$router.push({ name: RouteName.DASHBOARD })
    } catch (error) {
      console.error(error)
      this.authFormAlert = {
        type: 'error',
        text: 'guest_confirm_account_error',
      }

      this.resetData()
    }
  }
}
