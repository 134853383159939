
import { Component, Prop, Vue } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

@Component({ name: 'action' })
export default class Action extends Vue {
  @Prop({ required: false, type: Boolean, default: true })
  readonly isPrimary: boolean

  @Prop({ required: true, type: String })
  readonly text: string

  @Prop({ type: String, default: colors.orange })
  readonly color: string

  @Prop({ type: Boolean, default: false })
  readonly loading: boolean

  @Prop({ type: String, default: '' })
  readonly customStyle: string

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean

  @Prop({ type: Boolean, default: false })
  readonly keepCase: boolean

  @Prop({ type: Boolean, default: false })
  readonly rounded: boolean

  get selectedColor(): string {
    return this.disabled ? 'grey darken-1' : this.color
  }

  get classes(): string {
    const classes: string[] = []

    if (this.rounded) {
      classes.push('roundButton')
    }

    if (this.keepCase) {
      classes.push('keepCase')
    }

    if (!this.isPrimary) {
      classes.push('solidButton')
    }

    return classes.join(' ')
  }

  click(): void {
    this.$emit('click')
  }
}
