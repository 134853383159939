import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  IDocumentVersionContent,
  Endpoint,
  ICreateDocumentVersionPayload,
} from '@/services/bimoboxApiService/types'

export class DocumentVersionService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createVersion(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string,
    payload: ICreateDocumentVersionPayload
  ): Promise<IDocumentVersionContent> {
    const url = this.buildFullUrl(projectId, phaseId, folderId, documentId)
    try {
      return await this.apiService.post<IDocumentVersionContent>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private buildFullUrl(
    projectId: string,
    phaseId: string,
    folderId: string,
    documentId: string,
    params = ''
  ): string {
    return this.buildUrl(
      `${projectId}/${Endpoint.PHASE}/${phaseId}/${Endpoint.FOLDER}/${folderId}/${Endpoint.DOCUMENT}/${documentId}/${Endpoint.VERSION}/${params}`
    )
  }
}
