
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  FolderType,
  IBasicPhase,
  IDocument,
  IDocumentVersion,
  IFolder,
  Project,
  PublicUser,
} from '@/services/bimoboxApiService/types'
import {
  DocumentsTreeviewItemType,
  IDocumentsTreeviewItem,
} from '@/types/global'

import {
  getDocumentModule,
  getDocumentVersionModule,
  getFolderModule,
  getPhaseModule,
  getProjectModule,
} from '@/store/utils'

import FolderRow from '@/components/project/documents/folders/folder.row.vue'
import DocumentRow from '@/components/project/documents/documents/document.row.vue'
import CreateFolderModal from '@/components/project/documents/folders/create.folder.modal.vue'
import CreateDocumentModal from '@/components/project/documents/documents/create.document.modal.vue'
import UpdateFolderModal from '@/components/project/documents/folders/update.folder.modal.vue'
import DeleteFolderModal from '@/components/project/documents/folders/delete.folder.modal.vue'
import UpdateDocumentModal from '@/components/project/documents/documents/update.document.modal.vue'
import DeleteDocumentModal from '@/components/project/documents/documents/delete.document.modal.vue'
import DownloadDocumentsSnackbar from '@/components/project/documents/documents/download.documents.snackbar.vue'
import DocumentModal from '@/components/project/documents/documents/document.modal.vue'
import { PropType } from 'vue'
import { toNormalizedString } from '@/utils/projectsFilter'

const components = {
  FolderRow,
  DocumentRow,
  CreateFolderModal,
  CreateDocumentModal,
  UpdateFolderModal,
  DeleteFolderModal,
  UpdateDocumentModal,
  DeleteDocumentModal,
  DownloadDocumentsSnackbar,
  DocumentModal,
}

const Store = {
  ...getFolderModule(),
  ...getDocumentModule(),
  ...getPhaseModule(),
  ...getDocumentVersionModule(),
  ...getProjectModule(),
}

@Component({ name: 'documents-viewer', components })
export default class DocumentsViewer extends Vue {
  @Prop({ type: Boolean })
  readonly isFileSelectionEnabled: boolean

  @Prop({ type: String, default: '' })
  readonly search: string

  @Prop({ type: Boolean, default: false })
  readonly onlyTrd: boolean

  @Prop({ type: Array as PropType<string[]>, default: [] })
  readonly selectedFiles: string[]

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase | null

  @Store.FolderModule.Getter
  readonly getFolderById: (id: string) => IFolder

  @Store.DocumentModule.Getter
  readonly getDocumentById: (id: string) => IDocument | null

  @Store.FolderModule.Getter
  readonly currentPhaseFolders: IFolder[]

  @Store.DocumentVersionModule.Getter
  readonly getVersionsByDocumentId: (id: string) => IDocumentVersion[]

  @Store.PhaseModule.Getter
  readonly doesPhaseContainsDocuments: boolean

  @Store.ProjectModule.State
  readonly project: Project

  get projectUsers(): PublicUser[] {
    return [...this.project.guests.map((g) => g.user), this.project.creator]
  }

  get filesSelection(): string[] {
    return this.selectedFiles
  }

  set filesSelection(val: string[]) {
    this.$emit('update:selectedFiles', val ?? [])
  }

  get folders(): IDocumentsTreeviewItem[] {
    if (this.currentPhase === null) {
      return []
    }

    const phaseFolders =
      this.onlyTrd === true
        ? this.currentPhaseFolders.filter(({ type }) => type === FolderType.Trd)
        : this.currentPhaseFolders

    const mapped = phaseFolders.map(({ id }) => this.mapFolderForTreeview(id))

    let ret = mapped.filter((f) => f !== null) as IDocumentsTreeviewItem[]

    if (this.search.length) {
      ret = ret.filter((f) => !!f?.children?.length)
    }

    return ret
  }

  mounted() {
    if (this.querriedDocument !== null && this.showDocumentModal === false) {
      const docToShow = this.getDocumentById(this.querriedDocument)
      if (docToShow !== null) {
        this.documentToShow = this.querriedDocument
        this.showDocumentModal = true
      } else {
        const query = { ...this.$route.query }
        delete query.document
        this.$router.replace({ query })
      }
    }
  }

  private mapFolderForTreeview(
    folderId: string
  ): IDocumentsTreeviewItem | null {
    const folder = this.getFolderById(folderId)
    if (folder === null) {
      return null
    }

    let folderChildren = folder.folders
      .map((id) => this.mapFolderForTreeview(id))
      .filter((f) => f !== null)
      .map((item) => ({
        name: this.getFolderById((item as IDocumentsTreeviewItem).id).name,
        item,
      }))

    if (this.search.length > 0) {
      folderChildren = folderChildren.filter(
        ({ item }) => !!item?.children?.length
      )
    }

    folderChildren.sort((a, b) =>
      a.name === b.name ? 0 : a.name > b.name ? 1 : -1
    )

    const toTreeviewItem = (id: string): IDocumentsTreeviewItem | null => {
      const doc = this.getDocumentById(id)
      if (!doc) {
        return null
      }

      if (this.search.length > 0) {
        const author =
          this.projectUsers.find(({ id }) => id === doc.creator)?.displayName ??
          null

        const normalizedName = toNormalizedString(doc.name).toLowerCase()
        const normalizedAuthor = author
          ? toNormalizedString(author).toLowerCase()
          : null
        const normalizedSearch = toNormalizedString(this.search).toLowerCase()

        if (
          !normalizedName.includes(normalizedSearch) &&
          !normalizedAuthor?.includes(normalizedSearch)
        ) {
          return null
        }
      }

      return {
        disabled: this.onlyTrd ? this.isDocumentOnLoad(id) : undefined,
        type: DocumentsTreeviewItemType.DOCUMENT,
        id,
      }
    }

    const documentsChildrens = folder.documents
      .map(toTreeviewItem)
      .filter((d) => d !== null)
      .map((item) => ({
        name: (
          this.getDocumentById((item as IDocumentsTreeviewItem).id) as IDocument
        ).name,
        item,
      }))

    documentsChildrens.sort((a, b) =>
      a.name === b.name ? 0 : a.name > b.name ? 1 : -1
    )

    return {
      type: DocumentsTreeviewItemType.FOLDER,
      id: folder.id,
      children: [
        ...folderChildren.map(({ item }) => ({
          ...(item as IDocumentsTreeviewItem),
        })),
        ...documentsChildrens.map(({ item }) => ({
          ...(item as IDocumentsTreeviewItem),
        })),
      ],
    }
  }

  private isDocumentOnLoad(documentId: string): boolean {
    const versions = this.getVersionsByDocumentId(documentId)
    const currentVersion = versions[0]

    return currentVersion.status !== 100
  }

  parentFolderId: string | null = null
  showCreateFolderModal = false

  showCreateFolderModalToggle(id: string): void {
    this.showCreateFolderModal = true
    this.parentFolderId = id
  }

  @Watch('showCreateFolderModal')
  onShowCreateFolderModalChange(val: boolean): void {
    if (!val) {
      this.parentFolderId = null
      // this.folders = this.getFolders(this.currentPhase?.id)
    }
  }

  selectedFolder: IFolder | null = null
  showCreateDocumentModal = false

  showCreateDocumentModalToggle(id: string): void {
    this.selectedFolder = this.getFolderById(id)
    this.showCreateDocumentModal = true
  }

  @Watch('showCreateDocumentModal')
  onShowCreateDocumentModalChange(val: boolean): void {
    if (!val) {
      this.selectedFolder = null
      // this.folders = this.getFolders(this.currentPhase?.id)
    }
  }

  showUpdateFolderModal = false

  showUpdateFolderModalToggle(id: string): void {
    this.selectedFolder = this.getFolderById(id)
    this.showUpdateFolderModal = true
  }

  @Watch('showUpdateFolderModal')
  onShowUpdateFolderModalChange(val: boolean): void {
    if (!val) {
      this.selectedFolder = null
      // this.folders = this.getFolders(this.currentPhase?.id)
    }
  }

  showDeleteFolderModal = false

  showDeleteFolderModalToggle(id: string): void {
    this.selectedFolder = this.getFolderById(id)
    this.showDeleteFolderModal = true
  }

  @Watch('showDeleteFolderModal')
  onShowDeleteFolderModalChange(val: boolean): void {
    if (!val) {
      this.selectedFolder = null
      // this.folders = this.getFolders(this.currentPhase?.id)
    }
  }

  selectedDocument: IDocument | null = null
  showUpdateDocumentModal = false

  showUpdateDocumentModalToggle(id: string): void {
    this.selectedDocument = this.getDocumentById(id)
    this.showUpdateDocumentModal = true
  }

  @Watch('showUpdateDocumentModal')
  onShowUpdateDocumentModalChange(val: boolean): void {
    if (!val) {
      this.selectedDocument = null
      // this.folders = this.getFolders(this.currentPhase?.id)
    }
  }

  showDeleteDocumentModal = false

  showDeleteDocumentModalToggle(id: string): void {
    this.selectedDocument = this.getDocumentById(id)
    this.showDeleteDocumentModal = true
  }

  @Watch('showDeleteDocumentModal')
  onShowDeleteDocumentModalChange(val: boolean): void {
    if (!val) {
      this.selectedDocument = null
      // this.folders = this.getFolders(this.currentPhase?.id)
    }
  }

  showDownloadSnackbar = false

  @Watch('showDownloadSnackbar')
  onShowDownloadSnackbarChange(val: boolean): void {
    if (!val) {
      this.$emit('update:isFileSelectionEnabled', false)
    }
  }

  @Watch('isFileSelectionEnabled')
  onIsFileSelectionEnabledChange(val: boolean): void {
    if (val && !this.onlyTrd) {
      this.showDownloadSnackbar = true
    }
  }

  documentToShow: string | null = null
  showDocumentModal = false
  @Watch('showDocumentModal')
  onShowDocumentModalChange(val: boolean): void {
    if (val === false) {
      if (this.documentToShow === this.querriedDocument) {
        const query = { ...this.$route.query }
        delete query.document
        this.$router.replace({ query })
      }
      this.documentToShow = null
    }
  }

  get querriedDocument(): string | null {
    return (this.$route.query.document as string) ?? null
  }
}
