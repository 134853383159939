import Vue from 'vue'
import VueI18n, { I18nOptions } from 'vue-i18n'

import dateTimeFormats from '@/plugins/i18n/dateTimeFormats'
import en from '@/plugins/i18n/locales/en'
import fr from '@/plugins/i18n/locales/fr'

Vue.use(VueI18n)
const options: I18nOptions = {
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
  dateTimeFormats,
  messages: {
    fr,
    en,
  },
}

export default new VueI18n(options)

const supportedLanguages = ['fr', 'en'] as const
export type I18NSupportedLanguage = (typeof supportedLanguages)[number]

export const isI18NSupportedLanguage = (
  maybeSupportedLanguage: string
): maybeSupportedLanguage is I18NSupportedLanguage => {
  const nameFound = supportedLanguages.find(
    (validLanguage) => validLanguage === maybeSupportedLanguage
  )

  return nameFound !== undefined
}
