
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PropType } from 'vue'

import {
  IAdvice,
  IDocument,
  IDocumentVersion,
} from '@/services/bimoboxApiService/types'

import Action from '@/components/common/actions/action.vue'
import EmitAdviceModal from './emit.advice.modal.vue'

const components = {
  EmitAdviceModal,
  Action,
}

@Component({ name: 'emit-advice-btn', components })
export default class EmitAdviceBtn extends Vue {
  @Prop({ required: true, type: Object as PropType<IAdvice> })
  readonly advice: IAdvice

  @Prop({ required: true, type: Object as PropType<IDocumentVersion> })
  readonly version: IDocumentVersion

  @Prop({ required: true, type: Object as PropType<IDocument> })
  readonly document: IDocument

  showEmitAdviceModal = false
}
