import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class PageLoaderModule extends VuexModule {
  hasEntered = false
  pageLoading = false

  @Mutation
  RESET_HAS_ENTERED(): void {
    this.hasEntered = false
  }

  @Mutation
  ENTER(): void {
    this.hasEntered = true
  }

  @Mutation
  TOGGLE_PAGE_LOADING(val: boolean): void {
    this.pageLoading = val
    if (!this.pageLoading && !this.hasEntered) {
      this.hasEntered = true
    }
  }
}
