
import { Component, Vue } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import { TeamRight, TeamRightGroup } from '@/services/bimoboxApiService/types'
import { NavBarTab } from '@/types/global'

import {
  getPageLoaderModule,
  getProjectModule,
  getTeamModule,
} from '@/store/utils'
import { RouteName, colors } from '@/utils/constants'

import { logout } from '@/services/firebase.service'
import store from '@/store'

import NavBar from '@/components/NavBar.vue'
import RequestAlert from '@/components/common/RequestAlert.vue'
import PageLoader from '@/components/common/page.loader.vue'

const components = {
  NavBar,
  RequestAlert,
  PageLoader,
}

const Store = {
  ...getPageLoaderModule(),
  ...getProjectModule(),
  ...getTeamModule(),
}

const beforeRouteMethod = async (
  to: Route,
  next: (location?: Location) => void,
  enter: boolean
): Promise<void> => {
  store.commit('pageLoader/TOGGLE_PAGE_LOADING', true)

  const fetchUserOrDisconnect = async (): Promise<void> => {
    store.dispatch('project/resetProjectStates', false)
    try {
      await store.dispatch('user/fetchCurrentUser')
    } catch {
      return logout()
    }
  }

  try {
    if (enter) {
      await Promise.all([
        fetchUserOrDisconnect(),
        store.dispatch('project/fetchProject', to.params.id),
        store.dispatch('team/fetchTeams', to.params.id),
      ])
    }

    const isCreatorOrAdmin: boolean = store.getters['project/isCreatorOrAdmin']
    // const userTeam: boolean = store.getters["team/userTeam"];

    const nextRoute = {
      name: RouteName.INFORMATIONS,
      params: { id: to.params.id },
    }

    if (to.name === RouteName.TEAMS && !isCreatorOrAdmin) {
      return next(nextRoute)
    }

    const hasRight: (group: TeamRightGroup, right: TeamRight) => boolean =
      store.getters['team/hasRight']

    switch (to.name) {
      case RouteName.DOCUMENTS:
        if (!hasRight(TeamRightGroup.DOCUMENT, TeamRight.ACCESS)) {
          return next(nextRoute)
        }

        break
      case RouteName.TRDVIEW:
        if (!hasRight(TeamRightGroup.TRD, TeamRight.ACCESS)) {
          return next(nextRoute)
        }

        break
      case RouteName.MESSENGER:
        if (!hasRight(TeamRightGroup.MESSENGER, TeamRight.ACCESS)) {
          return next(nextRoute)
        }

        break
      default:
        break
    }

    return next()
    // const params: Dictionary<string> = { id: to.params.id }
    // nextRoute = { name: RouteName.INFORMATIONS, params }
  } catch (e) {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    next({ name: RouteName.DASHBOARD })
  }
}

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate'])
@Component({ name: 'Project', components })
export default class Project extends Vue {
  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location) => void
  ): Promise<void> {
    return beforeRouteMethod(to, next, true)
  }

  async beforeRouteUpdate(
    to: Route,
    from: Route,
    next: (location?: Location) => void
  ): Promise<void> {
    return beforeRouteMethod(to, next, false)
  }

  @Store.PageLoaderModule.State
  readonly pageLoading: boolean

  @Store.PageLoaderModule.State
  readonly hasEntered: boolean

  @Store.ProjectModule.Getter
  readonly isCreatorOrAdmin: boolean

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  readonly colors = colors

  get showPageLoader(): boolean {
    return this.pageLoading && this.hasEntered
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get projectDivHeight(): number {
    const mainElement: HTMLElement = document.getElementById(
      'main'
    ) as HTMLElement
    const navBarHeight = 56
    const navBarTabsHeight = this.isMobile ? 0 : 35
    return mainElement.offsetHeight - navBarHeight - navBarTabsHeight
  }

  get tabs(): NavBarTab[] {
    const items = [
      {
        title: 'dashboard',
        icon: 'mdi-view-dashboard',
        dataCy: 'menuDashboardTitle',
        link: { name: RouteName.DASHBOARD },
        hasRight: true,
      },
      {
        title: 'route_information',
        icon: 'mdi-information',
        dataCy: 'menuInformationTitle',
        link: { name: RouteName.INFORMATIONS },
        hasRight: true,
      },
      {
        title: 'route_trd',
        icon: 'mdi-video-3d',
        dataCy: 'menuTrdViewTitle',
        link: { name: RouteName.TRDVIEW },
        hasRight: this.hasRight(TeamRightGroup.TRD, TeamRight.ACCESS),
      },
      {
        title: 'route_documents',
        icon: 'mdi-file-document-multiple',
        dataCy: 'menuDocumentsTitle',
        link: { name: RouteName.DOCUMENTS },
        hasRight: this.hasRight(TeamRightGroup.DOCUMENT, TeamRight.ACCESS),
      },
      // {
      //   title: "route_bcf",
      //   icon: "mdi-note",
      //   dataCy: "",
      //   link: { name: RouteName.BCF },
      // },
      {
        title: 'route_users',
        icon: 'mdi-account-supervisor',
        dataCy: 'menuUsersTitle',
        link: { name: RouteName.USERS },
        hasRight: true,
      },
      {
        title: 'route_teams',
        icon: 'mdi-account-group',
        link: { name: RouteName.TEAMS },
        dataCy: 'menuTeamsTitle',
        hasRight: this.isMobile === true && this.isCreatorOrAdmin,
      },
      // {
      //   title: "route_messages",
      //   icon: "mdi-forum",
      //   dataCy: "menuMessagesTitle",
      //   link: { name: RouteName.MESSENGER },
      // },
    ]

    return items
      .filter(({ hasRight }) => hasRight === true)
      .map(({ title, icon, link, dataCy }) => ({ title, icon, link, dataCy }))
  }

  getTabTitle(nonFormatedString: string): string {
    const title = this.$t(nonFormatedString) as string
    return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase()
  }
}
