import {
  IVisa,
  IVisaObservation,
  VisaObservationStatus,
  VisaStatus,
} from '@/services/bimoboxApiService/types'

export enum VisaExplicitStatus {
  SUSPENDEND = 'suspended',
  IN_PROGRESS = 'in_progress',
  WAITING = 'waiting',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  ACCEPTED_W_OBS = 'accepted_with_comment',
  OUT_OF_MISSION = 'out_of_mission',
}

export const hasObservationContent = (obs: IVisaObservation): boolean =>
  obs.content.length > 0

export const visaStatusToi18n = (
  observations: IVisaObservation[],
  visa?: IVisa | undefined
): VisaExplicitStatus => {
  if (visa && visa.status === VisaStatus.OUT_OF_MISSION) {
    return VisaExplicitStatus.OUT_OF_MISSION
  }

  if (visa && visa.status === VisaStatus.IN_PROGRESS) {
    const now = new Date().getTime()
    return visa.dueDate < now
      ? VisaExplicitStatus.WAITING
      : VisaExplicitStatus.IN_PROGRESS
  }

  const rejectedObs = observations.filter(
    ({ status }) => status === VisaObservationStatus.REJECTED
  )

  if (rejectedObs.length > 0) {
    return VisaExplicitStatus.REJECTED
  }

  const suspendedObs = observations.filter(
    ({ status }) => status === VisaObservationStatus.SUSPENDED
  )

  if (suspendedObs.length > 0) {
    return VisaExplicitStatus.SUSPENDEND
  }

  const acceptedWithObs = observations.filter(
    ({ status }) => status === VisaObservationStatus.ACCEOTED_WITH_OBS
  )

  if (acceptedWithObs.length > 0) {
    return VisaExplicitStatus.ACCEPTED_W_OBS
  }

  const acceptedObs = observations.filter(
    ({ status }) => status === VisaObservationStatus.ACCEPTED
  )

  if (acceptedObs.length > 0) {
    return VisaExplicitStatus.ACCEPTED
  }

  return VisaExplicitStatus.OUT_OF_MISSION
}

export const visaExplicitStatusToColor = (
  status: VisaExplicitStatus
): string => {
  switch (status) {
    case VisaExplicitStatus.SUSPENDEND:
      return 'orange'
    case VisaExplicitStatus.REJECTED:
      return 'red'
    case VisaExplicitStatus.WAITING:
      return 'orange'
    case VisaExplicitStatus.IN_PROGRESS:
      return 'black'
    case VisaExplicitStatus.ACCEPTED:
      return 'green'
    case VisaExplicitStatus.ACCEPTED_W_OBS:
      return 'yellow'
    case VisaExplicitStatus.OUT_OF_MISSION:
      return 'purple'
  }
}

export const visaExplicitStatusToIcon = (
  status: VisaExplicitStatus,
  isXs: boolean
): string => {
  switch (status) {
    case VisaExplicitStatus.SUSPENDEND:
      return isXs ? 'mdi-pause-circle' : 'mdi-pause'
    case VisaExplicitStatus.REJECTED:
      return isXs ? 'mdi-close-circle' : 'mdi-close'
    case VisaExplicitStatus.WAITING:
      return isXs ? 'mdi-clock-alert' : 'mdi-clock-alert-outline'
    case VisaExplicitStatus.IN_PROGRESS:
      return isXs ? 'mdi-clock' : 'mdi-clock-outline'
    case VisaExplicitStatus.ACCEPTED:
      return isXs ? 'mdi-check-circle' : 'mdi-check'
    case VisaExplicitStatus.ACCEPTED_W_OBS:
      return isXs ? 'mdi-comment-check' : 'mdi-comment-check-outline'
    case VisaExplicitStatus.OUT_OF_MISSION:
      return isXs ? 'mdi-comment-remove' : 'mdi-comment-remove-outline'
  }
}

export const isVisaStatusTextColorDark = (
  status: VisaExplicitStatus
): boolean => {
  if (
    status === VisaExplicitStatus.ACCEPTED_W_OBS ||
    status === VisaExplicitStatus.SUSPENDEND
  ) {
    return false
  }

  return true
}
