
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Action from '@/components/common/actions/action.vue'
import CreateAdviceModal from '@/components/project/documents/documents/advice/create.advice.modal.vue'

import { IDocument, IDocumentVersion } from '@/services/bimoboxApiService/types'

const components = {
  CreateAdviceModal,
  Action,
}

@Component({ name: 'create-advice-btn', components })
export default class CreateAdviceBtn extends Vue {
  @Prop({ type: Object as PropType<IDocumentVersion>, required: true })
  readonly version: IDocumentVersion

  @Prop({ type: Object as PropType<IDocument>, required: true })
  readonly document: IDocument

  get isVersionUploaded(): boolean {
    return this.version.status === 100
  }

  showCreateAdviceModal = false
}
