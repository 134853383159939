
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  ICreateVisaObservationPayload,
  IVisa,
  IVisaObservationContent,
  VisaObservationStatus,
} from '@/services/bimoboxApiService/types'

import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import CreateVisaObservation from '@/components/project/documents/documents/visa/create.visa.observation.vue'
import FormActions from '@/components/common/actions/form.actions.vue'

const components = {
  BaseModal,
  CreateVisaObservation,
  FormActions,
}

type IVisaObservationContentWithData = IVisaObservationContent & {
  validated: boolean
  loading: boolean
}

@Component({ name: 'sign-visa-modal', components })
export default class SignVisaModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({
    type: String as PropType<
      VisaObservationStatus.SUSPENDED | VisaObservationStatus.ACCEOTED_WITH_OBS
    >,
    required: true,
  })
  readonly status: VisaObservationStatus

  @Prop({ required: true, type: Object as PropType<IVisa> })
  readonly visa: IVisa

  readonly colors = colors

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  created() {
    this.resetData()
  }

  error = ''
  visaObservationContents: IVisaObservationContentWithData[] = []

  get title(): string {
    if (this.status === VisaObservationStatus.SUSPENDED) {
      return this.$t('visa_suspend') as string
    }

    return this.$t('visa_accept_with_obs') as string
  }

  resetData(): void {
    const emptyObsContent: IVisaObservationContentWithData = {
      text: '',
      files: [],
      validated: false,
      loading: false,
    }

    this.visaObservationContents = [emptyObsContent]
  }

  deleteContent(index: number): void {
    this.visaObservationContents = this.visaObservationContents.filter(
      (item, idx) => idx !== index
    )
  }

  validateContent(index: number): void {
    const emptyObsContent: IVisaObservationContentWithData = {
      text: '',
      files: [],
      validated: false,
      loading: false,
    }

    this.visaObservationContents[index].validated = true
    this.visaObservationContents = [
      emptyObsContent,
      ...this.visaObservationContents,
    ]
  }

  get isContentLoading(): boolean {
    return !this.visaObservationContents.every(({ loading }) => !loading)
  }

  get isFormComplete(): boolean {
    return !this.isContentLoading && this.visaObservationContents.length > 1
  }

  async action(): Promise<void> {
    const contents = [...this.visaObservationContents]
    contents.shift()

    const payload: ICreateVisaObservationPayload = {
      status: this.status,
      content: contents.map(({ text, files }) => ({
        text,
        files: files.map(({ id }) => id),
      })),
    }

    this.$emit('visaCompleted', payload)
  }
}
