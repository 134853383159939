
import { Component, Vue } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import { IconsSelectorItem, ProjectTypeFilter, SortBy } from '@/types/global'

import { colors } from '@/utils/constants'

import { logout } from '@/services/firebase.service'
import store from '@/store'

import IconsSelector from '@/components/common/IconsSelector.vue'
import RequestAlert from '@/components/common/RequestAlert.vue'
import CreateProjectModal from '@/components/dashboard/create.project.modal.vue'
import ProjectsCards from '@/components/dashboard/ProjectsCards.vue'
import NavBar from '@/components/NavBar.vue'

const components = {
  NavBar,
  ProjectsCards,
  IconsSelector,
  RequestAlert,
  CreateProjectModal,
}

type DashboardProjectFilter = {
  name: string
  value: ProjectTypeFilter
  icon: string
}

Component.registerHooks(['beforeRouteEnter'])
@Component({ name: 'Dashboard', components })
export default class Dashboard extends Vue {
  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get isSmallMobile(): boolean {
    return this.$vuetify.breakpoint.xs
  }

  readonly colors = colors
  showDrawer = false
  projectSearch = ''
  showCreateProjectModal = false

  selectedSortType = SortBy.ALPHA_ASC
  readonly sortTypes: IconsSelectorItem[] = [
    {
      icon: 'mdi-sort-alphabetical-ascending',
      id: '',
      value: SortBy.ALPHA_ASC,
    },
    {
      icon: 'mdi-sort-alphabetical-descending',
      id: '',
      value: SortBy.ALPHA_DESC,
    },
    { icon: 'mdi-sort-calendar-ascending', id: '', value: SortBy.DATE_ASC },
    { icon: 'mdi-sort-calendar-descending', id: '', value: SortBy.DATE_DESC },
  ]

  setSelectedSortType(sort: SortBy): void {
    this.selectedSortType = sort
  }

  get filterTypes(): DashboardProjectFilter[] {
    return [
      {
        name: (this.$t('dashboard_project_filter_all') as string).toUpperCase(),
        value: ProjectTypeFilter.ALL,
        icon: 'mdi-account-group-outline',
      },
      {
        name: (this.$t('dashboard_project_filter_my') as string).toUpperCase(),
        value: ProjectTypeFilter.MY,
        icon: 'mdi-account-outline',
      },
      {
        name: (
          this.$t('dashboard_project_filter_guest') as string
        ).toUpperCase(),
        value: ProjectTypeFilter.GUEST,
        icon: 'mdi-account-multiple',
      },
    ]
  }

  selectedFilterType = this.filterTypes[0].value

  closeDrawer = false

  setSelectedFilterType(type: ProjectTypeFilter): void {
    this.selectedFilterType = type
    this.closeDrawer = true
  }

  drawerToggled(value: boolean): void {
    if (value === false) {
      this.closeDrawer = false
    }
  }

  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location) => void
  ): Promise<void> {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', true)
    store.dispatch('project/resetProjectStates', true)

    try {
      await store.dispatch('user/fetchCurrentUser')
      await store.dispatch('dashboard/fetchUserProjects')
      store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
      next()
    } catch (e) {
      console.error(e)
      store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
      logout()
    }
  }

  beforeCreate(): void {
    document.body.className = 'dashboard'
  }
}
