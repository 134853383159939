import ServiceBusService, {
  ServiceBusServiceType,
} from '@/services/serviceBus.service'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class ServiceBusModule extends VuexModule {
  clients: ServiceBusService[] = []

  @Mutation
  ADD_CLIENT(client: ServiceBusService): void {
    client.startListening()
    this.clients.push(client)
  }

  @Mutation
  REMOVE_CLIENT(id: string): void {
    this.clients.filter((client) => client.id !== id)
  }

  @Action({ rawError: true, commit: 'ADD_CLIENT' })
  async addClient(data: {
    id: string
    type: ServiceBusServiceType
  }): Promise<ServiceBusService> {
    await this.context.dispatch('removeClient', data.id)
    return new ServiceBusService(data.id, data.type)
  }

  @Action({ rawError: true })
  async removeClient(id: string): Promise<void> {
    const client = this.clients.find(({ id }) => id === id)
    if (client) {
      await client.stopListening()
      this.context.commit('REMOVE_CLIENT', id)
    }
  }

  @Action({ rawError: true })
  async resetSbStates(): Promise<void> {
    await Promise.all(this.clients.map((client) => client.stopListening()))
    this.clients.forEach(({ id }) => this.context.commit('REMOVE_CLIENT', id))
  }
}
