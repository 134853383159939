import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import { Endpoint } from '@/services/bimoboxApiService/types'
import { AuthToken } from 'forge-apis'

export class ForgeService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async fetchToken(projectId: string, phaseId: string): Promise<AuthToken> {
    const url = this.buildFullUrl(projectId, phaseId, 'auth')
    try {
      return await this.apiService.get<AuthToken>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private buildFullUrl(
    projectId: string,
    phaseId: string,
    params = ''
  ): string {
    return this.buildUrl(
      `${projectId}/${Endpoint.PHASE}/${phaseId}/${Endpoint.FORGE}/${params}`
    )
  }
}
