
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IBasicPhase, PhaseGroup } from '@/services/bimoboxApiService/types'

import { colors, getIconForPhaseGroup } from '@/utils/constants'

@Component({ name: 'phase-group-displayer' })
export default class PhaseGroupDisplayer extends Vue {
  @Prop({ type: String as PropType<PhaseGroup>, required: true })
  readonly group: PhaseGroup

  @Prop({ type: Array as PropType<IBasicPhase[]>, default: [] })
  readonly phases: IBasicPhase[]

  @Prop({ type: String, required: true })
  readonly dataCy: string

  @Prop({ type: Array, default: () => [] })
  readonly selectedItems: string[]

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean

  @Prop({ type: String, default: '100%' })
  readonly width: string

  @Prop({ type: Boolean, default: false })
  readonly collapse: boolean

  get phasesToDisplay(): IBasicPhase[] {
    const ret = [...this.phases]
    ret.sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1))
    return ret
  }

  readonly colors = colors

  get phaseGroupIcon(): string {
    return getIconForPhaseGroup(this.group)
  }

  selectPhase(id: string): void {
    this.$emit('selectPhase', id)
  }
}
