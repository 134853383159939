
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { logout } from '@/services/firebase.service'

import {
  getPageLoaderModule,
  getProjectModule,
  getUserModule,
} from '@/store/utils'
import {
  RouteName,
  colors,
  defaultAvatarUrl,
  goToSupport,
} from '@/utils/constants'

import { DBUser, Project } from '@/services/bimoboxApiService/types'
import { NavBarProfileItem } from '@/types/global'

import ChevronLeftIcon from '@/assets/images/ic_chevron_left_orange.svg'
import Locale from '@/components/Locale.vue'
import NavBarDrawer from '@/components/navbar/NavBarDrawer.vue'
import NavBarTabs from '@/components/navbar/navbar.tabs.vue'
import UpdatePasswordModal from '@/components/navbar/update.password.modal.vue'
import UpdateProfileModal from '@/components/navbar/update.profile.modal.vue'

const components = {
  UpdatePasswordModal,
  UpdateProfileModal,
  NavBarTabs,
  NavBarDrawer,
  ChevronLeftIcon,
  Locale,
}

const Store = {
  ...getUserModule(),
  ...getPageLoaderModule(),
  ...getProjectModule(),
}

@Component({ name: 'nav-bar', components })
export default class NavBar extends Vue {
  @Prop({ required: true, type: String })
  readonly title: string

  @Prop({ type: Boolean, default: false })
  readonly addButton: boolean

  @Prop({ type: Boolean, default: false })
  readonly closeDrawer: boolean

  created(): void {
    this.ENTER()
  }

  @Store.UserModule.State
  readonly user: DBUser

  @Store.PageLoaderModule.Mutation
  readonly ENTER: () => void

  @Store.PageLoaderModule.Mutation
  readonly RESET_HAS_ENTERED: () => void

  @Store.ProjectModule.State
  readonly project: Project

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  readonly projectRoutes: RouteName[] = [
    RouteName.PROJECT,
    RouteName.INFORMATIONS,
    RouteName.TRDVIEW,
    RouteName.TEAMS,
    RouteName.USERS,
    RouteName.BCF,
    RouteName.DOCUMENTS,
    RouteName.MESSENGER,
  ]

  get isProjectRoute(): boolean {
    const currentRoute = this.$route.name as RouteName
    return this.projectRoutes.includes(currentRoute)
  }

  get currentRouteName(): string {
    return this.isProjectRoute
      ? this.project.name
      : (this.$route.name as string)
  }

  RouteName = RouteName

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl
  readonly version = process.env.VUE_APP_VERSION_NBR || 'develop'

  drawerOpen = false
  showMenu = false
  showUpdatePasswordModal = false
  showUpdateProfileModal = false

  setShowUpdatePasswordModal(val: boolean): void {
    this.showUpdatePasswordModal = val
  }

  setShowUpdateProfileModal(val: boolean): void {
    this.showUpdateProfileModal = val
  }

  readonly actions: NavBarProfileItem[] = [
    {
      action: (): void => {
        logout()
      },
      id: 'disconnectButton',
      title: 'logout',
    },
    {
      action: (): void => this.setShowUpdatePasswordModal(true),
      id: 'changeMdpButton',
      title: 'user_update_password',
    },
    {
      action: (): void => this.setShowUpdateProfileModal(true),
      id: 'profileButton',
      title: 'user_profile',
    },
    // { action: () => this.showNotificationPreferences = true, id: "NotificationPreferencesButton", title: "user_notifications_preferences" },
    {
      action: (): void => goToSupport(),
      id: 'supportButton',
      title: 'support',
    },
    {
      action: (): void => console.log(this.version),
      id: 'versionButton',
      title: `Version: ${this.version}`,
    },
  ]

  goToDashboard(): void {
    if (this.currentRouteName !== RouteName.DASHBOARD) {
      this.RESET_HAS_ENTERED()
      this.$router.push({ name: RouteName.DASHBOARD })
    }
  }

  onAddClick(): void {
    this.$emit('addClick')
  }

  @Watch('closeDrawer')
  onCloseDrawerChange(): void {
    this.drawerOpen = false
  }

  @Watch('drawerOpen')
  onDrawerOpenChange(val: boolean): void {
    this.$emit('drawerToggled', val)
  }
}
