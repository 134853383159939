
import moment from 'moment'
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  Address,
  CreateProjectPayload,
  DashboardProject,
  Project,
} from '@/services/bimoboxApiService/types'
import { AlertWithData } from '@/types/global'

import {
  getAlertModule,
  getDashboardModule,
  getProjectModule,
} from '@/store/utils'
import { colors, getFormattedDate } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import SetAddressCard from '@/components/dashboard/set.address.card.vue'

const components = { BaseModal, FormActions, SetAddressCard }

const Store = {
  ...getAlertModule(),
  ...getDashboardModule(),
  ...getProjectModule(),
}

@Component({ name: 'create-project-modal', components })
export default class CreateProjectModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<Project>, default: null })
  readonly project: Project | null

  @Store.ProjectModule.State
  readonly address: Address | null

  readonly colors = colors

  readonly rules = {
    required: (value: string): string | boolean =>
      value !== undefined || (this.$t('rule_required_error') as string),
  }

  dueDate = {
    show: false,
    value: '',
  }

  loading = false
  stepperValue = 1

  formData = {
    name: '',
    description: '',
  }

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS: (message: string) => void

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.DashboardModule.Action
  readonly createProject: (
    data?: CreateProjectPayload
  ) => Promise<DashboardProject>

  @Store.ProjectModule.Action
  readonly updateProject: (data?: CreateProjectPayload) => Promise<Project>

  created(): void {
    this.clearData()
  }

  get primaryActionText(): string {
    if (this.stepperValue === 1) {
      return 'btn_next'
    }

    return 'btn_save'
  }

  get secondaryActionText(): string {
    if (this.stepperValue === 1) {
      return 'btn_cancel'
    }

    return 'btn_back'
  }

  primaryAction(): void {
    if (this.stepperValue === 1) {
      this.stepperValue++
    } else {
      this.callCreateProject()
    }
  }

  secondaryAction(): void {
    if (this.stepperValue === 2) {
      this.stepperValue--
      this.isAddressCardLoading = false
    } else {
      this.show = false
    }
  }

  isAddressCardLoading = false
  onAddressCardLoading(value: boolean): void {
    this.isAddressCardLoading = value
  }

  get isFormComplete(): boolean {
    if (this.stepperValue === 1) {
      return this.formData.name.length > 0 && this.dueDate.value.length > 0
    } else {
      return !this.isAddressCardLoading
    }
  }

  fetchedAddress: Address | null = null

  error = ''
  async callCreateProject(): Promise<void> {
    this.loading = true
    const payload: CreateProjectPayload = {
      name: this.formData.name,
      description: this.formData.description,
      dueDate: moment(this.dueDate.value).unix(),
      address: this.fetchedAddress === null ? undefined : this.fetchedAddress,
    }

    try {
      if (this.project) {
        await this.updateProject(payload)
        this.ALERT_SUCCESS('project_update_success')
      } else {
        const project: DashboardProject = await this.createProject(payload)
        this.ALERT_SUCCESS_WITH_DATA({
          message: 'project_create_success',
          data: { name: project.name },
        })
      }

      this.show = false
    } catch (error) {
      this.error = error as string
      this.clearData()
    }
  }

  clearData(): void {
    this.stepperValue = 1
    this.loading = false
    this.formData.name = this.project?.name || ''
    this.dueDate.value = this.project
      ? getFormattedDate(this.project.dueDate)
      : ''
    this.dueDate.show = false
    this.formData.description = this.project?.description || ''
    this.isAddressCardLoading = false
    this.fetchedAddress = this.project ? this.address : null
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
    this.error = ''
    this.clearData()
  }
}
