import { v4 as uuid } from 'uuid'

import { BcfComponentsGetter } from './bcfComponents'

import { NamedBlob } from '@/services/bimoboxApiService/types'
import './main.css'
// https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/extensions/BoxSelection/BoxSelection.js

class BCFButton extends Autodesk.Viewing.Extension {
  private subToolbar: Autodesk.Viewing.UI.ControlGroup | null = null
  private componentGetter: BcfComponentsGetter

  constructor(viewer: Autodesk.Viewing.GuiViewer3D, opts: any) {
    super(viewer, opts)

    this.componentGetter = new BcfComponentsGetter(viewer)
  }

  load(): boolean | Promise<boolean> {
    console.info(`The extension ${BCFButton.name} has been loaded`)

    return true
  }

  unload(): boolean {
    if (this.subToolbar) {
      this.viewer.toolbar.removeControl(this.subToolbar)
    }

    console.info(`The extension ${BCFButton.name} has been unloaded`)

    return true
  }

  onToolbarCreated(): void {
    const btn = new Autodesk.Viewing.UI.Button('bcfBtn')

    btn.addClass('BCFButton')
    btn.setToolTip('BCF')
    btn.onClick = (): void => {
      console.log(this.viewer.getState())
      this.fetchBcfData()
    }

    this.subToolbar = new Autodesk.Viewing.UI.ControlGroup(uuid())
    this.subToolbar.addControl(btn)
    this.viewer.toolbar.addControl(this.subToolbar)
  }

  private async fetchBcfData(): Promise<void> {
    await this.componentGetter.getComponents()
    this.getCamera()
    console.log(this.viewer.getCamera())
    await this.takeScreenshot()
  }

  private getCamera(): any {
    const camera = this.viewer.getCamera() as Autodesk.Viewing.UnifiedCamera
    return {
      type: camera.isPerspective ? 'perspective' : 'orthogonal',
      camera: {
        viewPoint: camera.position,
        direction: {
          x: camera.target.x,
          y: camera.target.y,
          z: camera.target.z,
        },
        upVector: camera.up,
        fieldOfView: camera.fov,
        aspectRatio: camera.aspect,
      },
    }
  }

  private takeScreenshot(): Promise<{ file: File; b64: string }> {
    return new Promise((resolve, reject) => {
      this.viewer.getScreenShot(1280, 720, (blobURL: string) => {
        console.log('Screenshot url:', blobURL)
        fetch(blobURL)
          .then((res) => res.blob())
          .then((blob: Blob) => {
            const nb = new NamedBlob('snapshot.png', blob)
            nb.downloadLocal()
            const file = new File([blob], 'snapshot.png', { type: blob.type })
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
              resolve({ file, b64: reader.result as string })
            }
          })
          .catch((err) => reject(err))
      })
    })
  }
}

Autodesk.Viewing.theExtensionManager.registerExtension('BCFButton', BCFButton)
