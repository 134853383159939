
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { PublicUser } from '@/services/bimoboxApiService/types'
import { colors, defaultAvatarUrl } from '@/utils/constants'

@Component({ name: 'guests-selector' })
export default class GuestsSelector extends Vue {
  @Prop({ required: true, type: Array })
  readonly value: string[]

  @Prop({ required: true, type: Array as PropType<PublicUser[]> })
  readonly items: PublicUser[]

  @Prop({ required: true })
  readonly label: string

  @Prop({ type: Boolean, default: false })
  readonly disabled: boolean

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl

  get selectedGuests(): string[] {
    return this.value
  }

  set selectedGuests(value: string[]) {
    this.$emit('input', value)
  }

  removeGuestAdded(id: string): void {
    this.selectedGuests = this.selectedGuests.filter((g) => g !== id)
  }
}
