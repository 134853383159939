
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { TeamRightGroups } from '@/services/bimoboxApiService/types'

import { colors } from '@/utils/constants'

@Component({ name: 'rights-cards' })
export default class RightsCards extends Vue {
  @Prop({ required: true, type: Object as PropType<TeamRightGroups> })
  readonly rightGroups: TeamRightGroups

  @Prop({ type: Boolean, default: false })
  readonly update: boolean

  readonly colors = colors

  emitToggleRight(group: string, right: string): void {
    const value = !this.rightGroups[group][right]
    this.$emit('toggleRight', { group, right, value })
    if (right === 'access' && value === false) {
      const rightsKeys = Object.keys(this.rightGroups[group]).filter(
        (key) => this.rightGroups[group][key] === true
      )
      for (const rightKey of rightsKeys) {
        this.$emit('toggleRight', { group, right: rightKey, value })
      }

      return
    }

    const groupsWithAccess = ['trd', 'documents']

    if (
      value === true &&
      right !== 'access' &&
      groupsWithAccess.includes(group) &&
      this.rightGroups[group].access === false
    ) {
      this.$emit('toggleRight', { group, right: 'access', value: true })
    }
  }
}
