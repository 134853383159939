import { VisaObservationService } from '@/services/bimoboxApiService'
import {
  ICreateVisaObservationPayload,
  IVisaObservation,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class VisaObservationModule extends VuexModule {
  private readonly visaObservationService = new VisaObservationService()

  observations: IVisaObservation[] | null = null

  get getObservationById(): (id: string) => IVisaObservation | null {
    return (obsId: string): IVisaObservation | null => {
      const observation = this.observations?.find(({ id }) => id === obsId)
      return observation === undefined ? null : observation
    }
  }

  get hasUserSigned(): (userId: string, observationIds: string[]) => boolean {
    return (userId: string, observationIds: string[]): boolean => {
      if (this.observations === null) {
        return false
      }

      const obs = this.observations.filter(
        ({ id, user }) => observationIds.includes(id) && user.id === userId
      )

      return obs.length > 0
    }
  }

  @Mutation
  ADD_OBSERVATION(observations: IVisaObservation[]): void {
    if (!this.observations) {
      this.observations = []
    }

    this.observations = this.observations.filter(
      ({ id }) => !observations.map((o) => o.id).includes(id)
    )
    this.observations.push(...observations)
  }

  @Mutation
  RESET_OBSERVATION_STATES(): void {
    this.observations = null
  }

  @Mutation
  UPDATE_OBSERVATION(obs: IVisaObservation): void {
    if (!this.observations) {
      return
    }

    const index = this.observations.findIndex(({ id }) => id === obs.id)
    if (index > -1) {
      this.observations.splice(index, 1, obs)
    }
  }

  @Mutation
  DELETE_OBSERVATIONS_BY_IDS(ids: string[]): void {
    if (!this.observations) {
      return
    }

    this.observations = [
      ...this.observations.filter(({ id }) => !ids.includes(id)),
    ]
  }

  @Action({ rawError: true })
  async createObservation(data: {
    folderId: string
    documentId: string
    versionId: string
    visaId: string
    payload: ICreateVisaObservationPayload
  }): Promise<void> {
    try {
      const { visa, observations } =
        await this.visaObservationService.createObs(
          this.projectId,
          this.currentPhaseId,
          data.folderId,
          data.documentId,
          data.versionId,
          data.visaId,
          data.payload
        )
      this.context.commit('ADD_OBSERVATION', observations)
      this.context.commit('visa/UPDATE_VISA', visa, { root: true })
    } catch (error) {
      console.error('createObservation', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'UPDATE_OBSERVATION' })
  updateObservation(data: {
    folderId: string
    documentId: string
    versionId: string
    visaId: string
    observationId: string
    payload: ICreateVisaObservationPayload
  }): Promise<IVisaObservation> {
    try {
      return this.visaObservationService.updateObs(
        this.projectId,
        this.currentPhaseId,
        data.folderId,
        data.documentId,
        data.versionId,
        data.visaId,
        data.observationId,
        data.payload
      )
    } catch (error) {
      console.error('updateObservation', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'DELETE_OBSERVATIONS_BY_IDS' })
  async deleteObservation(data: {
    folderId: string
    documentId: string
    versionId: string
    visaId: string
    observationId: string
  }): Promise<string[]> {
    try {
      await this.visaObservationService.deleteObs(
        this.projectId,
        this.currentPhaseId,
        data.folderId,
        data.documentId,
        data.versionId,
        data.visaId,
        data.observationId
      )
    } catch (error) {
      console.error('deleteObservation', error)
      throw 'unknown_error'
    }

    this.context.commit(
      'visa/REMOVE_OBSERVATION_IN_VISA',
      { id: data.visaId, observationsIds: [data.observationId] },
      { root: true }
    )
    return [data.observationId]
  }

  private get projectId(): string {
    return this.context.rootState.project.project.id
  }

  private get currentPhaseId(): string {
    return this.context.rootState.phase.currentPhaseId
  }
}
