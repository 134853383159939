import { DocumentVersionService } from '@/services/bimoboxApiService'
import {
  ICreateDocumentVersionPayload,
  IDocument,
  IDocumentVersion,
  IFolder,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class DocumentVersionModule extends VuexModule {
  private readonly documentVersionService = new DocumentVersionService()

  versions: IDocumentVersion[] | null = null

  get getVersionById(): (versionId: string) => IDocumentVersion | null {
    return (versionId: string): IDocumentVersion | null => {
      const version = this.versions?.find(({ id }) => id === versionId)
      return version === undefined ? null : version
    }
  }

  get getVersionsByDocumentId(): (documentId: string) => IDocumentVersion[] {
    return (documentId: string): IDocumentVersion[] => {
      if (!this.versions) {
        return []
      }

      const document: IDocument | null =
        this.context.rootGetters['document/getDocumentById'](documentId)
      if (!document) {
        return []
      }

      const documentVersions = this.versions.filter(({ id }) =>
        document.versionsIds.includes(id)
      )
      documentVersions.sort((vA: IDocumentVersion, vB: IDocumentVersion) => {
        const a = vA.createdAt
        const b = vB.createdAt
        return a === b ? 0 : a < b ? 1 : -1
      })

      return documentVersions
    }
  }

  get getLastDocumentVersion(): (
    documentId: string
  ) => IDocumentVersion | null {
    return (documentId: string): IDocumentVersion | null => {
      const versions = this.context.getters.getVersionsByDocumentId(documentId)
      if (versions.length === 0) {
        return null
      }

      return versions[0]
    }
  }

  @Mutation
  ADD_VERSIONS(versions: IDocumentVersion[]): void {
    if (!this.versions) {
      this.versions = []
    }

    this.versions = this.versions.filter(
      ({ id }) => !versions.map((o) => o.id).includes(id)
    )
    this.versions.push(...versions)
  }

  @Mutation
  RESET_VERSIONS_STATES(): void {
    this.versions = null
  }

  @Mutation
  ADD_VERSION_VISA(data: { id: string; visaId: string }): void {
    if (!this.versions) {
      return
    }

    const index = this.versions.findIndex(({ id }) => id === data.id)
    if (index === -1) {
      return
    }

    const version = { ...this.versions[index] }
    version.visaId = data.visaId
    this.versions.splice(index, 1, version)
  }

  @Mutation
  DELETE_VERSION_VISA(data: { id: string; visaId: string }): void {
    if (!this.versions) {
      return
    }

    const index = this.versions.findIndex(({ id }) => id === data.id)
    if (index === -1) {
      return
    }

    const version = { ...this.versions[index] }
    version.visaId = null
    this.versions.splice(index, 1, version)
  }

  @Mutation
  ADD_VERSION_ADVICE(data: { id: string; adviceId: string }): void {
    if (!this.versions) {
      return
    }

    const index = this.versions.findIndex(({ id }) => id === data.id)
    if (index > -1) {
      const version = { ...this.versions[index] }
      version.adviceId = data.adviceId
      this.versions.splice(index, 1, version)
    }
  }

  @Mutation
  DELETE_VERSIONS_BY_IDS(ids: string[]): void {
    if (!this.versions) {
      return
    }

    this.versions = [...this.versions.filter(({ id }) => !ids.includes(id))]
  }

  @Mutation
  UPDATE_FILE_STATUS(data: {
    fileId: string
    status: number
    urn: string
  }): void {
    if (!this.versions) {
      return
    }

    const index = this.versions.findIndex(
      ({ fileId }) => fileId === data.fileId
    )
    if (index > -1) {
      const version = { ...this.versions[index] }
      version.status = data.status
      version.urn = data.urn
      this.versions.splice(index, 1, version)
    }
  }

  @Action({ rawError: true })
  async createVersion(data: {
    documentId: string
    payload: ICreateDocumentVersionPayload
  }): Promise<void> {
    const folder: IFolder | null = this.context.rootGetters[
      'folder/getParentByChildId'
    ](data.documentId)
    if (!folder) {
      throw 'unknown_error'
    }

    try {
      const { version } = await this.documentVersionService.createVersion(
        this.projectId,
        this.currentPhaseId,
        folder.id,
        data.documentId,
        data.payload
      )
      this.context.commit('ADD_VERSIONS', [version])
      this.context.commit(
        'document/ADD_DOCUMENT_VERSION',
        { id: data.documentId, versionId: version.id },
        { root: true }
      )
    } catch (error) {
      console.error('createVersion', error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true })
  deleteVersionsByIds(ids: string[]): void {
    if (!this.versions) {
      return
    }

    const versionsToDelete = this.versions.filter(({ id }) => ids.includes(id))
    const visasToDelete = versionsToDelete
      .map(({ visaId }) => visaId)
      .filter((id) => id !== null)

    const advicesToDelete = versionsToDelete
      .map(({ adviceId }) => adviceId)
      .filter((id) => id !== null)

    this.context.dispatch('visaObservation/deleteVisasByIds', visasToDelete, {
      root: true,
    })

    this.context.commit('advices/DELETE_ADVICES_BY_IDS', advicesToDelete, {
      root: true,
    })
  }

  private get projectId(): string {
    return this.context.rootState.project.project.id
  }

  private get currentPhaseId(): string {
    return this.context.rootState.phase.currentPhaseId
  }
}
