
import {
  VisaExplicitStatus,
  visaExplicitStatusToColor,
  visaExplicitStatusToIcon,
} from '@/utils/visa.utils'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PropType } from 'vue'

@Component({ name: 'visa-choice-card' })
export default class VisaChoiceCard extends Vue {
  @Prop({ type: String as PropType<VisaExplicitStatus>, required: true })
  readonly status: VisaExplicitStatus

  get color(): string {
    return visaExplicitStatusToColor(this.status)
  }

  get icon(): string {
    return visaExplicitStatusToIcon(this.status, true)
  }

  get text(): string {
    return this.statusChoiceToText(this.status)
  }

  statusChoiceToText(status: VisaExplicitStatus): string {
    switch (status) {
      case VisaExplicitStatus.OUT_OF_MISSION:
        return this.$t('visa_status_out_of_mission') as string
      case VisaExplicitStatus.REJECTED:
        return this.$t('visa_select_status_rejected') as string
      case VisaExplicitStatus.SUSPENDEND:
        return this.$t('visa_select_status_suspended') as string
      case VisaExplicitStatus.ACCEPTED_W_OBS:
        return this.$t('visa_select_status_accepted_with_comment') as string
      case VisaExplicitStatus.ACCEPTED:
        return this.$t('visa_select_staus_accepted') as string
      default:
        return ''
    }
  }
}
