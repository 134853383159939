
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { ProjectGuest } from '@/services/bimoboxApiService/types'
import { AlertWithData, I18NText } from '@/types/global'

import { getAlertModule, getGuestModule } from '@/store/utils'

import ConfirmationModal from '@/components/common/modals/confirmation.modal.vue'

const components = {
  ConfirmationModal,
}

const Store = {
  ...getAlertModule(),
  ...getGuestModule(),
}

@Component({ name: 'update-guest-modal', components })
export default class DeleteGuestModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<ProjectGuest>, required: true })
  readonly guest: ProjectGuest

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.GuestModule.Action
  readonly kickGuests: (ids: string[]) => Promise<void>

  loading = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.loading = false
    this.$emit('input', value)
  }

  get confirmationModalText(): I18NText {
    if (this.guest && this.show) {
      return {
        key: 'guest_delete_confirmation',
        data: { name: this.guest.user.displayName },
      }
    }

    return { key: '' }
  }

  async action(): Promise<void> {
    this.loading = true
    try {
      await this.kickGuests([this.guest.user.id])
      const alertData: AlertWithData = {
        message: 'guest_delete_success',
        data: { name: this.guest.user.displayName },
      }
      this.ALERT_SUCCESS_WITH_DATA(alertData)
    } catch (error) {
      this.ALERT_ERROR(error as string)
    }

    this.show = false
  }
}
