
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  IBasicPhase,
  IDocument,
  IDocumentVersion,
  IFolder,
  Project,
} from '@/services/bimoboxApiService/types'

import {
  getAlertModule,
  getDocumentModule,
  getDocumentVersionModule,
  getFolderModule,
  getPhaseModule,
  getProjectModule,
} from '@/store/utils'
import { colors } from '@/utils/constants'

import { FileService, PhaseService } from '@/services/bimoboxApiService'

import Action from '@/components/common/actions/action.vue'

const components = {
  Action,
}

const Store = {
  ...getDocumentModule(),
  ...getAlertModule(),
  ...getFolderModule(),
  ...getProjectModule(),
  ...getPhaseModule(),
  ...getDocumentVersionModule(),
}

@Component({ name: 'download-documents-snackbar', components })
export default class DownloadDocumentsSnackbar extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Array as PropType<string[]>, default: [] })
  readonly selection: string[]

  @Store.DocumentModule.Getter
  readonly getDocumentById: (id: string) => IDocument | null

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder | null

  @Store.FolderModule.Getter
  readonly getFolderById: (folderId: string) => IFolder | null

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase

  @Store.ProjectModule.State
  readonly project: Project

  @Store.DocumentVersionModule.Getter
  readonly getVersionsByDocumentId: (documentId: string) => IDocumentVersion[]

  readonly colors = colors
  readonly fileService = new FileService()
  readonly phaseService = new PhaseService()
  loading = false

  get selectedComputedFiles(): IDocument[] {
    return this.selection
      .map((id) => this.getDocumentById(id))
      .filter((d) => d !== null) as IDocument[]
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    if (!value) {
      this.$emit('update:selection', [])
    }

    this.$emit('input', value)
  }

  close(): void {
    if (!this.loading) {
      this.show = false
    }
  }

  async downloadFiles(): Promise<void> {
    this.loading = true
    try {
      await this.downloadFilesOneByOne()

      this.loading = false
    } catch (error) {
      console.error(error)
      this.ALERT_ERROR('unknown_error')
      this.loading = false
    }
    this.close()
  }

  async downloadFilesOneByOne(): Promise<void> {
    const namedBobs = await Promise.all(
      this.selectedComputedFiles.map((d) =>
        this.fileService.downloadFile(
          this.getCurrentVersionFileId(d.id),
          d.name
        )
      )
    )

    namedBobs.forEach((b) => b.downloadLocal())
  }

  getCurrentVersionFileId(id: string): string {
    return this.getVersionsByDocumentId(id)[0].fileId
  }
}
