
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IBasicPhase, PhaseGroup } from '@/services/bimoboxApiService/types'
import { getPhaseModule } from '@/store/utils'
import {
  capitalizeFirstChar,
  colors,
  getIconForPhaseGroup,
} from '@/utils/constants'

const Store = getPhaseModule()

interface IPhaseSelectorMenu {
  group: PhaseGroup
  icon: string
  phases: IBasicPhase[]
  active: boolean
}

@Component({ name: 'phase-selector' })
export default class PhaseSelector extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly dark: boolean

  @Prop({ type: Boolean, default: false })
  readonly small: boolean

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase | null

  @Store.PhaseModule.Getter
  readonly userPhases: IBasicPhase[]

  readonly getIconForPhaseGroup = getIconForPhaseGroup
  readonly colors = colors

  showMenu = false

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get menuItems(): IPhaseSelectorMenu[] {
    return [
      this.phaseToIPhaseSelectorMenu(PhaseGroup.CONCEPTION),
      this.phaseToIPhaseSelectorMenu(PhaseGroup.CONSTRUCTION),
      this.phaseToIPhaseSelectorMenu(PhaseGroup.EXPLOITATION),
    ]
  }

  private phaseToIPhaseSelectorMenu(group: PhaseGroup): IPhaseSelectorMenu {
    const phases = this.userPhases.filter((phase) => phase.group === group)
    phases.sort((a, b) => (a.name === b.name ? 0 : a.name > b.name ? 1 : -1))
    return {
      group: capitalizeFirstChar(group) as PhaseGroup,
      icon: this.getIconForPhaseGroup(group),
      phases,
      active: this.currentPhase?.group === group,
    }
  }

  updateCurrentPhase(id: string): void {
    if (this.currentPhase?.id !== id) {
      const query = { ...this.$route.query, phase: id }
      this.$router.replace({ query })
    }

    this.showMenu = false
  }

  get buttonText(): string {
    if (!this.currentPhase) {
      return this.$t('no_phase') as string
    }

    const groupName = this.isMobile ? '' : `${this.currentPhase.group} / `
    return this.isMobile || this.small
      ? this.currentPhase.name
      : groupName + this.currentPhase.name
  }
}
