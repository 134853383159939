
import store from '@/store'
import { Component, Vue } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import { AuthFormAction, AuthFormAlert } from '@/types/global'

import { colors, RouteName } from '@/utils/constants'

import * as firebaseService from '@/services/firebase.service'

import AuthForm from '@/components/AuthForm.vue'
import Recaptcha from '@/components/Recaptcha.vue'

const components = { AuthForm, Recaptcha }

Component.registerHooks(['beforeRouteEnter'])
@Component({ name: 'activation', components })
export default class Activation extends Vue {
  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location) => void
  ): Promise<void> {
    store.commit('user/SET_USER', null)

    return next()
  }

  readonly colors = colors
  loading = false
  authAlert: AuthFormAlert | null = null

  readonly rightAction: AuthFormAction = {
    text: 'register',
    route: RouteName.REGISTER,
  }

  readonly leftAction: AuthFormAction = {
    text: 'login',
    route: RouteName.LOGIN,
  }

  created(): void {
    this.callActivate()
  }

  async callActivate(): Promise<void> {
    this.loading = true
    this.authAlert = null

    try {
      await firebaseService.verifyEmail(this.$route.params.hash)
      this.authAlert = {
        type: 'success',
        text: 'confirm_account_success',
      }
    } catch (error) {
      this.authAlert = {
        type: 'error',
        text: error as string,
      }
    }

    this.loading = false
  }
}
