import { ForgeService } from '@/services/bimoboxApiService/forge/forge.service'
import store from '@/store'

import '@/utils/forgeExtensions/BCFButton/main'

const alertError = (message: string): void => {
  store.commit('alert/ALERT_ERROR', message)
}

export default class ViewerService {
  private viewer: Autodesk.Viewing.GuiViewer3D | null = null
  private loadedModels: Autodesk.Viewing.Model[] = []

  constructor(private isMobile = false, private enableScreenshot = false) {}

  setIsMobile(value: boolean): void {
    this.isMobile = value
  }

  setEnableScreenshot(value: boolean): void {
    this.enableScreenshot = value
  }

  initViewer(): Promise<void> {
    const options = {
      env: 'AutodeskProduction',
      getAccessToken: this.getForgeToken,
      api: 'derivativeV2_EU',
    }

    const div = document.getElementById(
      this.isMobile ? 'carouselForgeViewer' : 'forgeViewer'
    ) as HTMLElement

    const config = {
      extensions: ['BCFButton'],
      // extensions: this.enableScreenshot ? ['BCFButton'] : ['BCFButton'],
    }

    this.viewer = new Autodesk.Viewing.GuiViewer3D(div, config)

    return new Promise((resolve) => {
      const onForgeInitialized = () => {
        this.viewer?.setReverseZoomDirection(true)
        this.viewer?.setModelUnits('m')
        this.viewer?.start()
        this.viewer?.localize()
        this.viewer?.loadExtension('Autodesk.BoxSelection')

        resolve()
      }

      Autodesk.Viewing.Initializer(options, onForgeInitialized)
    })
  }

  emptyModels(): void {
    for (const model of this.loadedModels) {
      this.viewer?.unloadModel(model)
    }
  }

  async loadModels(urns: string[]): Promise<void> {
    if (!this.viewer || urns.length === 0) {
      return
    }

    this.emptyModels()

    try {
      let globalOffset: THREE.Vector3 | undefined
      for (const urn of urns) {
        const model = await this.loadModel(urn, globalOffset)
        if (model) {
          this.loadedModels.push(model)

          if (!globalOffset) {
            globalOffset = model.getGlobalOffset()
          }
        }
      }
    } catch (e) {
      this.error('failed to load 3d files')
    }
  }

  private error(message: string): void {
    alertError(message)
    this.emptyModels()
  }

  private loadModel(
    urn: string,
    globalOffset?: THREE.Vector3
  ): Promise<Autodesk.Viewing.Model | undefined> {
    return new Promise((resolve, reject) => {
      if (this.viewer == null) {
        return resolve(undefined)
      }

      this.viewer.prefs.tag('ignore-producer')
      const successCallback = async (
        doc: Autodesk.Viewing.Document
      ): Promise<void> => {
        if (this.viewer == null) {
          return reject('viewer not initialized')
        }

        const defaultModel = doc.getRoot().getDefaultGeometry()
        try {
          const loadDocumentNodeOpt = {
            keepCurrentModels: true,
            globalOffset,
          }

          const model = await this.viewer.loadDocumentNode(
            doc,
            defaultModel,
            loadDocumentNodeOpt
          )

          resolve(model)
        } catch (e) {
          console.error('loadModel error: ', e)
          reject(e)
        }
      }

      const errorCallback = (
        errorCode: Autodesk.Viewing.ErrorCodes,
        errorMsg: string,
        // eslint-disable-next-line
        messages: any[]
      ): void => {
        console.error(errorMsg, messages)
        reject(messages)
      }

      Autodesk.Viewing.Document.load(
        `urn:${urn}`,
        successCallback,
        errorCallback
      )
    })
  }

  private async getForgeToken(
    onTokenReady: (token: string, expiresIn: number) => void
  ): Promise<void> {
    const projectId = store.state.project.project.id
    const phaseId = store.state.phase.currentPhaseId
    const forgeService = new ForgeService()
    const token = await forgeService.fetchToken(projectId, phaseId)
    onTokenReady(token.access_token, token.expires_in)
  }

  // private setCamera(): void {
  //   const measures: any = this.viewer.getExtension('Autodesk.Measure')
  //   // this.viewerApp?.setModelUnits('mm')
  //   const model = this.viewer.model
  //   let lengthScale
  //   const modelUnit: string = this.viewer.model.getUnitString()
  //   switch (modelUnit) {
  //     case 'm':
  //       lengthScale = 1000
  //       break
  //     case 'dm':
  //       lengthScale = 100
  //       break
  //     case 'cm':
  //       lengthScale = 10
  //       break;
  //     default:
  //       lengthScale = 1
  //       break
  //   }

  //   const eye = new THREE.Vector3(bcfCamera.viewPoint.x, bcfCamera.viewPoint.y, bcfCamera.viewPoint.z)
  //   const sightVec = new THREE.Vector3(bcfCamera.direction.x, bcfCamera.direction.y * lengthScale, bcfCamera.direction.z * lengthScale)
  //   const target = eye.clone().add(bcfCamera.direction)
  //   const offsetMatrix = model.getData().placementWithOffset;
  //   const offsetEye = eye.applyMatrix4(offsetMatrix);
  //   const offsetTarget = target.applyMatrix4(offsetMatrix);

  //   this.viewerApp?.navigation.setView(offsetEye, offsetTarget)
  //   this.viewerApp?.navigation.setCameraUpVector(bcfCamera.upVector)
  //   this.viewerApp?.navigation.setVerticalFov(bcfCamera.fov, true)
  // }
}
