import { PhaseService } from '@/services/bimoboxApiService'
import {
  IBasicPhase,
  ICreatePhasePayload,
  IFolder,
  IGetVisaReportQuery,
  ILockPhasePayload,
  IUpdatePhasePayload,
  NamedBlob,
  Team,
} from '@/services/bimoboxApiService/types'
import { ServiceBusServiceType } from '@/services/serviceBus.service'
import djs from 'dayjs'
import { CommitOptions } from 'vuex'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class PhaseModule extends VuexModule {
  private readonly phaseService = new PhaseService()

  phases: IBasicPhase[] | null = null

  currentPhaseId: string | null = null

  phasesFetched: string[] = []

  lastPhaseId: string | null

  get doesPhaseContainsDocuments(): boolean {
    if (!this.phases || !this.currentPhaseId) {
      return false
    }

    const currentPhaseFolders: IFolder[] =
      this.context.rootGetters['folder/currentPhaseFolders']
    if (currentPhaseFolders.length === 0) {
      return false
    }

    return !currentPhaseFolders.every(({ documents }) => documents.length === 0)
  }

  get currentPhase(): IBasicPhase | null {
    if (!this.phases || !this.currentPhaseId) {
      return null
    }

    const currentPhase = this.phases.find(
      ({ id }) => id === this.currentPhaseId
    )
    if (currentPhase === undefined) {
      return null
    }

    return currentPhase
  }

  get userPhases(): IBasicPhase[] {
    if (!this.phases) {
      return []
    }

    if (
      this.context.rootGetters['project/isCreator'] ||
      this.context.rootGetters['project/isAdmin']
    ) {
      return [...this.phases]
    }

    const userTeam: Team | null = this.context.rootGetters['team/userTeam']
    if (userTeam === null) {
      return []
    }

    return this.phases.filter(({ guestsIds }) =>
      guestsIds.includes(userTeam.id)
    )
  }

  @Mutation
  RESET_PHASE_STATES(all: boolean): void {
    this.phases = null
    this.currentPhaseId = null
    this.phasesFetched = []
    if (all) {
      this.lastPhaseId = null
    }
  }

  @Mutation
  private SET_LAST_PHASE_ID(val: string | null): void {
    this.lastPhaseId = val
  }

  @Mutation
  private SET_PHASES(val: IBasicPhase[]): void {
    this.phases = [...val]
  }

  @Mutation
  private ADD_PHASE(val: IBasicPhase) {
    const data: IBasicPhase[] = []
    if (this.phases) {
      data.push(...this.phases)
    }

    data.push(val)
    this.phases = [...data]
  }

  @Mutation
  private UPDATE_PHASE(val: IBasicPhase): void {
    if (!this.phases) {
      return
    }

    const phaseIndex = this.phases.findIndex(({ id }) => id === val.id)
    if (phaseIndex > -1) {
      this.phases.splice(phaseIndex, 1, val)
    }
  }

  @Mutation
  private DELETE_PHASES(ids: string[]): void {
    if (!this.phases) {
      return
    }

    for (const phaseId of ids) {
      const phaseIndex = this.phases.findIndex(({ id }) => id === phaseId)
      if (phaseIndex > -1) {
        this.phases.splice(phaseIndex, 1)
      }
    }
  }

  @Mutation
  SET_CURRENT_PHASE(id: string): void {
    this.currentPhaseId = id
  }

  @Mutation
  private ADD_TEAM_FETCHED(id: string): void {
    if (!this.phasesFetched.includes(id)) {
      this.phasesFetched.push(id)
    }
  }

  @Action({ rawError: true, commit: 'ADD_PHASE' })
  async createPhase(payload: ICreatePhasePayload): Promise<IBasicPhase> {
    try {
      const phase = await this.phaseService.createPhase(
        this.context.rootState.project.project.id,
        payload
      )
      await this.context.dispatch(
        'servicebus/addClient',
        { id: phase.id, type: ServiceBusServiceType.PHASE },
        { root: true }
      )
      return phase
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true })
  async getVisaReport(
    payload: { phase: IBasicPhase } & IGetVisaReportQuery
  ): Promise<NamedBlob> {
    const { phase, includeTRD, includeVisaLess } = payload
    const projectName = (this.context.rootState.project.project.name as string)
      .trim()
      .toUpperCase()
    const now = djs().format('DD-MM-YYYY')

    const fileName =
      `BIMOBOX_${projectName}_${phase.name}_SUIVI-VISA_${now}.xlsx`
        .split(' ')
        .join('-')
    try {
      return this.phaseService.getVisaReport(
        this.context.rootState.project.project.id,
        phase.id,
        fileName,
        { includeTRD, includeVisaLess }
      )
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'SET_PHASES' })
  async fetchPhases(): Promise<IBasicPhase[]> {
    try {
      return this.phaseService.getProjectPhases(
        this.context.rootState.project.project.id
      )
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'UPDATE_PHASE' })
  async updatePhase(data: {
    id: string
    payload: IUpdatePhasePayload
  }): Promise<IBasicPhase> {
    try {
      return this.phaseService.updatePhaseById(
        this.context.rootState.project.project.id,
        data.id,
        data.payload
      )
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'UPDATE_PHASE' })
  async lockPhase(data: {
    id: string
    payload: ILockPhasePayload
  }): Promise<IBasicPhase> {
    try {
      return this.phaseService.lockPhaseById(
        this.context.rootState.project.project.id,
        data.id,
        data.payload
      )
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'DELETE_PHASES' })
  async deletePhases(ids: string[]): Promise<string[]> {
    try {
      await Promise.all(
        ids.map((id) =>
          this.phaseService.deletePhaseById(
            this.context.rootState.project.project.id,
            id
          )
        )
      )
      await Promise.all(
        ids.map((id) =>
          this.context.dispatch('servicebus/removeClient', id, { root: true })
        )
      )
      return ids
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  @Action({ rawError: true, commit: 'ADD_TEAM_FETCHED' })
  async fetchPhase(id: string): Promise<string> {
    if (this.phasesFetched.includes(id)) {
      return id
    }

    const projectId = this.context.rootState.project.project.id
    const content = await this.phaseService
      .getPhaseContent(projectId, id)
      .catch((err) => {
        console.error(err)

        throw 'unknown_error'
      })

    const opts: CommitOptions = { root: true }
    this.context.commit(
      'visaObservation/ADD_OBSERVATION',
      content.observations,
      opts
    )

    this.context.commit('advice/ADD_ADVICES', content.advices, opts)
    this.context.commit('visa/ADD_VISAS', content.visas, opts)
    this.context.commit('documentVersion/ADD_VERSIONS', content.versions, opts)
    this.context.commit('document/ADD_DOCUMENTS', content.documents, opts)

    const folders = content.folders.map((folder) => ({ folder }))
    this.context.commit('folder/ADD_FOLDERS', folders, opts)
    this.context.dispatch(
      'servicebus/addClient',
      { id, type: ServiceBusServiceType.PHASE },
      opts
    )
    this.context.commit('SET_LAST_PHASE_ID', id)

    return id
  }
}
