
import { Component, Prop, Vue } from 'vue-property-decorator'
import { PropType } from 'vue'

import { IVisa, IVisaObservation } from '@/services/bimoboxApiService/types'

import {
  isVisaStatusTextColorDark,
  VisaExplicitStatus,
  visaExplicitStatusToColor,
  visaExplicitStatusToIcon,
  visaStatusToi18n,
} from '@/utils/visa.utils'

@Component({ name: 'visa-status-chip' })
export default class VisaStatusChip extends Vue {
  @Prop({ type: Array as PropType<IVisaObservation[]>, required: true })
  readonly observations: IVisaObservation[]

  @Prop({ type: Object as PropType<IVisa | null>, default: null })
  readonly visa: IVisa | null

  @Prop({ type: Boolean, default: false })
  readonly forceMinify: boolean

  get i18NVisaStatus(): VisaExplicitStatus {
    return visaStatusToi18n(
      this.observations,
      this.visa === null ? undefined : this.visa
    )
  }

  get color(): string {
    return visaExplicitStatusToColor(this.i18NVisaStatus)
  }

  get isDark(): boolean {
    return isVisaStatusTextColorDark(this.i18NVisaStatus)
  }

  get icon(): string {
    return visaExplicitStatusToIcon(this.i18NVisaStatus, this.forceMinify)
  }
}
