import moment from 'moment'

import {
  PhaseGroup,
  Team,
  TeamRightGroups,
} from '@/services/bimoboxApiService/types'

export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

export const colors = {
  orange: '#ff5722',
  darkGrey: '#21252b',
  grey: '#263238',
  GEDGrey: '#455a64',
}

export enum RouteName {
  PROJECT = 'Project',
  INFORMATIONS = 'Information',
  TRDVIEW = '3DView',
  DOCUMENTS = 'Documents',
  MESSENGER = 'Messenger',
  USERS = 'Users',
  TEAMS = 'Teams',
  LOGIN = 'login',
  REGISTER = 'register',
  ACTIVATION = 'activation',
  SEND_ACTIVATION = 'sendActivation',
  SEND_RESET_PASSWORD = 'sendResetPassword',
  RESET_PASSWORD = 'resetPassword',
  DASHBOARD = 'Dashboard',
  BCF = 'BCF',
  NEW_GUEST_ACTIVATION = 'newGuestActivation',
}

export const AuthRoutes: string[] = [
  RouteName.LOGIN,
  RouteName.REGISTER,
  RouteName.ACTIVATION,
  RouteName.SEND_ACTIVATION,
  RouteName.SEND_RESET_PASSWORD,
  RouteName.RESET_PASSWORD,
  RouteName.NEW_GUEST_ACTIVATION,
]

export const emptyRights = (toCopy?: Team | null): TeamRightGroups => {
  if (toCopy) {
    return {
      trd: { ...toCopy.trd },
      documents: { ...toCopy.documents },
      messenger: { ...toCopy.messenger },
      folders: { ...toCopy.folders },
      phase: { ...toCopy.phase },
      confidential: { ...toCopy.confidential },
    }
  }

  return {
    trd: {
      access: false,
      create: false,
      upd: false,
      del: false,
    },
    phase: {
      create: false,
      upd: false,
      del: false,
    },
    documents: {
      access: false,
      create: false,
      upd: false,
      del: false,
    },
    folders: {
      create: false,
      upd: false,
      del: false,
    },
    messenger: {
      create: false,
      upd: false,
      del: false,
    },
    confidential: {
      access: false,
      create: false,
      upd: false,
      del: false,
    },
  }
}

export const areRightsSame = (
  a: TeamRightGroups,
  b: TeamRightGroups
): boolean => {
  return (
    a.trd.access === b.trd.access &&
    a.trd.create === b.trd.create &&
    a.trd.upd === b.trd.upd &&
    a.trd.del === b.trd.del &&
    a.phase.create === b.phase.create &&
    a.phase.upd === b.phase.upd &&
    a.phase.del === b.phase.del &&
    a.documents.access === b.documents.access &&
    a.documents.create === b.documents.create &&
    a.documents.upd === b.documents.upd &&
    a.documents.del === b.documents.del &&
    a.folders.create === b.folders.create &&
    a.folders.upd === b.folders.upd &&
    a.folders.del === b.folders.del &&
    a.messenger.create === b.messenger.create &&
    a.messenger.upd === b.messenger.upd &&
    a.messenger.del === b.messenger.del &&
    a.confidential.access === b.confidential.access &&
    a.confidential.create === b.confidential.create &&
    a.confidential.upd === b.confidential.upd &&
    a.confidential.del === b.confidential.del
  )
}

export const goToSupport = (): void => {
  window.location.href =
    'https://bimobox.atlassian.net/servicedesk/customer/portal/4'
}

export const bimoboxVersion = process.env.VUE_APP_VERSION_NBR || 'develop'

export const getIconForPhaseGroup = (group: PhaseGroup): string => {
  switch (group) {
    case PhaseGroup.CONCEPTION:
      return 'mdi-head-lightbulb-outline'
    case PhaseGroup.CONSTRUCTION:
      return 'mdi-tools'
    case PhaseGroup.EXPLOITATION:
      return 'mdi-key-chain-variant'
  }
}

export const capitalizeFirstChar = (str: string): string =>
  str.charAt(0).toUpperCase() + str.substring(1)

export const getFormattedDate = (date: string | number): string => {
  return moment(date).local().format('DD/MM/YY')
}

export const defaultAvatarUrl = '/avatar_color_circle.png'
