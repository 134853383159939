
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  IPostTeamPayload,
  IUpdateTeamPayload,
  Project,
  PublicUser,
  Team,
  TeamRightGroups,
} from '@/services/bimoboxApiService/types'
import { AlertWithData, VuetifyDataTableHeader } from '@/types/global'

import { getAlertModule, getProjectModule, getTeamModule } from '@/store/utils'
import {
  areRightsSame,
  colors,
  defaultAvatarUrl,
  emptyRights,
} from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import RightsCards from '@/components/project/teams/RightsCards.vue'
import GuestsSelector from '@/components/project/GuestsSelector.vue'
import ResizableTable from '@/components/common/resizableTable.vue'
import TeamColorPicker from '@/components/project/teams/TeamColorPicker.vue'

const components = {
  BaseModal,
  FormActions,
  RightsCards,
  GuestsSelector,
  ResizableTable,
  TeamColorPicker,
}

const Store = {
  ...getAlertModule(),
  ...getTeamModule(),
  ...getProjectModule(),
}

@Component({ name: 'update-team-modal', components })
export default class UpdateTeamModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: Object as PropType<Team>, required: true })
  readonly teamToUpdate: Team

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.TeamModule.Action
  readonly updateTeam: (payload: IUpdateTeamPayload) => Promise<Team>

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.ProjectModule.State
  readonly project: Project

  get projectGuests(): PublicUser[] {
    const usersIdsInTeams: string[] = this.teams.flatMap(
      ({ membersIds }) => membersIds
    )

    const projectGuests = [
      ...this.project.guests
        .filter(({ isKicked }) => !isKicked)
        .map(({ user }) => user),
      this.project.creator,
    ]

    return projectGuests.filter(({ id }) => !usersIdsInTeams.includes(id))
  }

  membersToDelete: string[] = []

  mounted(): void {
    this.resetData()
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.error = ''
    this.resetData()

    this.$emit('input', value)
  }

  @Watch('teamToUpdate')
  onTeamToUpdateChange(): void {
    this.resetData()
  }

  resetData(): void {
    this.loading = false
    this.name = this.teamToUpdate.name
    this.color = this.teamToUpdate.color
    this.newMembersIds = []
    this.rights = emptyRights(this.teamToUpdate)
    this.stepperValue = 1
    this.membersToDelete = []
    this.members = [
      ...this.project.guests.map(({ user }) => user),
      this.project.creator,
    ].filter(({ id }) => this.teamToUpdate.membersIds.includes(id))
  }

  loading = false
  error = ''

  name = ''
  color = '#FF0000'
  newMembersIds: string[] = []
  rights: TeamRightGroups = emptyRights()
  members: PublicUser[] = []

  stepperValue = 1

  get isFormValid(): boolean {
    switch (this.stepperValue) {
      case 1:
        return this.name.length > 0 && this.color.length === 7
      case 2:
        return true
      case 3:
        return this.isTeamWellFilled
      default:
        return false
    }
  }

  get isTeamWellFilled(): boolean {
    const isTeamValid =
      this.name.length > 0 &&
      this.color.length === 7 &&
      !areRightsSame(this.rights, emptyRights())

    const isTeamUpdated =
      this.teamToUpdate.name !== this.name ||
      this.color !== this.teamToUpdate.color ||
      !areRightsSame(this.rights, emptyRights(this.teamToUpdate)) ||
      this.newMembersIds.length > 0 ||
      this.membersToDelete.length > 0

    return isTeamUpdated && isTeamValid
  }

  rightToggled(evt: { group: string; right: string; value: boolean }): void {
    this.rights[evt.group][evt.right] = evt.value
  }

  primaryAction(): void {
    switch (this.stepperValue) {
      case 1:
        this.stepperValue++
        return
      case 2:
        this.stepperValue++
        return
      case 3:
        this.callUpdateTeam()
        return
      default:
        return
    }
  }

  secondaryAction(): void {
    switch (this.stepperValue) {
      case 1:
        this.show = false
        return
      case 2:
        this.stepperValue--
        return
      case 3:
        this.stepperValue--
        return
      default:
        return
    }
  }

  get primaryActionText(): string {
    if (this.stepperValue === 3) {
      return 'btn_save'
    }

    return 'btn_next'
  }

  get secondaryActionText(): string {
    if (this.stepperValue === 1) {
      return 'btn_cancel'
    }

    return 'btn_back'
  }

  async callUpdateTeam(): Promise<void> {
    this.loading = true
    this.error = ''
    try {
      const payload: IPostTeamPayload = {
        name: this.name,
        membersIds: [
          ...this.newMembersIds,
          ...this.teamToUpdate.membersIds.filter(
            (id) => !this.membersToDelete.includes(id)
          ),
        ],
        color: this.color,
        ...this.rights,
      }

      const data: IUpdateTeamPayload = {
        id: this.teamToUpdate.id,
        data: payload,
      }

      await this.updateTeam(data)
      this.ALERT_SUCCESS_WITH_DATA({
        message: `team_update_success`,
        data: { name: this.teamToUpdate.name },
      })
      this.show = false
    } catch {
      this.error = 'unknown_error'
      this.resetData()
    }
  }

  updateMembersToDelete(id: string): void {
    if (this.membersToDelete.includes(id)) {
      this.membersToDelete = this.membersToDelete.filter(
        (memberId) => memberId !== id
      )
    } else {
      this.membersToDelete.push(id)
    }
  }

  get membersHeaderInfo(): VuetifyDataTableHeader[] {
    return [
      {
        value: 'avatar',
        align: 'start',
        filterable: false,
        sortable: false,
        width: '40',
      },
      {
        text: this.$t('name') as string,
        value: 'displayName',
        align: 'center',
      },
      {
        text: this.$t('actions') as string,
        value: 'actions',
        filterable: false,
        sortable: false,
        align: 'end',
      },
    ]
  }
}
