
import { Component, Prop, Vue } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import {
  ICreateVisaObservationPayload,
  VisaObservationStatus,
} from '@/services/bimoboxApiService/types'

const components = {
  BaseModal,
  FormActions,
}

@Component({ name: 'reject-visa-modal', components })
export default class RejectVisaModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  readonly colors = colors

  reason = ''

  get isFormValid(): boolean {
    return this.reason.trim().length > 0
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.reason = ''
    this.$emit('input', value)
  }

  visaCompleted(): void {
    const payload: ICreateVisaObservationPayload = {
      status: VisaObservationStatus.REJECTED,
      content: [{ text: this.reason, files: [] }],
    }

    this.$emit('visaCompleted', payload)
  }
}
