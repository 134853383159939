
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'team-color-picker' })
export default class TeamColorPicker extends Vue {
  @Prop({ type: String, required: true })
  readonly value: string

  @Prop({ type: Boolean, default: false })
  readonly disabled: string

  readonly choiceColor = [
    ['#FF0000', '#AA0000', '#550000'],
    ['#FFFF00', '#AAAA00', '#555500'],
    ['#00FF00', '#00AA00', '#005500'],
    ['#00FFFF', '#00AAAA', '#005555'],
  ]

  get selectedColor(): string {
    return this.value
  }

  set selectedColor(value: string) {
    this.$emit('input', value)
  }
}
