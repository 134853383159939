
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  ICreatePhasePayload,
  PhaseGroup,
  Team,
} from '@/services/bimoboxApiService/types'
import { AlertWithData } from '@/types/global'

import { getAlertModule, getPhaseModule, getTeamModule } from '@/store/utils'
import { colors, getIconForPhaseGroup } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import NumberInput from '@/components/common/NumberInput.vue'

const components = {
  BaseModal,
  FormActions,
  NumberInput,
}

const Store = {
  ...getTeamModule(),
  ...getPhaseModule(),
  ...getAlertModule(),
}

@Component({ name: 'create-phase-modal', components })
export default class CreatePhaseModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.PhaseModule.Action
  readonly createPhase: (payload: ICreatePhasePayload) => Promise<void>

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  readonly colors = colors
  readonly getIconForPhaseGroup = getIconForPhaseGroup

  selectedPhaseGroup: PhaseGroup | null = null

  phaseGroups: PhaseGroup[] = [
    PhaseGroup.CONCEPTION,
    PhaseGroup.CONSTRUCTION,
    PhaseGroup.EXPLOITATION,
  ]

  loading = false
  phaseName = ''
  visaDelay = 14
  visaEmailDelay = 3
  documentUpdateDelay = 14
  selectedTeams: string[] = []

  resetData(): void {
    this.loading = false
    this.selectedPhaseGroup = null
    this.selectedTeams = []
    this.visaDelay = 14
    this.visaEmailDelay = 3
    this.documentUpdateDelay = 14
    this.phaseName = ''
  }

  removeAddedTeam(id: string): void {
    this.selectedTeams = this.selectedTeams.filter((t) => t !== id)
  }

  get isFormValid(): boolean {
    return this.selectedPhaseGroup !== null && this.phaseName.length > 0
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xs
  }

  error = ''

  async primaryAction(): Promise<void> {
    this.error = ''
    this.loading = true
    try {
      await this.createPhase({
        name: this.phaseName,
        group: this.selectedPhaseGroup as PhaseGroup,
        guestsIds: this.selectedTeams,
        visaDelay: this.visaDelay,
        visaEmailDelay: this.visaEmailDelay,
        documentUpdateDelay: this.documentUpdateDelay,
      })

      this.ALERT_SUCCESS_WITH_DATA({
        message: 'phase_create_success',
        data: { name: this.phaseName },
      })

      this.show = false
    } catch {
      this.error = 'unknown_error'
      this.resetData()
    }
  }
}
