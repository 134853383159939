
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { WidgetInstance } from 'friendly-challenge'
import { LocalStorageService } from '@/services/localStorage.service'
import { I18NSupportedLanguage, isI18NSupportedLanguage } from '@/plugins/i18n'

@Component({ name: 'recaptcha' })
export default class Recaptcha extends Vue {
  @Prop({ type: String, required: true })
  readonly token: string | null

  @Prop({ type: Boolean, default: false })
  readonly resetCaptcha: boolean

  readonly localStorageService = new LocalStorageService()

  widget: WidgetInstance | null
  mounted(): void {
    if (this.widget) {
      return
    }

    this.widget = new WidgetInstance(this.$refs.captcha as HTMLElement, {
      sitekey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
      startMode: 'focus',
      language: this.locale,
      errorCallback: this.onTokenExpired.bind(this),
      doneCallback: this.updateToken.bind(this),
      solutionFieldName: 'frc-captcha-solution',
      puzzleEndpoint: process.env.VUE_APP_CAPTCHA_PUZZLE_ENDPOINT,
    })
  }

  get locale(): I18NSupportedLanguage {
    const storedLocale = this.localStorageService.getLocale()
    if (storedLocale != null) {
      return storedLocale
    }

    const browserLocale = navigator.language.split('-')[0]
    return isI18NSupportedLanguage(browserLocale) ? browserLocale : 'en'
  }

  destroy(): void {
    if (this.widget) {
      this.widget.destroy()
      this.$emit('update:resetCaptcha', false)
    }
  }

  @Watch('resetCaptcha')
  onResetCaptchaChange(val: boolean): void {
    if (!val) {
      return
    }

    this.widget?.reset()
    this.updateToken('')
  }

  onTokenExpired(): void {
    this.updateToken('')
  }

  updateToken(token: string): void {
    this.$emit('update:token', token)
  }
}
