
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  DBUser,
  FolderType,
  IBasicPhase,
  ICreateAdvicePayload,
  IDocument,
  IFolder,
  Team,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'
import { AlertWithData, VForm } from '@/types/global'

import {
  getAdviceModule,
  getAlertModule,
  getDocumentModule,
  getFolderModule,
  getPhaseModule,
  getTeamModule,
  getUserModule,
} from '@/store/utils'
import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'

const components = {
  BaseModal,
  FormActions,
}

const Store = {
  ...getAdviceModule(),
  ...getTeamModule(),
  ...getPhaseModule(),
  ...getAlertModule(),
  ...getFolderModule(),
  ...getDocumentModule(),
  ...getUserModule(),
}

@Component({ name: 'create-advice-modal', components })
export default class CreateVisaModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ required: true, type: String })
  readonly documentId: string

  @Prop({ required: true, type: String })
  readonly versionId: string

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.UserModule.State
  readonly user: DBUser

  @Store.AdviceModule.Action
  readonly createAdvice: (payload: ICreateAdvicePayload) => Promise<void>

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder

  @Store.DocumentModule.Getter
  readonly getDocumentById: (id: string) => IDocument

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  get currentDocument(): IDocument {
    return this.getDocumentById(this.documentId)
  }

  get currentPhaseTeams(): Team[] {
    const isTrd =
      this.getParentByChildId(this.documentId).type === FolderType.Trd
    return this.teams.filter((t) => {
      if (!this.currentPhase.guestsIds.includes(t.id)) {
        return false
      }

      if (t.membersIds.includes(this.user.id) || t.membersIds.length === 0) {
        return false
      }

      if (!t.documents.access) {
        return false
      }

      if (isTrd && !t.trd.access) {
        return false
      }

      return true
    })
  }

  readonly colors = colors

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  error = ''
  loading = false

  rules = {
    teamRequired: (value: string): string | boolean => {
      console.error(value)
      return value !== undefined || (this.$t('rule_required_error') as string)
    },
    required: (value: string): string | boolean =>
      value !== undefined || (this.$t('rule_required_error') as string),
  }

  selectedTeams: string[] = []
  adviceObject = ''
  removeSelectedTeam(id: string): void {
    this.selectedTeams = this.selectedTeams.filter((t) => t !== id)
  }

  get isFormValid(): boolean {
    return this.selectedTeams.length > 0 && this.adviceObject.length > 0
  }

  form(): VForm {
    return this.$refs.form as VForm
  }

  resetData(): void {
    this.loading = false
    this.selectedTeams = []
    this.adviceObject = ''
    this.form().resetValidation()
  }

  async primaryAction(): Promise<void> {
    this.loading = true

    const payload: ICreateAdvicePayload = {
      documentId: this.documentId,
      versionId: this.versionId,
      folderId: this.getParentByChildId(this.documentId).id,
      data: {
        object: this.adviceObject,
        signersTeams: this.selectedTeams,
      },
    }

    await this.createAdvice(payload).catch((err) => {
      this.error = err as string
      this.resetData()
    })

    this.ALERT_SUCCESS_WITH_DATA({
      message: 'visa_create_success',
      data: { name: this.currentDocument.name },
    })

    this.show = false
  }
}
