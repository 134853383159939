import { DashboardProject } from '@/services/bimoboxApiService/types'
import { ProjectTypeFilter, SortBy } from '@/types/global'

export const toNormalizedString = (str: string): string => {
  return str.normalize('NFD').replace(/\p{Diacritic}/gu, '')
}

export const getProjectsByFilter = (
  projects: DashboardProject[],
  filter: ProjectTypeFilter
): DashboardProject[] => {
  switch (filter) {
    case ProjectTypeFilter.ALL:
      return projects
    case ProjectTypeFilter.MY:
      return projects.filter((p) => p.isGuest === false)
    case ProjectTypeFilter.GUEST:
      return projects.filter((p) => p.isGuest === true)
  }
}

const searchProjects = (
  projects: DashboardProject[],
  search: string
): DashboardProject[] => {
  const normalizedSearch = toNormalizedString(search)
  return projects.filter((p) =>
    toNormalizedString(p.name).includes(normalizedSearch)
  )
}

const stringToSortable = (str: string): string => str.toLowerCase()

const sortProjects = (
  projects: DashboardProject[],
  sort: SortBy
): DashboardProject[] => {
  switch (sort) {
    case SortBy.ALPHA_ASC:
      return projects.sort((a: DashboardProject, b: DashboardProject) => {
        const A = stringToSortable(a.name)
        const B = stringToSortable(b.name)
        return A === B ? 0 : A > B ? 1 : -1
      })
    case SortBy.ALPHA_DESC:
      return projects.sort((a: DashboardProject, b: DashboardProject) => {
        const A = stringToSortable(a.name)
        const B = stringToSortable(b.name)
        return A === B ? 0 : A < B ? 1 : -1
      })
    case SortBy.DATE_ASC:
      return projects.sort((a: DashboardProject, b: DashboardProject) => {
        return a.createdAt === b.createdAt
          ? 0
          : a.createdAt < b.createdAt
          ? 1
          : -1
      })
    case SortBy.DATE_DESC:
      return projects.sort((a: DashboardProject, b: DashboardProject) => {
        return a.createdAt === b.createdAt
          ? 0
          : a.createdAt > b.createdAt
          ? 1
          : -1
      })
  }
}

export const filterProjects = (
  projects: DashboardProject[],
  filter: ProjectTypeFilter,
  search: string,
  sort: SortBy
): DashboardProject[] => {
  const filteredProjects = getProjectsByFilter(projects, filter)
  const searchedProjects =
    search === '' ? filteredProjects : searchProjects(filteredProjects, search)
  return sortProjects(searchedProjects, sort)
}
