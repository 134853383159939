
import CountryFlag from 'vue-country-flag'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { I18NSupportedLanguage, isI18NSupportedLanguage } from '@/plugins/i18n'
import { LocalStorageService } from '@/services/localStorage.service'
import { colors } from '@/utils/constants'

type Language = {
  flag: string
  title: string
  language: I18NSupportedLanguage
}

type I18NLocales = {
  [key in I18NSupportedLanguage]: Language
}

@Component({ name: 'locale', components: { CountryFlag } })
export default class Locale extends Vue {
  @Prop({ required: false, type: Boolean, default: false })
  readonly outlined: boolean

  readonly colors = colors
  readonly localStorageService = new LocalStorageService()

  created(): void {
    const browserLocale = navigator.language.split('-')[0]
    const currentLanguage = this.localStorageService.getLocale()
    const localeToSet: I18NSupportedLanguage =
      currentLanguage ??
      (isI18NSupportedLanguage(browserLocale) ? browserLocale : 'en')
    this.setLocale(localeToSet)
  }

  currentLocale: Language
  showMenu = false
  locales: I18NLocales = {
    fr: {
      flag: 'fr',
      title: 'Français',
      language: 'fr',
    },
    en: {
      flag: 'us',
      title: 'English',
      language: 'en',
    },
  }

  setLocale(locale: I18NSupportedLanguage): void {
    this.$i18n.locale = locale
    this.localStorageService.setLocale(locale)
    this.currentLocale = this.locales[locale]
  }
}
