import { apiBaseUrl } from '@/utils/constants'
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponseHeaders,
} from 'axios'
import { Endpoint } from './bimoboxApiService/endpoint.enum'
import { logout } from './firebase.service'

export class HttpService {
  protected readonly axiosInstance: AxiosInstance

  constructor(baseURL: string, headers?: AxiosRequestHeaders) {
    this.axiosInstance = axios.create({
      baseURL,
      headers,
      withCredentials: true,
    })

    this.axiosInstance.interceptors.response.use(
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (error: any): Promise<any> => {
        const config = error.config

        if (
          error?.response?.status !== 401 ||
          !config ||
          config._retry === true
        ) {
          return Promise.reject(error)
        }

        config._retry = true

        const uri = `${apiBaseUrl}/${Endpoint.AUTH}/token/refresh`

        try {
          await axios.post<void>(uri, {}, { withCredentials: true })
          return this.axiosInstance(config)
        } catch (error) {
          logout()
          return Promise.reject('unknown_error')
        }
      }
    )
  }

  static objectToQueryParamsString(obj: object): string {
    const qpString = Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join('&')

    return '?' + qpString
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setResponseErrorInterceptor(error?: (error: any) => Promise<any>): void {
    this.axiosInstance.interceptors.response.use(undefined, error)
  }

  async get<T>(endpoint: string, config?: AxiosRequestConfig): Promise<T> {
    const { data } = await this.axiosInstance.get<T>(endpoint, config)
    return data
  }

  async getWithHeader<T>(
    endpoint: string,
    config?: AxiosRequestConfig
  ): Promise<{
    data: T
    headers: AxiosResponseHeaders
  }> {
    const { data, headers } = await this.axiosInstance.get<T>(endpoint, config)
    return { data, headers }
  }

  async post<T>(
    endpoint: string,
    data?: object,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response = await this.axiosInstance.post<T>(endpoint, data, config)
    return response.data
  }

  async put<T>(
    endpoint: string,
    data?: object,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response = await this.axiosInstance.put<T>(endpoint, data, config)
    return response.data
  }

  async patch<T>(
    endpoint: string,
    data?: object,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response = await this.axiosInstance.patch<T>(endpoint, data, config)
    return response.data
  }

  async delete<T>(endpoint: string): Promise<T> {
    const response = await this.axiosInstance.delete<T>(endpoint)
    return response.data
  }
}
