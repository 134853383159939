
import { Component, Prop, Vue } from 'vue-property-decorator'

import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import {
  IAdvice,
  IEmitAdvicePayload,
  IFolder,
} from '@/services/bimoboxApiService/types'
import { PropType } from 'vue'
import { getAdviceModule, getAlertModule, getFolderModule } from '@/store/utils'

const components = {
  BaseModal,
  FormActions,
}

const Store = {
  ...getAlertModule(),
  ...getAdviceModule(),
  ...getFolderModule(),
}

@Component({ name: 'emit-advice-modal', components })
export default class EmitAdviceModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ required: true, type: Object as PropType<IAdvice> })
  readonly advice: IAdvice

  @Prop({ required: true, type: String })
  readonly versionId: string

  @Prop({ required: true, type: String })
  readonly documentId: string

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS: (message: string) => void

  @Store.AdviceModule.Action
  readonly emitAdvice: (payload: IEmitAdvicePayload) => Promise<void>

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder

  readonly colors = colors

  get folderId(): string {
    return this.getParentByChildId(this.documentId).id
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  error = ''
  adviceValue = ''
  loading = false

  get isFormValid(): boolean {
    return this.adviceValue.trim().length > 0
  }

  resetData() {
    this.adviceValue = ''
    this.loading = false
  }

  async primaryAction(): Promise<void> {
    this.loading = true

    const payload: IEmitAdvicePayload = {
      documentId: this.documentId,
      folderId: this.folderId,
      versionId: this.versionId,
      adviceId: this.advice.id,
      data: {
        advice: this.adviceValue,
      },
    }

    await this.emitAdvice(payload)
      .then(() => {
        this.show = false
      })
      .catch((err) => {
        this.error = err
        this.resetData()
      })
  }
}
