
import { VisaExplicitStatus } from '@/utils/visa.utils'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { colors } from '@/utils/constants'
import VisaChoiceCard from './visa.choice.card.vue'

@Component({ name: 'visa-choice-modal', components: { VisaChoiceCard } })
export default class VisaChoiceModal extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly value: boolean

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  readonly colors = colors

  readonly statuses = [
    VisaExplicitStatus.REJECTED,
    VisaExplicitStatus.ACCEPTED,
    VisaExplicitStatus.SUSPENDEND,
    VisaExplicitStatus.ACCEPTED_W_OBS,
    VisaExplicitStatus.OUT_OF_MISSION,
  ]

  getStatusCols(status: VisaExplicitStatus): number {
    const idx = this.statuses.findIndex((s) => s === status)
    if (idx === this.statuses.length - 1 && idx % 2 === 0) {
      return 12
    }

    return 6
  }

  emitSelection(status: VisaExplicitStatus): void {
    this.$emit('statusSelected', status)
  }
}
