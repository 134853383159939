
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  IAdvice,
  IDocument,
  IDocumentVersion,
  IVisa,
  IVisaObservation,
  PublicFile,
  Team,
} from '@/services/bimoboxApiService/types'

import {
  getAdviceModule,
  getAlertModule,
  getDocumentVersionModule,
  getTeamModule,
  getVisaModule,
  getVisaObservationModule,
} from '@/store/utils'
import { colors, defaultAvatarUrl, getFormattedDate } from '@/utils/constants'
import {
  imageExts,
  isFileExtensionContainedIn,
} from '@/utils/fileExtensionChecker'
import {
  isVisaStatusTextColorDark,
  VisaExplicitStatus,
  visaExplicitStatusToColor,
  visaStatusToi18n,
} from '@/utils/visa.utils'

import { FileService } from '@/services/bimoboxApiService'

import ExpandablePanel from '@/components/common/ExpansionPanel.vue'
import VisaUserObservations from '@/components/project/documents/documents/visa/visaUserObservations.vue'
import PDFViewerModal from '@/components/common/modals/pdfViewer.modal.vue'
import ImagePreviewModal from '@/components/common/modals/imagePreview.modal.vue'
import VisaStatusChip from '@/components/project/documents/documents/visa/visa.status.chip.vue'

const components = {
  PDFViewerModal,
  ImagePreviewModal,
  ExpandablePanel,
  VisaUserObservations,
  VisaStatusChip,
}

const Store = {
  ...getAlertModule(),
  ...getDocumentVersionModule(),
  ...getVisaModule(),
  ...getVisaObservationModule(),
  ...getTeamModule(),
  ...getAdviceModule(),
}

@Component({ name: 'document-version', components })
export default class DocumentVersion extends Vue {
  @Prop({ type: Object as PropType<IDocument>, required: true })
  readonly document: IDocument

  @Prop({ type: Object as PropType<IDocumentVersion>, required: true })
  readonly version: IDocumentVersion

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl
  getFormattedDate = getFormattedDate
  readonly fileService = new FileService()

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.AlertModule.Mutation
  readonly ALERT_ERROR: (message: string) => void

  @Store.DocumentVersionModule.Getter
  readonly getVersionsByDocumentId: (id: string) => IDocumentVersion[]

  @Store.VisaModule.Getter
  readonly getVisaById: (id: string) => IVisa | null

  @Store.AdviceModule.Getter
  readonly getAdviceById: (id: string) => IAdvice | null

  @Store.VisaObservationModule.Getter
  readonly getObservationById: (id: string) => IVisaObservation | null

  cancelExpansion = false
  expand = true
  headerExpansionClass = 'expandedHeader'

  downloadFileLoading = false
  showFileViewer = false

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get isPDF(): boolean {
    return isFileExtensionContainedIn(this.version.fileUrl, ['pdf'])
  }

  get isImage(): boolean {
    return isFileExtensionContainedIn(this.version.fileUrl, imageExts)
  }

  get fileToShow(): PublicFile {
    return {
      id: this.version.fileId,
      url: this.version.fileUrl,
      name: `${this.displayedIndex}_${this.document.name}`,
    }
  }

  get index(): number {
    const versions = this.getVersionsByDocumentId(this.document.id)
    versions.reverse()

    return versions.findIndex(({ id }) => id === this.version.id)
  }

  get visa(): IVisa | null {
    if (this.version.visaId === null) {
      return null
    }

    return this.getVisaById(this.version.visaId)
  }

  get observations(): IVisaObservation[] {
    if (this.visa === null) {
      return []
    }

    return this.visa.observationsIds
      .map((id) => this.getObservationById(id))
      .filter((o) => o !== null) as IVisaObservation[]
  }

  get i18NVisaStatus(): VisaExplicitStatus | '' {
    if (this.visa === null) {
      return ''
    }

    return visaStatusToi18n(this.observations, this.visa)
  }

  get statusColor(): string {
    if (this.i18NVisaStatus === '') {
      return ''
    }

    return visaExplicitStatusToColor(this.i18NVisaStatus)
  }

  get isStatusTextDark(): boolean {
    if (this.i18NVisaStatus === '') {
      return true
    }

    return isVisaStatusTextColorDark(this.i18NVisaStatus)
  }

  get visaTeams(): Team[] {
    if (!this.visa) {
      return []
    }

    return this.teams.filter(({ id }) =>
      this.visa?.signersTeamsIds.includes(id)
    )
  }

  get adviceTeams(): Team[] {
    if (!this.advice) {
      return []
    }

    return this.teams.filter(({ id }) =>
      this.advice?.signersTeamsIds.includes(id)
    )
  }

  get displayedIndex(): string {
    if (this.index === 0) {
      return '0'
    }

    let ret = ''
    const aNb = Math.floor(this.index / 26)
    const letter = this.index % 26
    if (aNb >= 1) {
      for (let i = 0; i < aNb; i++) {
        ret += 'A'
      }
    }

    ret += letter === 0 ? 'Z' : String.fromCharCode(64 + letter)
    return ret
  }

  get advice(): IAdvice | null {
    if (!this.version.adviceId) {
      return null
    }

    return this.getAdviceById(this.version.adviceId)
  }

  async downloadFile(): Promise<void> {
    this.downloadFileLoading = true
    try {
      const blob = await this.fileService.downloadFile(
        this.version.fileId,
        `${this.displayedIndex}_${this.document.name}`
      )
      blob.downloadLocal()
    } catch (error) {
      console.error(error)
      this.ALERT_ERROR('unknown_error')
    }

    this.downloadFileLoading = false
  }

  strToInitials(str: string): string {
    return str
      .split(' ')
      .map((s) => s[0])
      .join('')
      .toUpperCase()
  }
}
