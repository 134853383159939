
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  DBFile,
  FileUrl,
  UploadFilePayload,
} from '@/services/bimoboxApiService/types'
import { VPond } from '@/types/global'

import { getAlertModule, getProjectModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import { FileService } from '@/services/bimoboxApiService'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import FilePondHandler from '@/components/common/FilePondHandler.vue'
import ImagePlaceholder from '@/components/common/ImagePlaceholder.vue'

const components = {
  BaseModal,
  FormActions,
  FilePondHandler,
  ImagePlaceholder,
}

const Store = {
  ...getProjectModule(),
  ...getAlertModule(),
}

@Component({ name: 'update-avatars-modal', components })
export default class UpdateAvatarsModal extends Vue {
  @Prop({ type: Array as PropType<FileUrl[]>, required: true })
  readonly avatars: FileUrl[]

  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS: (message: string) => void

  @Store.ProjectModule.Action
  readonly updateProjectAvatars: (avatars: string[]) => Promise<FileUrl[]>

  readonly colors = colors
  readonly fileService = new FileService()

  avatarsToDelete: string[] = []

  toggleDeleteAvatar(id: string): void {
    if (this.avatarsToDelete.includes(id)) {
      this.avatarsToDelete = this.avatarsToDelete.filter(
        (avatarId) => avatarId !== id
      )
    } else {
      this.avatarsToDelete.push(id)
    }
  }

  isToggled(id: string): boolean {
    return this.avatarsToDelete.includes(id)
  }

  stepperValue = 1

  async uploadFile(
    file: File,
    onUploadProgress: (progressEvent) => void
  ): Promise<string> {
    const payload: UploadFilePayload = {
      file,
      onUploadProgress,
    }

    const res: DBFile = await this.fileService.uploadFile(payload)
    this.newAvatars.push(res.id)
    return res.id
  }

  error = ''
  loading = false

  get isFormValid(): boolean {
    if (this.stepperValue === 1) {
      return true
    }

    if (this.loading == true || this.isFilepondLoading === true) {
      return false
    }

    return this.avatarsToDelete.length > 0 || this.newAvatars.length > 0
  }

  get primaryActionText(): string {
    return this.stepperValue === 1 ? 'btn_next' : 'btn_save'
  }

  async primaryAction(): Promise<void> {
    if (this.stepperValue === 1) {
      this.stepperValue++
      return
    }

    if (this.isFilepondLoading) {
      return
    }

    this.error = ''
    this.loading = true
    const body = [
      ...this.newAvatars,
      ...this.avatars
        .filter(({ id }) => !this.avatarsToDelete.includes(id))
        .map(({ id }) => id),
    ]

    try {
      await this.updateProjectAvatars(body)
      this.ALERT_SUCCESS('project_avatars_update_success')
      this.newAvatars = []
      this.show = false
    } catch (error) {
      await this.deleteNewAvatars()
      this.loading = false
      this.resetData()
      this.error = error as string
    }
  }

  get secondaryActionText(): string {
    return this.stepperValue === 1 ? 'btn_cancel' : 'btn_back'
  }

  secondaryAction(): void {
    if (this.stepperValue === 2) {
      this.stepperValue--
    } else {
      this.show = false
    }
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.onShowChanged(value)
  }

  async onShowChanged(value: boolean): Promise<void> {
    this.error = ''
    if (value === false) {
      this.pond().removeFiles()
      if (this.newAvatars.length > 0) {
        await this.deleteNewAvatars()
      }
    }

    this.resetData()
    this.$emit('input', value)
  }

  pond(): VPond {
    return this.$refs.pond as VPond
  }

  resetData(): void {
    this.avatarsToDelete = []
    this.newAvatars = []
    this.stepperValue = 1
    this.loading = false
  }

  isFilepondLoading = false

  filepondToggled(state: boolean): void {
    this.isFilepondLoading = state
  }

  newAvatars: string[] = []

  fileDeleted(deletedId: string): void {
    this.newAvatars = this.newAvatars.filter((id) => id !== deletedId)
  }

  async deleteNewAvatars(): Promise<void> {
    this.loading = true
    await Promise.all(this.newAvatars.map(this.fileService.deleteFile))
    this.loading = false
  }
}
