
import { PropType } from 'vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'

import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'

vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginImageResize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageTransform,
  FilePondPluginImageCrop,
  FilePondPluginFileValidateSize
)

import { FileService } from '@/services/bimoboxApiService'
import { VPond } from '@/types/global'

type UploadFileCallback = (
  file: File,
  onUploadProgress: (progressEvent) => void
) => Promise<string>

@Component({ name: 'file-pond-handler' })
export default class FilePondHandler extends Vue {
  @Prop({ required: true, type: String })
  readonly label: string

  @Prop({ required: true, type: Function as PropType<UploadFileCallback> })
  readonly uploadFile: UploadFileCallback

  @Prop({ required: false, type: Boolean, default: false })
  readonly multiple: boolean

  @Prop({ required: false, type: String, default: '' })
  readonly acceptedTypes: string

  @Prop({ type: Boolean, default: false })
  readonly areCustomTypes: boolean

  @Prop({ required: false, type: Number, default: 3 })
  readonly maxSize: number

  @Prop({ required: false, type: Boolean, default: false })
  readonly autoCrop: boolean

  @Prop({ required: false, type: Boolean, default: false })
  readonly preview: boolean

  @Prop({ required: false, type: Number, default: null })
  readonly previewHeight?: boolean

  @Prop({ required: false, type: Boolean, default: false })
  readonly resize: boolean

  @Prop({ required: false, type: Number, default: null })
  readonly resizeWidth?: boolean

  @Prop({ required: false, type: Number, default: null })
  readonly resizeHeight?: boolean

  @Prop({ required: false, type: String, default: '' })
  readonly classes: string

  @Prop({ required: false, type: String, default: null })
  readonly panelLayout?: string

  @Prop({ required: false, type: String, default: 'right' })
  readonly panelLayoutProcessPosition: string

  @Prop({ required: false, type: String, default: 'left' })
  readonly panelLayoutRemovePosition: string

  @Prop({ required: false, type: String, default: 'right' })
  readonly panelLayoutLoadIndicatorPosition: string

  @Prop({ required: false, type: String, default: 'right' })
  readonly panelLayoutProgressIndicatorPosition: string

  @Prop({
    required: false,
    type: String,
    default: 'filepond_not_supported_error',
  })
  readonly processError: string

  @Prop({ required: false, type: String, default: '' })
  readonly notExpectedTypeError: string

  @Prop({
    required: false,
    type: String,
    default: 'filepond_not_supported_error',
  })
  readonly notAllowedTypeError: string

  @Prop({
    required: false,
    type: String,
    default: 'filepond_upload_success',
  })
  readonly processSuccess: string

  @Prop({ required: false, type: Boolean, default: false })
  readonly disabled: boolean

  private readonly fileService = new FileService()

  get acceptedFilesTypes(): string | undefined {
    if (this.areCustomTypes) {
      return undefined
    }

    if (this.acceptedTypes.length === 0) {
      return undefined
    }

    return this.acceptedTypes
  }

  toggle(state: boolean): void {
    this.$emit('toggle', state)
  }

  fileDeleted(id: string): void {
    this.$emit('fileDeleted', id)
  }

  uploading = false
  deleting = false

  @Watch('uploading')
  onUploadingChange(value: boolean): void {
    if (value === true && this.deleting === false) {
      this.toggle(true)
      return
    }

    if (value === false && this.deleting === false) {
      this.toggle(false)
    }
  }

  @Watch('deleting')
  onDeletingChange(value: boolean): void {
    if (value === true && this.uploading === false) {
      this.toggle(true)
      return
    }

    if (value === false && this.uploading === false) {
      this.toggle(false)
    }
  }

  async deleteFile(id: string): Promise<void> {
    await this.fileService.deleteFile(id)
    this.fileDeleted(id)
  }

  readonly server = {
    load: (uniqueFileId: string, load, error): void => error(),
    restore: (uniqueFileId: string, load, error): void => error(),
    revert: (uniqueFileId: string, load, error): void => {
      this.revert(uniqueFileId, load, error)
    },
    process: (
      fieldName: string,
      file: File,
      metadata,
      load: (requestId: string) => void,
      error: (error: string) => void,
      progress: (status: boolean, done: number, total: number) => void
    ): void => {
      this.process(file, load, error, progress)
    },
  }

  async typeValidation(file: File, type: string): Promise<string> {
    if (!this.areCustomTypes || this.acceptedTypes.length === 0) {
      return type
    }

    const typesAsArray = this.acceptedTypes.split(', ')
    const splitedFileName = file.name.split('.')
    const extension = splitedFileName[splitedFileName.length - 1].toLowerCase()

    if (typesAsArray.includes(type) || typesAsArray.includes(extension)) {
      return type
    }

    throw 'non supported file'
  }

  pond(): VPond {
    return this.$refs.pond as VPond
  }

  removeFiles(): void {
    this.pond().removeFiles()
  }

  async revert(
    id: string,
    load: (params?) => void,
    error: (error?) => void
  ): Promise<void> {
    try {
      await this.deleteFile(id)
      if (load) {
        load()
      }
    } catch (e) {
      if (error) {
        error(e)
      }
    }
  }

  async process(
    file: File,
    load: (params?) => void,
    error: (error?) => void,
    progress: (status: boolean, done: number, total: number) => void
  ): Promise<void> {
    const onUploadProgress = (progressEvent): void => {
      const totalLength = progressEvent.lengthComputable
        ? progressEvent.total
        : progressEvent.target.getResponseHeader('content-length') ||
          progressEvent.target.getResponseHeader(
            'x-decompressed-content-length'
          )

      progress(true, progressEvent.loaded, totalLength)
    }

    try {
      const id = await this.uploadFile(file, onUploadProgress)
      load(id)
    } catch (e) {
      error(e)
    }
  }
}
