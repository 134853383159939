
import {
  getPageLoaderModule,
  getProjectModule,
  getTeamModule,
  getUserModule,
} from '@/store/utils'
import { Component, Vue, Watch } from 'vue-property-decorator'

import {
  DBUser,
  Project,
  ProjectGuest,
  PublicUser,
  Team,
} from '@/services/bimoboxApiService/types'
import { VuetifyDataTableHeader } from '@/types/global'

import { colors, defaultAvatarUrl } from '@/utils/constants'

import store from '@/store'

import ResizableTable from '@/components/common/resizableTable.vue'
import TeamsUsersNavDrawer from '@/components/project/teams/TeamsUsersNavDrawer.vue'
import CreateGuestsModal from '@/components/project/users/create.guests.modal.vue'
import DeleteGuestModal from '@/components/project/users/delete.guest.modal.vue'
import ResendlinkModal from '@/components/project/users/resendlink.modal.vue'
import UpdateGuestModal from '@/components/project/users/update.guest.modal.vue'

const components = {
  ResizableTable,
  TeamsUsersNavDrawer,
  CreateGuestsModal,
  UpdateGuestModal,
  DeleteGuestModal,
  ResendlinkModal,
}

const Store = {
  ...getProjectModule(),
  ...getTeamModule(),
  ...getUserModule(),
  ...getPageLoaderModule(),
}

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate'])
@Component({ name: 'users', components })
export default class Users extends Vue {
  async beforeRouteEnter(to, from, next) {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    return next()
  }

  async beforeRouteUpdate(to, from, next) {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    return next()
  }

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl

  @Store.ProjectModule.State
  readonly project: Project

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.UserModule.State
  readonly user: DBUser

  @Store.ProjectModule.Getter
  readonly isCreator: boolean

  @Store.ProjectModule.Getter
  readonly isAdmin: boolean

  @Store.ProjectModule.Getter
  readonly isCreatorOrAdmin: boolean

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get smallMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  @Store.PageLoaderModule.Mutation
  readonly TOGGLE_PAGE_LOADING: (value: boolean) => void

  created(): void {
    this.TOGGLE_PAGE_LOADING(false)
  }

  get headerInfo(): VuetifyDataTableHeader[] {
    const header: VuetifyDataTableHeader[] = [
      {
        value: 'avatar',
        filterable: false,
        sortable: false,
        align: 'start',
        width: '40',
      },
      { text: this.$t('name') as string, value: 'name', align: 'start' },
      { text: this.$tc('team', 1) as string, value: 'team', align: 'start' },
      {
        text: this.$t('user_email') as string,
        value: 'email',
        align: 'start',
      },
      {
        text: this.$t('user_company') as string,
        value: 'company',
        align: 'start',
      },
      {
        text: this.$t('actions') as string,
        value: 'actions',
        align: 'start',
        filterable: false,
        sortable: false,
      },
    ]

    if (!this.isCreatorOrAdmin) {
      return header.filter(({ value }) => value !== 'actions')
    }

    return header
  }

  @Store.TeamModule.Getter
  readonly guestTeam: (id: string) => Team | null

  search = ''

  get guestsToInviteIds(): string[] {
    return this.users
      .filter(({ user }) => user.displayName === user.email)
      .map(({ user }) => user.id)
  }

  showCreateGuestsModal = false
  get users(): ProjectGuest[] {
    const items: ProjectGuest[] = [
      {
        isAdmin: true,
        isKicked: false,
        user: this.project.creator,
      },
      ...this.project.guests.filter(({ isKicked }) => !isKicked),
    ]

    if (this.search.length === 0) {
      return items
    }

    const lowSearch = this.search.toLowerCase()
    return items.filter(({ user }) => {
      const lowName = user.displayName.toLowerCase()
      const lowEmail = user.email.toLowerCase()
      return lowName.startsWith(lowSearch) || lowEmail.startsWith(lowSearch)
    })
  }

  isUserAdmin(userId: string): boolean {
    const guest = this.project.guests.find(({ user }) => user.id === userId)
    return guest?.isAdmin ?? false
  }

  isUserCreator(userId: string): boolean {
    return this.project.creator.id === userId
  }

  isUserGuest(userId: string): boolean {
    return !this.isUserAdmin(userId) && !this.isUserCreator(userId)
  }

  guestToEdit: ProjectGuest | null = null
  showUpdateGuestModal = false

  @Watch('showUpdateGuestModal')
  onShowUpdateGuestModalChange(val: boolean): void {
    if (!val) {
      this.guestToEdit = null
    }
  }

  updateGuest(guest: ProjectGuest): void {
    this.guestToEdit = guest
    this.showUpdateGuestModal = true
  }

  guestToDelete: ProjectGuest | null = null
  showDeleteGuestConfirmationModal = false

  @Watch('showDeleteGuestConfirmationModal')
  onShowDeleteGuestConfirmationModalChange(val: boolean): void {
    if (!val) {
      this.guestToDelete = null
    }
  }

  deleteGuest(guest: ProjectGuest): void {
    this.guestToDelete = guest
    this.showDeleteGuestConfirmationModal = true
  }

  guestToSendLink: PublicUser | null = null
  showSendLinkConfirmationModal = false
  async sendLink(guest: PublicUser): Promise<void> {
    this.guestToSendLink = guest
    this.showSendLinkConfirmationModal = true
  }
}
