
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  IPostTeamPayload,
  Project,
  PublicUser,
  Team,
  TeamRightGroups,
} from '@/services/bimoboxApiService/types'
import { AlertWithData } from '@/types/global'

import { getAlertModule, getProjectModule, getTeamModule } from '@/store/utils'
import { areRightsSame, colors, emptyRights } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import RightsCards from '@/components/project/teams/RightsCards.vue'
import GuestsSelector from '@/components/project/GuestsSelector.vue'
import TeamColorPicker from '@/components/project/teams/TeamColorPicker.vue'

const components = {
  BaseModal,
  FormActions,
  RightsCards,
  GuestsSelector,
  TeamColorPicker,
}

const Store = {
  ...getAlertModule(),
  ...getTeamModule(),
  ...getProjectModule(),
}

@Component({ name: 'create-team-modal', components })
export default class CreateTeamModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  readonly colors = colors

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.TeamModule.Action
  readonly createTeam: (payload: IPostTeamPayload) => Promise<Team>

  @Store.TeamModule.State
  readonly teams: Team[]

  @Store.ProjectModule.State
  readonly project: Project

  get projectGuests(): PublicUser[] {
    const usersIdsInTeams: string[] = this.teams.flatMap(
      ({ membersIds }) => membersIds
    )

    const projectGuests = [
      ...this.project.guests
        .filter(({ isKicked }) => !isKicked)
        .map(({ user }) => user),
      this.project.creator,
    ]

    return projectGuests.filter(({ id }) => !usersIdsInTeams.includes(id))
  }

  mounted(): void {
    this.resetData()
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.error = ''
    this.resetData()

    this.$emit('input', value)
  }

  resetData(): void {
    this.loading = false
    this.name = ''
    this.color = '#FF0000'
    this.membersIds = []
    this.rights = emptyRights()
    this.stepperValue = 1
  }

  loading = false
  error = ''

  name = ''
  color = '#FF0000'
  membersIds: string[] = []
  rights: TeamRightGroups = emptyRights()

  stepperValue = 1

  get isFormValid(): boolean {
    switch (this.stepperValue) {
      case 1:
        return this.name.length > 0 && this.color.length === 7
      case 2:
        return this.isTeamWellFilled
      default:
        return false
    }
  }

  get isTeamWellFilled(): boolean {
    return (
      this.name.length > 0 &&
      this.color.length === 7 &&
      !areRightsSame(this.rights, emptyRights())
    )
  }

  rightToggled(evt: { group: string; right: string; value: boolean }): void {
    this.rights[evt.group][evt.right] = evt.value
  }

  primaryAction(): void {
    switch (this.stepperValue) {
      case 1:
        this.stepperValue++
        return
      case 2:
        this.callAddTeam()
        return
      default:
        return
    }
  }

  secondaryAction(): void {
    switch (this.stepperValue) {
      case 1:
        this.show = false
        return
      case 2:
        this.stepperValue--
        return
      default:
        return
    }
  }

  get primaryActionText(): string {
    if (this.stepperValue === 2) {
      return 'btn_save'
    }

    return 'btn_next'
  }

  get secondaryActionText(): string {
    if (this.stepperValue === 1) {
      return 'btn_cancel'
    }

    return 'btn_back'
  }

  async callAddTeam(): Promise<void> {
    this.loading = true
    this.error = ''
    try {
      const payload: IPostTeamPayload = {
        name: this.name,
        membersIds: this.membersIds,
        color: this.color,
        ...this.rights,
      }
      await this.createTeam(payload)

      this.ALERT_SUCCESS_WITH_DATA({
        message: `team_create_success`,
        data: { name: this.name },
      })
      this.show = false
    } catch {
      this.error = 'unknown_error'
      this.resetData()
    }
  }
}
