import store from '@/store'
import {
  delay,
  isServiceBusError,
  ProcessErrorArgs,
  ServiceBusClient,
  ServiceBusReceivedMessage,
  ServiceBusReceiver,
} from '@azure/service-bus'

const connectionString = process.env.VUE_APP_SERVICE_BUS_CONNECTION_STRING
;('')

export enum ServiceBusServiceType {
  PHASE,
  MESSENGER,
}

const typeToQueueExtension = (type: ServiceBusServiceType): string => {
  switch (type) {
    case ServiceBusServiceType.MESSENGER:
      return '.msg'
    case ServiceBusServiceType.PHASE:
      return '.trd'
  }
}

export default class ServiceBusService {
  private readonly sbClient: ServiceBusClient
  private readonly receiver: ServiceBusReceiver

  constructor(readonly id: string, readonly type: ServiceBusServiceType) {
    this.sbClient = new ServiceBusClient(connectionString as string)
    this.receiver = this.sbClient.createReceiver(
      `${this.id}${typeToQueueExtension(this.type)}`
    )
  }

  startListening(): void {
    const subscription = this.receiver.subscribe({
      processMessage: async (brokeredMessage: ServiceBusReceivedMessage) => {
        switch (this.type) {
          case ServiceBusServiceType.PHASE:
            store.commit(
              'documentVersion/UPDATE_FILE_STATUS',
              JSON.parse(brokeredMessage.body)
            )
        }
      },
      processError: async (args: ProcessErrorArgs) => {
        console.error(
          `Error from source ${args.errorSource} occurred: `,
          args.error
        )
        if (isServiceBusError(args.error)) {
          switch (args.error.code) {
            case 'MessagingEntityDisabled':
            case 'MessagingEntityNotFound':
            case 'UnauthorizedAccess':
              console.error(
                `An unrecoverable error occurred. Stopping processing. ${args.error.code}`,
                args.error
              )
              await subscription.close()
              break
            case 'MessageLockLost':
              console.error(`Message lock lost for message`, args.error)
              break
            case 'ServiceBusy':
              await delay(1000)
              break
          }
        }
      },
    })
  }

  async stopListening(): Promise<void> {
    await this.receiver.close()
    await this.sbClient.close()
  }
}
