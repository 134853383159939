
import { Component, Vue } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import {
  IBasicPhase,
  IFolder,
  PhaseGroup,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'

import {
  getFolderModule,
  getPageLoaderModule,
  getPhaseModule,
  getTeamModule,
} from '@/store/utils'
import { RouteName } from '@/utils/constants'

import store from '@/store'

import DocumentsViewer from '@/components/project/documents/documents.viewer.vue'
import DocumentsHeader from '@/components/project/documents/DocumentsHeader.vue'

const components = {
  DocumentsHeader,
  DocumentsViewer,
}

const Store = {
  ...getFolderModule(),
  ...getPhaseModule(),
  ...getTeamModule(),
  ...getPageLoaderModule(),
}

const beforeRouteMethod = async (
  to: Route,
  from: Route,
  next: (location?: Location | Route) => void
): Promise<void> => {
  store.commit('pageLoader/TOGGLE_PAGE_LOADING', true)

  const defaultRoute: Location = {
    name: RouteName.INFORMATIONS,
    params: { id: to.params.id },
  }

  if (store.state.phase.phases === null) {
    try {
      await store.dispatch('phase/fetchPhases')
    } catch (e) {
      store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
      return next(defaultRoute)
    }
  }

  const phaseParams = to.query.phase as string | undefined
  const userPhases: IBasicPhase[] = store.getters['phase/userPhases']
  if (!phaseParams) {
    const lastPhaseId = store.state.phase.lastPhaseId
    if (lastPhaseId) {
      to.query.phase = lastPhaseId
      return next(to)
    }

    const getFirstPhaseByGroup = (g: PhaseGroup): string | null => {
      const phases = userPhases.filter(({ group }) => g === group)
      if (phases.length === 0) {
        return null
      }

      phases.sort((a: IBasicPhase, b: IBasicPhase) => {
        const aLow = a.name.toLocaleLowerCase()
        const bLow = b.name.toLocaleLowerCase()

        return aLow === bLow ? 0 : aLow > bLow ? -1 : 1
      })

      return phases[0].id
    }

    const conceptionId = getFirstPhaseByGroup(PhaseGroup.CONCEPTION)
    if (conceptionId) {
      to.query.phase = conceptionId
      return next(to)
    }

    const constructionId = getFirstPhaseByGroup(PhaseGroup.CONSTRUCTION)
    if (constructionId) {
      to.query.phase = constructionId
      return next(to)
    }

    const exploitationId = getFirstPhaseByGroup(PhaseGroup.EXPLOITATION)
    if (exploitationId) {
      to.query.phase = exploitationId
      return next(to)
    }

    return next()
  }

  const isParamInUserPhases = userPhases
    .map(({ id }) => id)
    .includes(phaseParams)
  if (!isParamInUserPhases) {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    return next(defaultRoute)
  }

  if (phaseParams !== store.getters['phase/userPhases']?.id) {
    store.commit('phase/SET_CURRENT_PHASE', phaseParams)
  }

  try {
    await store.dispatch('phase/fetchPhase', phaseParams)
  } catch {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    return next(defaultRoute)
  }

  store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
  return next()
}

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate'])
@Component({ name: 'documents', components })
export default class Documents extends Vue {
  beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location | Route) => void
  ): Promise<void> {
    return beforeRouteMethod(to, from, next)
  }

  beforeRouteUpdate(
    to: Route,
    from: Route,
    next: (location?: Location | Route) => void
  ): Promise<void> {
    return beforeRouteMethod(to, from, next)
  }

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase | null

  @Store.FolderModule.Getter
  readonly currentPhaseFolders: IFolder[]

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  @Store.PageLoaderModule.Mutation
  readonly TOGGLE_PAGE_LOADING: (value: boolean) => void

  mounted(): void {
    this.TOGGLE_PAGE_LOADING(false)
  }

  readonly viewerSelectedFiles: string[] = []

  get canCreatePhase(): boolean {
    return this.hasRight(TeamRightGroup.PHASE, TeamRight.CREATE)
  }

  get canCreateFolder(): boolean {
    return this.hasRight(TeamRightGroup.FOLDER, TeamRight.CREATE)
  }

  get isSmartphone(): boolean {
    return this.$vuetify.breakpoint.xs
  }

  search = ''

  isFileSelectionEnabled = false

  toggleFileSelection(val: boolean): void {
    this.isFileSelectionEnabled = val
  }
}
