import activeAdvice from './activeAdvice.json'
import activeVisa from './activeVisa.json'
import advice from './advice.json'
import auth from './auth.json'
import btn from './btn.json'
import dashboard from './dashboard.json'
import document from './document.json'
import filepond from './filepond.json'
import folder from './folder.json'
import guest from './guest.json'
import misc from './misc.json'
import phase from './phase.json'
import project from './project.json'
import route from './route.json'
import rule from './rule.json'
import team from './team.json'
import user from './user.json'
import visa from './visa.json'
import vuetify from './vuetify.json'

const fr = {
  ...advice,
  ...auth,
  ...btn,
  ...dashboard,
  ...document,
  ...filepond,
  ...folder,
  ...guest,
  ...misc,
  ...phase,
  ...project,
  ...route,
  ...rule,
  ...team,
  ...user,
  ...visa,
  ...vuetify,
  ...activeVisa,
  ...activeAdvice,
}

export default fr
