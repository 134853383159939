
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import {
  FolderType,
  ICreateFolderPayload,
  IFolder,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'
import { AlertWithData } from '@/types/global'

import { getAlertModule, getFolderModule, getTeamModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'

const components = {
  BaseModal,
  FormActions,
}

const Store = {
  ...getFolderModule(),
  ...getAlertModule(),
  ...getTeamModule(),
}

@Component({ name: 'create-folder-modal', components })
export default class CreateFolderModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Prop({ type: String, default: null })
  readonly parentId: string | null

  @Prop({ type: Boolean, default: false })
  readonly isTrdView: boolean

  readonly colors = colors

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.FolderModule.Getter
  readonly getFolderById: (id: string) => IFolder | null

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  @Store.FolderModule.Action
  readonly createFolder: (payload: ICreateFolderPayload) => Promise<void>

  get hasTrdRights(): boolean {
    return (
      this.hasRight(TeamRightGroup.TRD, TeamRight.ACCESS) &&
      this.hasRight(TeamRightGroup.TRD, TeamRight.CREATE)
    )
  }

  get hasConfidentialRights(): boolean {
    return (
      this.hasRight(TeamRightGroup.CONFIDENTIAL, TeamRight.ACCESS) &&
      this.hasRight(TeamRightGroup.CONFIDENTIAL, TeamRight.CREATE)
    )
  }

  get parent(): IFolder | null {
    if (!this.parentId) {
      return null
    }

    return this.getFolderById(this.parentId)
  }

  error = ''
  loading = false
  name = ''
  isTrd = false
  isConfidential = false

  @Watch('isTrd')
  onIsTrdChange(val: boolean) {
    if (val) {
      this.isConfidential = false
    }
  }

  @Watch('isConfidential')
  onIsConfidentialChange(val: boolean) {
    if (val) {
      this.isTrd = false
    }
  }

  created(): void {
    this.resetData()
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.resetData()
    this.error = ''
    this.$emit('input', value)
  }

  get isFormValid(): boolean {
    return this.name.length > 0
  }

  resetData(): void {
    this.name = ''
    this.isTrd = this.isTrdView || this.parent?.type === FolderType.Trd || false
    this.isConfidential = this.parent?.type === FolderType.Confidential || false
    this.loading = false
  }

  async primaryAction(): Promise<void> {
    this.loading = true
    try {
      await this.createFolder({
        name: this.name,
        type: this.isTrd
          ? FolderType.Trd
          : this.isConfidential
          ? FolderType.Confidential
          : FolderType.Normal,
        parentId: this.parentId === null ? undefined : this.parentId,
      })

      const alertData: AlertWithData = {
        message: 'folder_create_success',
        data: { name: this.name },
      }

      this.ALERT_SUCCESS_WITH_DATA(alertData)
      this.show = false
    } catch (error) {
      console.error(error)
      this.error = 'unknown_error'
      this.resetData()
    }
  }
}
