
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { I18NText } from '@/types/global'

import { colors } from '@/utils/constants'

import Action from '@/components/common/actions/action.vue'
import FormActions from '@/components/common/actions/form.actions.vue'

@Component({ name: 'confirmation-modal', components: { Action, FormActions } })
export default class ConfirmationModal extends Vue {
  @Prop({ type: Boolean, required: true })
  readonly value: boolean

  @Prop({ type: Object as PropType<I18NText>, required: true })
  readonly text: I18NText

  @Prop({ type: Boolean, default: false })
  readonly progress: boolean

  @Prop({ type: String, default: 'confirmation' })
  readonly type: string

  readonly colors = colors

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.$emit('input', value)
  }

  clickTrue(): void {
    this.$emit('clickTrue')
  }

  clickFalse(): void {
    this.$emit('clickFalse')
  }
}
