
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Location, Route } from 'vue-router'

import { IBasicPhase, PhaseGroup } from '@/services/bimoboxApiService/types'
import { colors, RouteName } from '@/utils/constants'

import store from '@/store'

import DocumentsViewer from '@/components/project/documents/documents.viewer.vue'
import ForgeViewer from '@/components/project/ForgeViewer.vue'
import TrdSidebar from '@/components/project/trd/trd.sidebar.vue'

const components = {
  DocumentsViewer,
  TrdSidebar,
  ForgeViewer,
}

const beforeRouteMethod = async (
  to: Route,
  from: Route,
  next: (location?: Location | Route) => void
): Promise<void> => {
  store.commit('pageLoader/TOGGLE_PAGE_LOADING', true)

  const defaultRoute: Location = {
    name: RouteName.INFORMATIONS,
    params: { id: to.params.id },
  }

  if (store.state.phase.phases === null) {
    try {
      await store.dispatch('phase/fetchPhases')
    } catch (e) {
      store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
      return next(defaultRoute)
    }
  }

  const phaseParams = to.query.phase as string | undefined
  const userPhases: IBasicPhase[] = store.getters['phase/userPhases']
  if (!phaseParams) {
    const lastPhaseId = store.state.phase.lastPhaseId
    if (lastPhaseId) {
      to.query.phase = lastPhaseId
      return next(to)
    }

    const getFirstPhaseByGroup = (g: PhaseGroup): string | null => {
      const phases = userPhases.filter(({ group }) => g === group)
      if (phases.length === 0) {
        return null
      }

      phases.sort((a: IBasicPhase, b: IBasicPhase) => {
        const aLow = a.name.toLocaleLowerCase()
        const bLow = b.name.toLocaleLowerCase()

        return aLow === bLow ? 0 : aLow > bLow ? -1 : 1
      })

      return phases[0].id
    }

    const conceptionId = getFirstPhaseByGroup(PhaseGroup.CONCEPTION)
    if (conceptionId) {
      to.query.phase = conceptionId
      store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
      return next(to)
    }

    const constructionId = getFirstPhaseByGroup(PhaseGroup.CONSTRUCTION)
    if (constructionId) {
      to.query.phase = constructionId
      store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
      return next(to)
    }

    const exploitationId = getFirstPhaseByGroup(PhaseGroup.EXPLOITATION)
    if (exploitationId) {
      to.query.phase = exploitationId
      store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
      return next(to)
    }

    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    return next()
  }

  const isParamInUserPhases = userPhases
    .map(({ id }) => id)
    .includes(phaseParams)
  if (!isParamInUserPhases) {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    return next(defaultRoute)
  }

  if (phaseParams !== store.getters['phase/userPhases']?.id) {
    store.commit('phase/SET_CURRENT_PHASE', phaseParams)
  }

  try {
    await store.dispatch('phase/fetchPhase', phaseParams)
  } catch {
    store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
    return next(defaultRoute)
  }

  store.commit('pageLoader/TOGGLE_PAGE_LOADING', false)
  return next()
}

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate'])
@Component({ name: 'trd', components })
export default class Trd extends Vue {
  async beforeRouteEnter(
    to: Route,
    from: Route,
    next: (location?: Location | Route) => void
  ): Promise<void> {
    await beforeRouteMethod(to, from, next)
  }

  async beforeRouteUpdate(
    to: Route,
    from: Route,
    next: (location?: Location | Route) => void
  ): Promise<void> {
    await beforeRouteMethod(to, from, next)
  }

  showSideBar = true
  @Watch('showSideBar')
  onShowSideBarChange(val: boolean) {
    if (!val) {
      this.drag = false
    }
  }

  readonly colors = colors

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get resize(): HTMLElement | null {
    return this.showSideBar ? this.getElement('#resize') : null
  }

  get left(): HTMLElement | null {
    return this.showSideBar ? this.getElement('.left') : null
  }

  get resizableContainer(): HTMLElement | null {
    return this.showSideBar ? this.getElement('.resizableContainer') : null
  }

  getElement(selector: string): HTMLElement | null {
    return document.querySelector(selector) as HTMLElement | null
  }

  moveX =
    (this.left ? this.left.getBoundingClientRect().width : 0) +
    (this.resize ? this.resize.getBoundingClientRect().width / 2 : 0)

  lastMoveX = this.moveX
  drag = false

  clickResize(e: MouseEvent) {
    this.drag = true
    this.moveX =
      (this.left ? this.left.getBoundingClientRect().width : 0) +
      (this.resize ? this.resize.getBoundingClientRect().width / 2 : 0)
    this.lastMoveX = this.moveX
    this.moveX = e.x
  }

  moveResizeContainer(e: MouseEvent) {
    this.moveX = e.x
    if (this.drag && this.left && this.resize) {
      const reszieWidth =
        this.moveX - this.resize.getBoundingClientRect().width / 2 + 'px'
      this.left.style.width =
        reszieWidth < this.left.style['min-width']
          ? this.left.style['min-width']
          : reszieWidth
    }
  }

  mouseupResizableContainer() {
    this.drag = false
  }

  mounted(): void {
    this.moveX =
      (this.left ? this.left.getBoundingClientRect().width : 0) +
      (this.resize ? this.resize.getBoundingClientRect().width / 2 : 0)
    this.lastMoveX = this.moveX
  }

  selectedTab = 1

  selectedFiles: string[] = []

  @Watch('selectedFiles', { deep: true, immediate: true })
  onSelectedFilesChange(val: string[]): void {
    if (this.isMobile) {
      this.showSideBar = val.length === 0
    }
  }
}
