export enum RequestAlertType {
  Success = 'success',
  Error = 'error',
}

export type AlertWithData = {
  message: string
  // eslint-disable-next-line
  data: any
  pluralize?: boolean
}

export type RequestAlert = {
  message: string
  // eslint-disable-next-line
  messageData?: any
  pluralize?: boolean
  type: RequestAlertType
}
