import { UserService } from '@/services/bimoboxApiService'
import {
  DBUser,
  UpdateProfilePayload,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class UserModule extends VuexModule {
  private readonly userService = new UserService()

  user: DBUser | null = null

  @Mutation
  SET_USER(val: DBUser | null): void {
    this.user = val
  }

  @Action({ rawError: true, commit: 'SET_USER' })
  async fetchCurrentUser(): Promise<DBUser> {
    if (this.user) {
      return this.user
    }

    return this.userService.fetchCurrentUser()
  }

  @Action({ rawError: true, commit: 'SET_USER' })
  async updateProfile(data: UpdateProfilePayload): Promise<DBUser> {
    if (this.user === null) {
      throw 'unknown_error'
    }

    try {
      return await this.userService.updateProfile(this.user, data)
    } catch {
      throw 'unknown_error'
    }
  }
}
