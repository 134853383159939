import { AdviceService } from '@/services/bimoboxApiService'
import {
  IAdvice,
  ICreateAdvicePayload,
  IEmitAdvicePayload,
} from '@/services/bimoboxApiService/types'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({ namespaced: true })
export default class AdviceModule extends VuexModule {
  private readonly adviceService = new AdviceService()

  advices: IAdvice[] | null = null

  get getAdviceById(): (adviceId: string) => IAdvice | null {
    return (adviceId: string): IAdvice | null => {
      const advice = this.advices?.find(({ id }) => id === adviceId)

      return advice ?? null
    }
  }

  @Mutation
  ADD_ADVICES(advices: IAdvice[]): void {
    if (!this.advices) {
      this.advices = []
    }

    this.advices = this.advices.filter(
      ({ id }) => !advices.map((o) => o.id).includes(id)
    )

    this.advices.push(...advices)
  }

  @Mutation
  RESET_ADVICE_STATES(): void {
    this.advices = null
  }

  @Mutation
  UPDATE_ADVICE(advice: IAdvice): void {
    if (!this.advices) {
      return
    }

    const index = this.advices.findIndex(({ id }) => id === advice.id)
    if (index > -1) {
      this.advices.splice(index, 1, advice)
    }
  }

  @Mutation
  DELETE_ADVICES_BY_IDS(ids: string[]): void {
    if (!this.advices) {
      return
    }

    this.advices = [...this.advices.filter(({ id }) => !ids.includes(id))]
  }

  @Action({ rawError: true })
  async createAdvice(payload: ICreateAdvicePayload): Promise<void> {
    const advice = await this.adviceService
      .createAdvice(this.projectId, this.currentPhaseId, payload)
      .catch((err) => {
        console.error('createVisa', err)

        throw 'unknown_error'
      })

    this.context.commit('ADD_ADVICES', [advice])
    this.context.commit(
      'documentVersion/ADD_VERSION_ADVICE',
      { id: payload.versionId, adviceId: advice.id },
      { root: true }
    )
  }

  @Action({ rawError: true, commit: 'UPDATE_ADVICE' })
  async emitAdvice(payload: IEmitAdvicePayload): Promise<IAdvice> {
    return this.adviceService
      .emitAdvice(this.projectId, this.currentPhaseId, payload)
      .catch((err) => {
        console.error('createVisa', err)

        throw 'unknown_error'
      })
  }

  private get projectId(): string {
    return this.context.rootState.project.project.id
  }

  private get currentPhaseId(): string {
    return this.context.rootState.phase.currentPhaseId
  }
}
