
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  IBasicPhase,
  PhaseGroup,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'

import { getPhaseModule, getProjectModule, getTeamModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import PhaseGroupDisplayer from '@/components/project/information/phaseGroup.displayer.vue'
import CreatePhaseModal from '@/components/project/phase/create.phase.modal.vue'
import DeletePhasesModal from '@/components/project/phase/delete.phases.modal.vue'

const Store = {
  ...getProjectModule(),
  ...getTeamModule(),
  ...getPhaseModule(),
}

const components = {
  PhaseGroupDisplayer,
  CreatePhaseModal,
  DeletePhasesModal,
}

@Component({ name: 'phases-displayer', components })
export default class PhasesDisplayer extends Vue {
  @Prop({ type: Array as PropType<IBasicPhase[]>, default: [] })
  readonly phasesToDisplay: IBasicPhase[]

  @Store.PhaseModule.State
  readonly phases: IBasicPhase[]

  readonly colors = colors

  @Store.TeamModule.Getter
  hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  get canDeletePhase(): boolean {
    return (
      this.hasRight(TeamRightGroup.PHASE, TeamRight.DELETE) &&
      this.phases.length > 0
    )
  }

  get canCreatePhase(): boolean {
    return this.hasRight(TeamRightGroup.PHASE, TeamRight.CREATE)
  }

  showCreatePhaseModal = false
  showDeletePhasesModal = false

  get conceptionPhases(): IBasicPhase[] {
    return this.phasesToDisplay.filter(
      ({ group }) => group === PhaseGroup.CONCEPTION
    )
  }

  get constructionPhases(): IBasicPhase[] {
    return this.phasesToDisplay.filter(
      ({ group }) => group === PhaseGroup.CONSTRUCTION
    )
  }

  get exploitationPhases(): IBasicPhase[] {
    return this.phasesToDisplay.filter(
      ({ group }) => group === PhaseGroup.EXPLOITATION
    )
  }
}
