export const imageExts = ['png', 'jpg', 'jpeg', 'bmp', 'gif']

export const getFileExtension = (name: string): string => {
  const splited = name.split('.')
  const ext = splited[splited.length - 1].toLowerCase()
  return ext
}

export const isFileExtensionContainedIn = (
  fileName: string,
  exts: string[]
): boolean => {
  const fileExt = getFileExtension(fileName)
  return exts.includes(fileExt)
}

import { acceptedFilesExtensions } from '@/utils/forge.config'

export const isForgeAcceptedExt = (fileName: string): boolean => {
  return isFileExtensionContainedIn(fileName, acceptedFilesExtensions)
}
