
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  IBasicPhase,
  IGetVisaReportQuery,
  NamedBlob,
} from '@/services/bimoboxApiService/types'

import { getPhaseModule } from '@/store/utils'
import { colors } from '@/utils/constants'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'

const components = {
  BaseModal,
  FormActions,
}

const Store = {
  ...getPhaseModule(),
}

@Component({ name: 'get-visa-report-modal', components })
export default class GetVisaReportModal extends Vue {
  @Prop({ type: Object as PropType<IBasicPhase>, required: true })
  readonly phase: IBasicPhase

  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  readonly colors = colors

  @Store.PhaseModule.Action
  readonly getVisaReport: (
    payload: { phase: IBasicPhase } & IGetVisaReportQuery
  ) => Promise<NamedBlob>

  error = ''
  loading = false
  includeTRD = false
  includeVisaLess = false

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.onShowChanged(value)
  }

  resetData(): void {
    this.loading = false
    this.includeTRD = false
    this.includeVisaLess = false
  }

  async onShowChanged(value: boolean): Promise<void> {
    this.error = ''
    this.resetData()
    this.$emit('input', value)
  }

  async primaryAction(): Promise<void> {
    this.loading = true

    try {
      const blob = await this.getVisaReport({
        phase: this.phase,
        includeTRD: this.includeTRD,
        includeVisaLess: this.includeVisaLess,
      })

      blob.downloadLocal()

      this.show = false
    } catch (error) {
      this.error = error as string
      this.resetData()
    }
  }
}
