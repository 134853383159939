export interface IFolder {
  id: string
  name: string
  rootId?: string
  type: FolderType
  folders: string[]
  documents: string[]
  isEmpty: boolean
  phaseId: string
  createdAt: string
}

export interface IBasicFolder {
  id: string
  name: string
  type: FolderType
  isEmpty: boolean
}

export enum FolderType {
  Normal = 'normal',
  Confidential = 'confidential',
  Trd = '3d',
}

export interface ICreateFolderPayload {
  name: string
  type: FolderType
  parentId?: string
}

export interface IUpdateFolderPayload {
  id: string
  name: string
  type: FolderType
}
