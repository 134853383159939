export enum Endpoint {
  FILE = 'file',
  AUTH = 'auth',
  USER = 'user',
  ADDRESS = 'address',
  PROJECT = 'project',
  FORGE = 'forge',
  PHASE = 'phase',
  FOLDER = 'folder',
  DOCUMENT = 'document',
  BCF = 'bcf',
  GUEST = 'guest',
  TEAM = 'team',
  VERSION = 'version',
  VISA = 'visa',
  OBSERVATION = 'observation',
  ADVICE = 'advice',
}
