
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { AlertWithData } from '@/types/global'

import {
  getAlertModule,
  getDocumentModule,
  getFolderModule,
} from '@/store/utils'
import { colors } from '@/utils/constants'

import {
  IDocument,
  IFolder,
  IUpdateDocumentPayload,
} from '@/services/bimoboxApiService/types'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'

const components = {
  BaseModal,
  FormActions,
}

const Store = {
  ...getDocumentModule(),
  ...getFolderModule(),
  ...getAlertModule(),
}

@Component({ name: 'update-document-modal', components })
export default class UpdateDocumentModal extends Vue {
  @Prop({ type: Object as PropType<IDocument>, required: true })
  readonly document: IDocument

  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS_WITH_DATA: (data: AlertWithData) => void

  @Store.DocumentModule.Action
  readonly updateDocument: (data: {
    folderId: string
    documentId: string
    payload: IUpdateDocumentPayload
  }) => Promise<void>

  @Store.FolderModule.Getter
  readonly getParentByChildId: (id: string) => IFolder

  readonly colors = colors

  error = ''
  loading = false
  fileName = ''
  fileDescription = ''

  created(): void {
    this.resetData()
  }

  get parentFolder(): IFolder {
    return this.getParentByChildId(this.document.id)
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.error = ''
    this.resetData()
    this.$emit('input', value)
  }

  get isFormValid(): boolean {
    const isValid = this.fileName.length > 0
    const isSame =
      this.fileName === this.removeExtension() &&
      this.document.description.trim() === this.fileDescription.trim()
    return isValid && !isSame
  }

  removeExtension(): string {
    const splittedName = this.document.name.split('.')
    splittedName.pop()
    return splittedName.join('.')
  }

  getExtension(): string {
    const splittedName = this.document.name.split('.')
    return splittedName[splittedName.length - 1]
  }

  async primaryAction(): Promise<void> {
    this.loading = true
    try {
      const data = {
        folderId: this.parentFolder.id,
        documentId: this.document.id,
        payload: {
          name: `${this.fileName}.${this.getExtension()}`.trim(),
          description:
            this.fileDescription.length > 0
              ? this.fileDescription.trim()
              : undefined,
        },
      }

      await this.updateDocument(data)
      this.ALERT_SUCCESS_WITH_DATA({
        message: 'document_update_success',
        data: { name: this.document.name },
      })
      this.show = false
    } catch (error) {
      this.error = error as string
      this.resetData()
    }
  }

  resetData(): void {
    this.fileName = this.removeExtension()
    this.fileDescription = this.document.description
    this.loading = false
  }
}
