
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  FolderType,
  IBasicPhase,
  IFolder,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'

import { getFolderModule, getPhaseModule, getTeamModule } from '@/store/utils'
import { colors } from '@/utils/constants'

const Store = {
  ...getFolderModule(),
  ...getPhaseModule(),
  ...getTeamModule(),
}

interface IFolderRowMenuItem {
  name: string
  action: () => void
}

@Component({ name: 'folder-row' })
export default class FolderRow extends Vue {
  @Prop({ required: true, type: String })
  readonly folderId: string

  @Prop({ type: Boolean, default: false })
  readonly isTrdView: boolean

  readonly colors = colors

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase

  @Store.FolderModule.Getter
  readonly getFolderById: (id: string) => IFolder

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  get folder(): IFolder {
    return this.getFolderById(this.folderId)
  }

  get isSmartphone(): boolean {
    return this.$vuetify.breakpoint.xs || this.isTrdView
  }

  get isEmpty(): boolean {
    return (
      this.folder.folders.length === 0 && this.folder.documents.length === 0
    )
  }

  get menuItems(): IFolderRowMenuItem[] {
    const items = [
      {
        right:
          this.currentPhase.locked === false &&
          this.hasRight(TeamRightGroup.DOCUMENT, TeamRight.CREATE) &&
          this.hasRequiredRights(TeamRight.CREATE),
        name: 'document_create',
        action: () => this.$emit('showCreateDocumentModal', this.folderId),
      },
      {
        right:
          this.currentPhase.locked === false &&
          this.hasRight(TeamRightGroup.FOLDER, TeamRight.CREATE) &&
          this.hasRequiredRights(TeamRight.CREATE),
        name: 'folder_create',
        action: () => this.$emit('showCreateFolderModal', this.folderId),
      },
      {
        right:
          this.currentPhase.locked === false &&
          this.hasRight(TeamRightGroup.FOLDER, TeamRight.UPDATE) &&
          this.hasRequiredRights(TeamRight.UPDATE),
        name: 'folder_update',
        action: () => this.$emit('showUpdateFolderModal', this.folderId),
      },
      {
        right:
          this.currentPhase.locked === false &&
          this.hasRight(TeamRightGroup.FOLDER, TeamRight.DELETE) &&
          this.hasRequiredRights(TeamRight.DELETE),
        name: 'folder_delete',
        action: () => this.$emit('showDeleteFolderModal', this.folderId),
      },
    ]

    return items.filter(({ right }) => right === true)
  }

  get folderIcon(): string {
    switch (this.folder.type) {
      case FolderType.Trd:
        return '3d_folder'
      case FolderType.Confidential:
        return 'confidential_folder'
      case FolderType.Normal:
        return 'folder'
    }
  }

  isChoiceAvailable(nameToFind: string): boolean {
    return this.menuItems
      .map(({ name }) => {
        const splitedName = name.split('.')
        return splitedName[splitedName.length - 1]
      })
      .includes(nameToFind)
  }

  hasRequiredRights(right: TeamRight): boolean {
    if (this.folder.type === FolderType.Trd) {
      return (
        this.hasRight(TeamRightGroup.TRD, TeamRight.ACCESS) &&
        this.hasRight(TeamRightGroup.TRD, right)
      )
    }

    if (this.folder.type === FolderType.Confidential) {
      return (
        this.hasRight(TeamRightGroup.CONFIDENTIAL, TeamRight.ACCESS) &&
        this.hasRight(TeamRightGroup.CONFIDENTIAL, right)
      )
    }

    return true
  }
}
