
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  FileUrl,
  IVisaObservation,
  Team,
} from '@/services/bimoboxApiService/types'
import { colors, defaultAvatarUrl, getFormattedDate } from '@/utils/constants'

import {
  isVisaStatusTextColorDark,
  VisaExplicitStatus,
  visaExplicitStatusToColor,
  visaStatusToi18n,
} from '@/utils/visa.utils'

import ExpandablePanel from '@/components/common/ExpansionPanel.vue'
import VisaStatusChip from '@/components/project/documents/documents/visa/visa.status.chip.vue'
import VisaObservationContent from '@/components/project/documents/documents/visa/visaObservationContent.vue'
import { getTeamModule } from '@/store/utils'
import { FileService } from '@/services/bimoboxApiService'

const components = { ExpandablePanel, VisaObservationContent, VisaStatusChip }

const Store = {
  ...getTeamModule(),
}

@Component({ name: 'visa-user-observations', components })
export default class VisaUserObservations extends Vue {
  @Prop({ type: Object as PropType<IVisaObservation>, required: true })
  readonly observation: IVisaObservation

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl
  readonly getFormattedDate = getFormattedDate
  readonly fileService = new FileService()

  @Store.TeamModule.Getter
  readonly getTeamByUserId: (id: string) => Team | null

  get teamColor(): string | null {
    return this.getTeamByUserId(this.observation.user.id)?.color ?? null
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  get i18NVisaStatus(): VisaExplicitStatus | '' {
    return visaStatusToi18n([this.observation])
  }

  get statusColor(): string {
    if (this.i18NVisaStatus === '') {
      return ''
    }

    return visaExplicitStatusToColor(this.i18NVisaStatus)
  }

  get isStatusTextDark(): boolean {
    if (this.i18NVisaStatus === '') {
      return true
    }

    return isVisaStatusTextColorDark(this.i18NVisaStatus)
  }
  async downloadFile(file: FileUrl): Promise<void> {
    try {
      const blob = await this.fileService.downloadFile(
        file.id,
        file.name.slice(37)
      )
      blob.downloadLocal()
    } catch (error) {
      console.error(error)
    }
  }
}
