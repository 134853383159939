import Vue from 'vue'

import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import router from '@/router'
import store from '@/store'

import VueLayers from 'vuelayers'

import App from './App.vue'

Vue.config.productionTip = false
Vue.use(VueLayers, {
  dataProjection: 'EPSG:4326',
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
