import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import { Endpoint } from '@/services/bimoboxApiService/endpoint.enum'
import {
  DBUser,
  UpdatePasswordPayload,
  UpdateProfilePayload,
} from '@/services/bimoboxApiService/user/user.types'
import { enrichUserAvatar } from '@/utils/imageUrlGetter'

export class UserService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.USER)
  }

  async fetchCurrentUser(): Promise<DBUser> {
    const url = this.buildUrl('whoami')
    try {
      return this.apiService.get<DBUser>(url).then(this.enrichDBUser)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateProfile(
    user: DBUser,
    data: UpdateProfilePayload
  ): Promise<DBUser> {
    const url = this.buildUrl(user.id)
    try {
      return this.apiService.put<DBUser>(url, data).then(this.enrichDBUser)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updatePassword(
    user: DBUser,
    payload: UpdatePasswordPayload
  ): Promise<void> {
    const url = this.buildUrl(`${user.id}/password`)
    try {
      await this.apiService.put<void>(url, payload)
    } catch (error) {
      console.error(error)
      throw 'unknown_error'
    }
  }

  private enrichDBUser(user: DBUser): DBUser {
    user.avatar = enrichUserAvatar(user.avatar)

    return user
  }
}
