import { apiBaseUrl, defaultAvatarUrl } from './constants'

const defaultIcon = 'file.png'
const urls = [
  {
    extensions: ['pdf'],
    icon: 'pdf_icon.png',
  },
  {
    extensions: [
      'xls',
      'xlt',
      'xlm',
      'xlsx',
      'xlsm',
      'xltx',
      'xltm',
      'xlsb',
      'xla',
      'xlam',
      'xll',
      'xlw',
    ],
    icon: 'excel_icon.svg',
  },
  {
    extensions: ['docdot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb'],
    icon: 'word_icon.svg',
  },
  {
    extensions: [
      'ppt',
      'postMessagepps',
      'pptx',
      'pptm',
      'potx',
      'potm',
      'ppam',
      'ppsx',
      'ppsm',
      'sldx',
      'sldm',
    ],
    icon: 'powerpoint_icon.svg',
  },
  {
    extensions: [
      'jpg',
      'png',
      'gif',
      'webp',
      'tiff',
      'raw',
      'bmp',
      'heif',
      'mpeg',
      'indd',
      'jpeg',
      'svg',
      'jpeg',
      'ai',
      'eps',
    ],
    icon: 'image_icon.png',
  },
]
export const getImageUrl = (filename: string): string => {
  const lowerName = filename
  const fileNameArray = lowerName.split('.')
  const icon = urls.find((u) =>
    u.extensions.includes(fileNameArray[fileNameArray.length - 1])
  )
  return icon ? icon.icon : defaultIcon
}

export function enrichUserAvatar(avatar?: string): string | undefined {
  if (avatar && avatar !== defaultAvatarUrl) {
    return new URL(`${apiBaseUrl}/file/${avatar}`).toString()
  }

  return avatar
}
