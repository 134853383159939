import { BCFComponents, BoxSelectionExtension, ModelElement } from './types'

export class BcfComponentsGetter {
  constructor(private readonly viewer: Autodesk.Viewing.GuiViewer3D) {}

  async getComponents(): Promise<BCFComponents> {
    const hiddenNodesDbIds = this.viewer
      .getAggregateHiddenNodes()
      .flatMap(({ ids }: ModelElement) => ids)
    const selectedDbIds = this.viewer.getSelection()

    try {
      const visibleIfcGuids: string[] = await this.getAllVisibleComponents()
      const selectedIfcGuids = await this.dbIdsToIfcGuids(selectedDbIds)
      const hiddenIfcGuids: string[] = await this.dbIdsToIfcGuids(
        hiddenNodesDbIds
      )

      return {
        visible: visibleIfcGuids,
        selected: selectedIfcGuids,
        hidden: hiddenIfcGuids,
      }
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  private getAllVisibleComponents(): Promise<string[]> {
    const elements = this.selectScreen()
    if (elements === null) {
      throw new Error('Unable to get Autodesk.BoxSelection')
    }

    const dbIds = elements.flatMap(({ ids }) => ids)
    return this.dbIdsToIfcGuids(dbIds)
  }

  private selectScreen(): ModelElement[] | null {
    const selectExt = this.viewer.getExtension(
      'Autodesk.BoxSelection'
    ) as BoxSelectionExtension | null

    if (!selectExt) {
      return null
    }

    const tool = selectExt.boxSelectionTool
    const {
      left: startX,
      top: startY,
      right: endX,
      bottom: endY,
    } = this.viewer.impl.getCanvasBoundingClientRect()

    tool.startPoint.set(startX, startY)
    tool.endPoint.set(endX, endY)

    return tool.getSelection()
  }

  private async dbIdsToIfcGuids(dbIds: number[]): Promise<string[]> {
    const results = await this.getIFCGuids(dbIds)

    const ifcGuids: string[] = []

    for (const result of results) {
      const ifcGuid = this.propertyResultToIfcGuid(result)
      if (ifcGuid !== null && !ifcGuids.includes(ifcGuid)) {
        ifcGuids.push(ifcGuid)
      }
    }

    return ifcGuids
  }

  private getIFCGuids(
    dbIds: number[]
  ): Promise<Autodesk.Viewing.PropertyResult[]> {
    return new Promise((resolve, reject) => {
      this.viewer.model.getBulkProperties(
        dbIds,
        { propFilter: ['GLOBALID'], ignoreHidden: false },
        (r: Autodesk.Viewing.PropertyResult[]) => resolve(r),
        (err: any) => reject(err)
      )
    })
  }

  private propertyResultToIfcGuid(
    pr: Autodesk.Viewing.PropertyResult
  ): string | null {
    const property = pr.properties
      .filter(({ displayCategory }) => displayCategory === 'IFC')
      .find((p) => p.displayName === 'GLOBALID')

    return (property?.displayValue as string) ?? null
  }
}
