
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { DashboardProject } from '@/services/bimoboxApiService/types'

import { getPageLoaderModule } from '@/store/utils'
import { RouteName } from '@/utils/constants'

const Store = getPageLoaderModule()

@Component({ name: 'project-card' })
export default class ProjectCard extends Vue {
  @Prop({ type: Object as PropType<DashboardProject> })
  readonly project: DashboardProject

  @Store.PageLoaderModule.Mutation
  readonly RESET_HAS_ENTERED: () => void

  goProject(): void {
    const opt = {
      params: { id: this.project.id },
      name: RouteName.INFORMATIONS,
    }

    this.RESET_HAS_ENTERED()
    this.$router.push(opt)
  }
}
