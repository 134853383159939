
import { isEmail } from 'validator'
import { Component, Prop, Vue } from 'vue-property-decorator'

import {
  DBFile,
  DBUser,
  UpdateProfilePayload,
} from '@/services/bimoboxApiService/types'
import { VForm } from '@/types/global'

import { getAlertModule, getUserModule } from '@/store/utils'
import { colors, defaultAvatarUrl } from '@/utils/constants'

import { FileService } from '@/services/bimoboxApiService'

import BaseModal from '@/components/common/modals/base.modal.vue'
import FormActions from '@/components/common/actions/form.actions.vue'
import FilePondHandler from '@/components/common/FilePondHandler.vue'

interface IUpdateProfileForm {
  email: string
  name: string
  lastName: string
  company: string
}

const components = {
  BaseModal,
  FormActions,
  FilePondHandler,
}

const Store = {
  ...getAlertModule(),
  ...getUserModule(),
}

@Component({ name: 'update-profile-modal', components })
export default class UpdateProfileModal extends Vue {
  @Prop({ required: true, type: Boolean })
  readonly value: boolean

  @Store.AlertModule.Mutation
  readonly ALERT_SUCCESS: (message: string) => void

  @Store.UserModule.Action
  readonly updateProfile: (data?: UpdateProfilePayload) => Promise<void>

  readonly fileService = new FileService()

  @Store.UserModule.State
  readonly user: DBUser

  readonly colors = colors
  readonly defaultAvatarUrl = defaultAvatarUrl

  showUploadAvatarButton = false

  loading = false
  showFilePond = false

  formData: IUpdateProfileForm = {
    name: '',
    lastName: '',
    email: '',
    company: '',
  }

  error = ''

  rules = {
    required: (value: string): string | boolean =>
      value !== undefined || (this.$t('rule_required_error') as string),
    email: (value: string): string | boolean =>
      isEmail(value) || (this.$t('rule_invalid_email_error') as string),
  }

  created(): void {
    this.resetForm()
  }

  get isFormValid(): boolean {
    const areFieldsFilled: boolean =
      this.rules.required(this.formData.name) === true &&
      this.rules.required(this.formData.lastName) === true &&
      this.rules.required(this.formData.email) === true &&
      this.rules.required(this.formData.company) === true

    const isSameDataAsUser: boolean =
      this.formData.name === this.user.name &&
      this.formData.lastName === this.user.lastName &&
      this.formData.email === this.user.email &&
      this.formData.company === this.user.company &&
      this.newAvatarId === ''

    return areFieldsFilled && !isSameDataAsUser && !this.filePondLoading
  }

  async primaryAction(): Promise<void> {
    this.loading = true
    this.error = ''
    const data: UpdateProfilePayload = {
      ...this.formData,
    }

    if (this.newAvatarId !== '') {
      data.avatar = this.newAvatarId
    }

    try {
      await this.updateProfile(data)
      this.ALERT_SUCCESS('user_profile_update_success')
      this.newAvatarId = ''
      this.show = false
    } catch (error) {
      console.error(error)
      this.error = 'unknown_error'
      if (this.newAvatarId !== '') {
        await this.deleteAvatar(this.newAvatarId)
      }

      this.loading = false
      this.resetForm()
    }
  }

  get show(): boolean {
    return this.value
  }

  set show(value: boolean) {
    this.onShowChanged(value)
  }

  async onShowChanged(value: boolean): Promise<void> {
    this.error = ''
    this.loading = false
    if (value === false && this.newAvatarId !== '') {
      await this.deleteAvatar(this.newAvatarId)
    }

    this.resetForm()
    this.$emit('input', value)
  }

  async deleteAvatar(id: string): Promise<void> {
    this.loading = true
    await this.fileService.deleteFile(id)
    this.loading = false
  }

  resetForm(): void {
    const form = this.$refs.form as VForm
    this.formData = {
      name: this.user.name,
      lastName: this.user.lastName,
      email: this.user.email,
      company: this.user.company,
    }

    this.showFilePond = false
    this.showUploadAvatarButton = false
    this.newAvatarId = ''

    form?.resetValidation()
  }

  newAvatarId = ''

  fileDeleted(): void {
    this.newAvatarId = ''
  }

  filePondLoading = false

  filepondToggled(state: boolean): void {
    this.filePondLoading = state
  }

  async uploadFile(
    file: File,
    onUploadProgress: (progressEvent) => void
  ): Promise<string> {
    const res: DBFile = await this.fileService.uploadFile({
      file,
      onUploadProgress,
    })
    this.newAvatarId = res.id
    return res.id
  }
}
