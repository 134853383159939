
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { getPhaseModule, getProjectModule, getTeamModule } from '@/store/utils'
import { colors } from '@/utils/constants'
import {
  IBasicPhase,
  TeamRight,
  TeamRightGroup,
} from '@/services/bimoboxApiService/types'

import DocumentsViewer from '@/components/project/documents/documents.viewer.vue'
import CreateFolderModal from '@/components/project/documents/folders/create.folder.modal.vue'
import PhaseSelector from '@/components/project/PhaseSelector.vue'
import { PropType } from 'vue/types/v3-component-props'

const Store = {
  ...getTeamModule(),
  ...getPhaseModule(),
  ...getProjectModule(),
}

const components = {
  DocumentsViewer,
  CreateFolderModal,
  PhaseSelector,
}

@Component({ name: 'trd-sidebar', components })
export default class TrdSidebar extends Vue {
  @Prop({ type: Array as PropType<string[]>, required: true })
  readonly currentFiles: string[]

  readonly colors = colors

  @Store.TeamModule.Getter
  readonly hasRight: (group: TeamRightGroup, right: TeamRight) => boolean

  @Store.PhaseModule.Getter
  readonly currentPhase: IBasicPhase | null

  showCreateFolderModal = false
  get canCreateFolder(): boolean {
    return (
      this.hasRight(TeamRightGroup.TRD, TeamRight.ACCESS) &&
      this.hasRight(TeamRightGroup.FOLDER, TeamRight.CREATE) &&
      this.currentPhase !== null &&
      this.currentPhase?.locked === false
    )
  }

  selectedFiles: string[] = []
  @Watch('currentSelectedFiles', { deep: true, immediate: true })
  onCurrentSelectedFilesChange(val: string[]) {
    this.selectedFiles = val
  }

  get currentSelectedFiles(): string[] {
    return this.currentFiles
  }

  set currentSelectedFiles(files: string[]) {
    this.$emit('update:currentFiles', files)
  }

  arraysEqual(a?: string[], b?: string[]): boolean {
    if (a === b) return true
    if (!a || !b) return false
    if (a.length !== b.length) return false

    return (
      a.every((str) => b.includes(str)) && b.every((str) => a.includes(str))
    )
  }

  get canValidateSelection(): boolean {
    return (
      this.selectedFiles.length > 0 &&
      !this.arraysEqual(this.selectedFiles, this.currentSelectedFiles)
    )
  }

  validateSelection(): void {
    this.currentSelectedFiles = this.selectedFiles
  }
}
