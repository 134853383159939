export enum DocumentsTreeviewItemType {
  FOLDER = 'folder',
  DOCUMENT = 'document',
}

export interface IDocumentsTreeviewItem {
  type: DocumentsTreeviewItemType
  disabled?: boolean
  id: string
  children?: IDocumentsTreeviewItem[]
}
