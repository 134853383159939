import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  Endpoint,
  IPostTeamPayload,
  Team,
} from '@/services/bimoboxApiService/types'

export class TeamService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.PROJECT)
  }

  async createTeam(
    projectId: string,
    payload: IPostTeamPayload
  ): Promise<Team> {
    const url = this.buildFullUrl(projectId)
    try {
      return await this.apiService.post<Team>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getProjectTeams(projectId: string): Promise<Team[]> {
    const url = this.buildFullUrl(projectId)
    try {
      return await this.apiService.get<Team[]>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getTeamById(projectId: string, teamId: string): Promise<Team> {
    const url = this.buildFullUrl(projectId, teamId)
    try {
      return await this.apiService.get<Team>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async updateTeamById(
    projectId: string,
    teamId: string,
    payload: IPostTeamPayload
  ): Promise<Team> {
    const url = this.buildFullUrl(projectId, teamId)
    try {
      return await this.apiService.put<Team>(url, payload)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async deleteTeam(projectId: string, teamId: string): Promise<void> {
    const url = this.buildFullUrl(projectId, teamId)
    try {
      return await this.apiService.delete<void>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private buildFullUrl(projectId: string, params = ''): string {
    return this.buildUrl(`${projectId}/${Endpoint.TEAM}/${params}`)
  }
}
