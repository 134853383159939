import { BimoboxEndpointService } from '@/services/bimoboxApiService/endpoint-bimobox.service'
import {
  DBFile,
  Endpoint,
  NamedBlob,
  UploadFilePayload,
} from '@/services/bimoboxApiService/types'
import { AxiosRequestConfig } from 'axios'
import * as mime from 'mime-types'

export class FileService extends BimoboxEndpointService {
  constructor() {
    super(Endpoint.FILE)
  }

  uploadFile(payload: UploadFilePayload): Promise<DBFile> {
    return this.upload(payload, false)
  }

  uploadTrdFile(payload: UploadFilePayload): Promise<DBFile> {
    return this.upload(payload, true)
  }

  private async upload(
    payload: UploadFilePayload,
    isTrd: boolean
  ): Promise<DBFile> {
    const url = this.buildUrl(isTrd ? 'trd' : '')
    const config = this.newUploadConfig(payload.onUploadProgress)
    const data = new FormData()
    data.set('file', payload.file as File)
    data.set('fileName', payload.file.name)
    try {
      return await this.apiService.post<DBFile>(url, data, config)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getFile(id: string): Promise<ArrayBuffer> {
    const url = this.buildUrl(id)
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/octet-stream',
      },
    }

    try {
      return this.apiService.get<ArrayBuffer>(url, config)
    } catch (err) {
      console.error(err)

      throw 'unknown_error'
    }
  }

  async downloadFile(id: string, name: string): Promise<NamedBlob> {
    const url = this.buildUrl(id)
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/octet-stream',
      },
    }

    const type = mime.lookup(name)
    try {
      const data = await this.apiService.get<ArrayBuffer>(url, config)
      const blob = new Blob([data], {
        type: type || 'application/octet-stream',
      })
      return new NamedBlob(name, blob)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async deleteFile(id: string): Promise<void> {
    const url = this.buildUrl(id)
    try {
      return await this.apiService.delete<void>(url)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  private newUploadConfig(
    onUploadProgress?: (progressEvent) => void
  ): AxiosRequestConfig {
    return {
      onUploadProgress,
      headers: { 'Content-Type': 'multipart/form-data' },
    }
  }
}
