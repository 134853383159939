
import { PropType } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import Action from '@/components/common/actions/action.vue'
import CancelVisaModal from '@/components/project/documents/documents/visa/cancel.visa.modal.vue'

import {
  IDocument,
  IDocumentVersion,
  IVisa,
} from '@/services/bimoboxApiService/types'

const components = {
  CancelVisaModal,
  Action,
}

@Component({ name: 'cancel-visa-btn', components })
export default class CancelVisaBtn extends Vue {
  @Prop({ type: Object as PropType<IDocumentVersion>, required: true })
  readonly version: IDocumentVersion

  @Prop({ type: Object as PropType<IDocument>, required: true })
  readonly document: IDocument

  @Prop({ type: Object as PropType<IVisa>, required: true })
  readonly visa: IVisa

  showCancelVisaModal = false
}
